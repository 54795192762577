import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { Coach } from '../model/coach.model';
import { MarketingKitComponent } from '../dialogs/marketing-kit/marketing-kit.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-coaching-tools',
  templateUrl: './coaching-tools.component.html',
  styleUrls: ['./coaching-tools.component.css']
})
export class CoachingToolsComponent implements OnInit {

  constructor(private router: Router, public userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, private authService: AuthService, public dialog: MatDialog) { }

  public environment = environment

  public coach: Coach

  public showNotifications: boolean = false
  public showCheckIns: boolean = true
  public showFileSharing: boolean = false
  public showCommunity: boolean = false;

  ngOnInit(): void {
    const user = this.userService.getLoggedInUser();
    if(user) {
      this.coach = user.coach
    }
  }

  resetTabs() {
    this.showNotifications = false;
    this.showCheckIns = false;
    this.showFileSharing = false;
    this.showCommunity = false;
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "notifications":
        this.showNotifications = true;
        break;
      case "checkIns":
        this.showCheckIns = true;
        break;
      case "fileSharing":
        this.showFileSharing = true;
        break;
      case "community":
        this.showCommunity = true;
        break;
      default:
        break;
    }
  }

  isAdmin() {
    return this.authService.isAdmin()
  }

  openMarketingKit() {
    const dialogRef = this.dialog.open(MarketingKitComponent, { data: { }, width: '1000px'})
  }
}
