import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailTemplate } from 'src/app/model/payment-settings.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-template-dialog',
  templateUrl: './email-template-dialog.component.html',
  styleUrls: ['./email-template-dialog.component.css']
})
export class EmailTemplateDialogComponent {

  template: EmailTemplate
  type: string
  requiredPlaceholders: string[]

  constructor(public dialogRef: MatDialogRef<EmailTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { template: EmailTemplate, type: string, requiredPlaceholders: string[] }, private toastr: ToastrService) {
    this.template = data.template
    this.template.body = this.template.body.replaceAll('<br>', '\n')
    this.type = data.type
    this.requiredPlaceholders = data.requiredPlaceholders
  }

  ngOnInit(): void {
  }

  onBodyChanged(text: string) {
    this.template.body = text
  }

  onPlaceholderClicked(placeholder: string) {
    this.toastr.success("Platzhalter in Zwischenablage kopiert", "",  {
      positionClass: 'toast-bottom-center'
    })
  }

  containsAllRequiredPlaceholders() {
    if (!this.requiredPlaceholders) return true
    for (const placeholder of this.requiredPlaceholders) {
      if (!this.template.body?.includes('{{' + placeholder + '}}')) {
        return false
      }
    }
    return true
  }

  onCancelDialog() {
    this.dialogRef.close({template: null});
  }
  onConfirmDialog() {
    this.template.body = this.template.body.replaceAll('\n', '<br>').replaceAll('  ', '<br>')
    this.dialogRef.close({template: this.template});
  }
}
