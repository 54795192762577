import { Component, Input } from '@angular/core';
import { User } from '../model/user.model';
import { Questionaire } from '../model/questionaires.model';
import { FirestoreService } from '../services/firestore.service';
import { QuestionairesService } from '../services/questionaires.service';
import { ToastrService } from 'ngx-toastr';
import { Metric } from '../model/metric.model';

@Component({
  selector: 'app-checkin-assignment-view',
  templateUrl: './checkin-assignment-view.component.html',
  styleUrls: ['./checkin-assignment-view.component.css']
})
export class CheckinAssignmentViewComponent {

  constructor(public userService: FirestoreService, public questionaireService: QuestionairesService, private toastr: ToastrService) {
  }

  public user: User
  @Input() set User(value: User){
    this.user = value
    this.init()
  }
  public allQuestionaires: Questionaire[]
  public loading = true

  async init() {
    this.allQuestionaires = await this.questionaireService.getQuestionaires()
    if (this.user) {
      await this.userService.loadAllAssignedQuestionairesForUser(this.user)
    }
    this.loading = false
  }

  getDailyQuestionaires() {
    return this.allQuestionaires?.filter(x => x.type == 'daily')
  }
  getWeeklyQuestionaires() {
    return this.allQuestionaires?.filter(x => x.type == 'weekly')
  }
  getTrainingFeedbackQuestionaires() {
    return this.allQuestionaires?.filter(x => x.type == 'training_feedback')
  }

  getSelectedDailyQuestionaire() {
    if (this.user?.assignedMetrics?.length > 0) {
      var questionaireId = this.user.assignedMetrics[0].questionaireId
      if (questionaireId) {
        var questionaire = this.allQuestionaires.find(x => x.id == questionaireId)
        return questionaire
      }
    }
    return null
  }
  getSelectedWeeklyQuestionaire() {
    if (this.user?.weeklyQuestionaireId) {
      var questionaire = this.allQuestionaires.find(x => x.id == this.user.weeklyQuestionaireId)
      return questionaire
    }
    return null
  }
  getSelectedTrainingFeedbackQuestionaire() {
    if (this.user?.trainingFeedbackQuestionaireId) {
      var questionaire = this.allQuestionaires.find(x => x.id == this.user.trainingFeedbackQuestionaireId)
      return questionaire
    }
    return null
  }

  async onSelectDailyCheck(questionaire: Questionaire) {
    if (questionaire) {
      this.user.assignedMetrics = questionaire.questions.map(x => {
        var metric = x.metric?.clone() ?? new Metric()
        metric.groupHeading = x.groupHeading
        metric.content = x.content
        metric.imagePath = x.imagePath
        metric.required = x.required
        return metric
      })
      this.user.assignedMetrics[0].questionaireId = questionaire.id
      var date = new Date()
      this.user.assignedMetrics.forEach(metric => {
        metric.applied = false
        metric.assignmentDate = date
      });
      await this.userService.insertAssignedMetrics(this.user, this.user.assignedMetrics)
      this.user.dailyQuestionaireId = questionaire.id
      await this.userService.saveAssignedQuestionaireSettings(this.user)
      this.toastr.success("Daily Check gespeichert.", "",  {
        positionClass: 'toast-bottom-center'
      });
    } else {
      this.user.assignedMetrics = []
      var metric = new Metric()
      metric.metricId = 'NUT_bodyWeight'
      metric.assignmentDate = new Date()
      this.user.assignedMetrics.push(metric)
      await this.userService.insertAssignedMetrics(this.user, this.user.assignedMetrics)
      this.user.dailyQuestionaireId = null
      await this.userService.saveAssignedQuestionaireSettings(this.user)
      this.toastr.success("Daily Check entfernt.", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }

  async onSelectWeeklyCheck(questionaire: Questionaire) {
    if (questionaire) {
      this.user.weeklyQuestionaireId = questionaire.id
      if (!this.user.nextWeeklyQuestionaireDate) {
        var date = new Date()
        var day = date.getDay()
        var diff = date.getDate() - day + (day == 0 ? -6 : 1)
        var nextMonday = new Date(date.setDate(diff))
        nextMonday.setHours(0, 0, 0, 0)
        this.user.nextWeeklyQuestionaireDate = nextMonday
      }
    } else {
      this.user.weeklyQuestionaireId = null
      this.user.nextWeeklyQuestionaireDate = null
    }
    await this.userService.saveAssignedQuestionaireSettings(this.user)
    this.toastr.success("Weekly Check gespeichert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  async onSelectTrainingFeedbackQuestionaire(questionaire: Questionaire) {
    this.user.trainingFeedbackQuestionaireId = questionaire?.id ?? null
    await this.userService.saveAssignedQuestionaireSettings(this.user)
    if (questionaire && !questionaire.assignedUids.includes(this.user.uid)) {
      questionaire.assignedUids.push(this.user.uid)
      await this.questionaireService.updateQuestionaire(questionaire)
    }
    for (var q of this.getTrainingFeedbackQuestionaires()) {
      if (q.id != questionaire?.id && q.assignedUids.includes(this.user.uid)) {
        q.assignedUids = q.assignedUids.filter(x => x != this.user.uid)
        await this.questionaireService.updateQuestionaire(q)
      }
    }
    this.toastr.success("Trainingsfeedback gespeichert.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  async onWeeklyCheckDayChanged(weekday: string) {
    var dayIndex = 0
    switch (weekday) {
      case 'monday': dayIndex = 1; break
      case 'tuesday': dayIndex = 2; break
      case 'wednesday': dayIndex = 3; break
      case 'thursday': dayIndex = 4; break
      case 'friday': dayIndex = 5; break
      case 'saturday': dayIndex = 6; break
      case 'sunday': dayIndex = 0; break
    }
    var date = new Date()
    date.setHours(0, 0, 0, 0)
    if (dayIndex < date.getDay()) {
      var diff = date.getDate() - date.getDay() + 7 + dayIndex
      var nextDay = new Date(date.setDate(diff))
      this.user.nextWeeklyQuestionaireDate = nextDay
    } else {
      var diff = date.getDate() - date.getDay() + dayIndex
      var nextDay = new Date(date.setDate(diff))
      this.user.nextWeeklyQuestionaireDate = nextDay
    }
    await this.userService.saveAssignedQuestionaireSettings(this.user)
  }
  getSelectedWeeklyCheckInDay() {
    var date = this.user?.nextWeeklyQuestionaireDate
    return date?.getPrintableWeekday()
  }

}
