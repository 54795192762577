import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {
  private hasUnsavedChanges = new BehaviorSubject<boolean>(false);
  public unsavedChanges$: Observable<boolean> = this.hasUnsavedChanges.asObservable();

  constructor() { }

  setHasUnsavedChanges(hasChanges: boolean): void {
    this.hasUnsavedChanges.next(hasChanges);
  }

  getHasUnsavedChanges(): boolean {
    return this.hasUnsavedChanges.getValue();
  }

}
