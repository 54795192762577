import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UnsavedChangesService} from "../services/unsavedchanges.service";

@Directive({
  selector: '[appUnsavedchanges]'
})
export class UnsavedChangesDirective implements OnChanges {
  @Input() set appUnsavedChanges(value: any) {
    this.value = value;
    this.checkChanges();
  }

  private value: any;
  private initialValue: any;

  constructor(private elementRef: ElementRef, private unsavedChangesService: UnsavedChangesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appUnsavedChanges']) {
      this.initialValue = JSON.parse(JSON.stringify(this.value));
      this.checkChanges();
    }
  }

  private checkChanges(): void {
    if (JSON.stringify(this.value) !== JSON.stringify(this.initialValue)) {
      this.unsavedChangesService.setHasUnsavedChanges(true);
    } else {
      this.unsavedChangesService.setHasUnsavedChanges(false);
    }
  }

}
