import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Coach } from '../../model/coach.model';
import { FirestoreService } from '../../services/firestore.service';
import { UtilityService } from '../../services/utility.service';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-settings-v1',
  templateUrl: './settings-v1.component.html',
  styleUrls: ['./settings-v1.component.css']
})

export class SettingsV1Component implements OnInit {

  constructor(private router: Router, public userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, private authService: AuthService, private route: ActivatedRoute, private imageCompressor: NgxImageCompressService) {
    this.isDarkModeEnabled = localStorage.getItem('darkMode') == 'true'
  }

  public environment = environment;

  public coach: Coach

  isDarkModeEnabled: boolean
  darkModeSettingChanged = false

  customInAppBrandingEnabled: boolean = false
  logoFileName: string
  logoLightFileName: string
  welcomeDialogHeading: string
  welcomeDialogContent: string
  hasBrandingChanges: boolean = false

  public showAccountSettings: boolean = false
  public showCustomInAppBrandingSettings: boolean = false
  public showTeamManagement: boolean = false

  logoUrl: string
  logoLightUrl: string
  logoFile: File
  logoLightFile: File

  ngOnInit(): void {
    if (environment.isWhitelabel) {
      this.showTeamManagement = true
    } else {
      this.showCustomInAppBrandingSettings = true
    }

    this.coach = this.userService.getLoggedInUser().coach
    var licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid || this.userService.getLoggedInUser().uid
    this.userService.getBrandingSettings(licenceHolderUid).toPromise().then(snapshot => {
      if (snapshot) {
        this.logoFileName = snapshot.data().logoFileName
        this.logoLightFileName = snapshot.data().logoLightFileName
        this.customInAppBrandingEnabled = snapshot.data().customInAppBrandingEnabled
        this.welcomeDialogHeading = snapshot.data().welcomeDialogHeading
        this.welcomeDialogContent = snapshot.data().welcomeDialogContent

        if (this.logoFileName) {
          this.userService.getBrandingImage(licenceHolderUid, this.logoFileName).subscribe(res => {
            this.logoUrl = res
          })
        }
        if (this.logoLightFileName) {
          this.userService.getBrandingImage(licenceHolderUid, this.logoLightFileName).subscribe(res => {
            this.logoLightUrl = res
          })
        }
      }

    })
    this.route.paramMap.subscribe(async (param) => {
      var tab = this.route.snapshot.queryParamMap.get("tab");
      if (tab == 'account') {
        this.swapTab('accountSettings')
      }
    })
  }

  onDarkModeEnabledChanged(value: boolean) {
    this.isDarkModeEnabled = value
    this.darkModeSettingChanged = true
    localStorage.setItem('darkMode', value ? 'true' : 'false')
  }

  onVoiceMessagesEnabledChanged() {
    var enabled = !this.coach.coacheeVoiceMessageEnabled
    this.userService.updateVoiceMessagesEnabled(this.coach, enabled)
  }
  onHideChatMessageSender() {
    this.coach.hideChatMessageSender = !this.coach.hideChatMessageSender
    this.userService.updateCoach(this.coach)
  }

  resetTabs() {
    this.showCustomInAppBrandingSettings = false;
    this.showAccountSettings = false;
    this.showTeamManagement = false;
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "inAppBrandingSettings":
        this.showCustomInAppBrandingSettings = true;
        break;
      case "accountSettings":
        this.showAccountSettings = true;
        break;
      case "teamManagement":
        this.showTeamManagement = true;
        break;
      default:
        break;
    }
  }

  onCustomBrandingEnabledChanged(enabled: boolean) {
    this.customInAppBrandingEnabled = enabled
    this.hasBrandingChanges = true
  }
  onUploadLogoImage() {
    document.getElementById('input-logoimage').click()
  }
  onLogoImageUploaded(e) {
    if (!e.target.files[0].name.toLowerCase().endsWith('.png')) {
      return
    }
    var image = new Image();
    this.logoFile = e.target.files[0];
    this.logoFileName = this.logoFile.name
    image.src = URL.createObjectURL(this.logoFile);

    image.onload = () => {
      this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, 100, 60).then((compressedFile) => {
        this.logoFile = this.dataURLtoFile(compressedFile, this.logoFileName);
      })
    }
    this.logoUrl = image.src
    this.hasBrandingChanges = true
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }
  onUploadLogoLightImage() {
    document.getElementById('input-logolightimage').click()
  }
  onLogoLightImageUploaded(e) {
    if (!e.target.files[0].name.toLowerCase().endsWith('.png')) {
      return
    }
    var image = new Image();
    this.logoLightFile = e.target.files[0];
    this.logoLightFileName = this.logoLightFile.name
    image.src = URL.createObjectURL(this.logoLightFile);

    image.onload = () => {
      this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, 100, 60).then((compressedFile) => {
        this.logoLightFile = this.dataURLtoFile(compressedFile, this.logoLightFileName);
      })
    }
    this.logoLightUrl = image.src
    this.hasBrandingChanges = true
  }
  onHeadingChanged(value: string) {
    this.welcomeDialogHeading = value
    this.hasBrandingChanges = true
  }
  onContentChanged(value: string) {
    this.welcomeDialogContent = value
    this.hasBrandingChanges = true
  }

  onSaveChanges() {
    if ((this.logoUrl && this.logoLightUrl && this.welcomeDialogHeading && this.welcomeDialogContent)) {
      if (this.logoFile) {
        this.userService.uploadBrandingImage(this.userService.getLoggedInUser(), this.logoFileName, this.logoFile)
      }
      if (this.logoLightFile) {
        this.userService.uploadBrandingImage(this.userService.getLoggedInUser(), this.logoLightFileName, this.logoLightFile)
      }
      this.userService.setBrandingSettings(this.userService.getLoggedInUser(), this.customInAppBrandingEnabled, this.logoFileName || null, this.logoLightFileName || null, this.welcomeDialogHeading || null, this.welcomeDialogContent || null)
      this.toastr.success("Gespeichert. Das Branding wird bei deinen Nutzer:innen eingeführt.", "",  {
        positionClass: 'toast-bottom-center'
      });
      this.hasBrandingChanges = false
    } else {
      this.toastr.error("Speichern fehlgeschlagen. Stelle sicher, dass alle Angaben vollständig sind.", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }

  isAdmin() {
    return this.authService.isAdmin()
  }
}
