<div class="dialog-topbar">
    <button class="button-filled small create-Exercise" (click)="onCreateExercise()">Übung erstellen</button>
    <div class="icon-button float-right" (click)="onCancelSelection()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row justify-content-center">
        <app-exercises-table [TrainingExercises]="trainingService.MergedTrainingExercises" (multipleTrainingExercisesSelected)="onMultiSelectionChanged($event)" [multiselect]="true"></app-exercises-table>
    </div>
</div>
<div class="dialog-bottombar" mat-dialog-actions>
    <button *ngIf="selectedTrainingExercises?.length > 0" class="button-filled small select-exercises" (click)="onTakeSelection(selectedTrainingExercises)">Auswahl übernehmen</button>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>