<div class="page-content">
    <div class="container-fluid">
        <h2 class="">Kundenverwaltung</h2>
        <div class="row">
            <div *ngIf="userService.getLoggedInUser()?.hasPortalAccess()" class="col-12 col-xxl-10">
                <div class="layout-box">
                    <h3 class="display-inlineblock marginright-25">Einladungen</h3>
                    <button class="button-filled small connect-client" (click)="onConnectClient()">Coachee einladen</button>
                    <br>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-12 textalign-center tab-button-group adjust-mobile">
                            <button class="tab-button {{currentTab == tabOpen ? 'active' : ''}}" (click)="onChangeTab(tabOpen)">
                                Offen ({{getOpenPendingLicences()?.length}})
                                <div class="underline"></div>
                            </button>
                            <button class="tab-button {{currentTab == tabPlanned ? 'active' : ''}}" (click)="onChangeTab(tabPlanned)">
                                Angenommen ({{getPlannedPendingLicences()?.length}})
                                <div class="underline"></div>
                            </button>
                        </div>
                    </div>
                    <div class="spacer-25"></div>
                    <div *ngIf="getPendingLicences()?.length == 0" >Keine Einladungen ausstehend.<br><br></div>
                    <div class="row licence-wrapper" *ngFor="let lid of (currentTab == tabOpen ? getOpenPendingLicences() : getPlannedPendingLicences()); let i = index">
                        <div class="col col-12 col-sm-5 col-md-5 col-xl-4">
                            {{lid.presetName || lid.user?.getName() || lid.email || lid.lid}}
                        </div>
                        <div class="col col-7 col-sm-4 col-md-3 col-lg-4 col-xl-4">
                            <div *ngIf="!lid.plannedActivationDate" class="icon-button small" (click)="onShowLicence(lid)">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Erneut einladen
                            </div>
                            <div *ngIf="lid.plannedActivationDate" class="icon-button small" (click)="onActivatePendingLicence(lid)">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Sofort aktivieren
                            </div>
                        </div>
                        <div class="col col-5 col-sm-3 col-md-4 col-lg-3 col-xl-3">
                            <div *ngIf="!lid.plannedActivationDate" class="icon-button" (click)="onDeletePendingLicence(lid)">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                Verwerfen
                            </div>
                            <div *ngIf="lid.plannedActivationDate" class="label">
                                Startet am {{lid.plannedActivationDate?.asFormatedString()}}
                            </div>
                        </div>
                    </div>
                    <div class="label">{{userService.getTeamLicences()?.length}} Lizenzen aktiv ({{getPlannedPendingLicences()?.length}} Einladungen angenommen, {{getOpenPendingLicences()?.length}} ausstehend).</div>
                </div>
            </div>
            <div class="col-12 col-xxl-10">
                <div class="layout-box">
                    <h3>Aktive Kunden</h3>
                    <input id="clientsearch-input" class="input wide client" (input)="onClientSearchInputChanged($event.target.value)" placeholder="Suchen" type="text">
                    <div *ngIf="clientSearchInput?.length > 0" class="icon-button deletesearch-icon delete-clientsearch" (click)="onDeleteClientSearchInput()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="label float-right">{{usedTrialLicences}} von {{user?.licenceHolder?.availableTrialLicences || '1'}} Trainerlizenzen in Verwendung.</div>
                    <div class="spacer-25"></div>
                    <div class="row licence-wrapper" *ngFor="let c of filteredClients">
                        <div class="col col-7 col-sm-12 col-lg-5 col-xl-3 col-xxl-2 order-1">
                            {{c.getName()}} 
                            <div *ngIf="user.coach?.canAccessUser(c)" class="icon-button" routerLink="/client/{{c.uid}}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                            </div>
                            <div *ngIf="c.licence?.redeemDate && !c.licence?.activationDate" class="label">Verbunden am {{c.licence?.redeemDate?.asFormatedString()}}</div>
                            <div *ngIf="c.licence?.activationDate" class="label">Verbunden am {{c.licence?.activationDate?.asFormatedString()}}</div>
                        </div>
                        <div class="col col-6 col-sm-4 col-md-5 col-xl-3 col-xxl-2 order-3 order-sm-2">
                            <div class="spacer-5"></div>
                            <input class="input checkbox" type="checkbox" [checked]="c.licence?.isTrialLicence" (change)="onTrialLicenceToggled(c.licence, $event.target.checked)" tooltip="{{(canCheckAsTrialLicence || c.licence.isTrialLicence) ? 'Trainerlizenzen sind Lizenzen, die du und dein Coaching-Team für die interne Nutzung benötigen und nicht abgerechnet werden.' : 'Deine Trainerlizenzen sind aufgebraucht. Wenn du für dein Team mehr davon benötigst, melde dich bei uns!'}}" [disabled]="!c.licence?.isTrialLicence && !canCheckAsTrialLicence"> Trainerlizenz
                            <div *ngIf="c.licence?.isTrialLicence && isCommunityEnabled()" tooltip="Wenn du in der Kunden-App in der Community als Coach agieren möchtest, wähle hier deinen Coach-Account aus.">
                                <div class="spacer-5"></div>
                                <div class="label">Coach-Account</div>
                                <div class="d-inline-block wrapper-timerange-selection">
                                    <button class="button-filled light small dropdown-toggle margin-inbetween link" type="button" id="dropdownCoachReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getCoachByUid(c.coachReferenceUid)?.name ?? 'Auswählen'}}</button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownCoachReference">
                                        <button class="dropdown-item" type="button" (click)="onSetCoachReference(c, null)">Kein Coach</button>
                                        <div *ngFor="let coach of coachesOfLicenceHolder">
                                            <button class="dropdown-item" type="button" (click)="onSetCoachReference(c, coach)">{{coach.name}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="coachesOfLicenceHolder?.length > 1" class="col-lg-6 col-xl-3 order-1 d-none d-lg-block">
                            <div class="label">Coach:</div>
                            <div class="d-inline-block wrapper-timerange-selection">
                                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownCoaches" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="!user.coach?.canAssignCoachesAndLicences()">{{getCoachForUser(c)?.name}}</button>
                                <div class="dropdown-menu" aria-labelledby="dropdownCoaches">
                                    <div *ngFor="let coach of coachesOfLicenceHolder">
                                        <button class="dropdown-item" type="button" (click)="onAssignNewCoach(c, coach)">{{coach.name}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-6 col-sm-4 col-md-4 col-xl-3 col-xxl-2 order-4 padding-0 order-sm-3">
                            <ng-container *ngIf="!c.licence?.productPurchaseId">
                                <input [ngModel]="c.licence?.expirationDate?.asFormatedString()" class="input full-date small" type="text" placeholder="Ablaufdatum" disabled>
                                <div class="open-datepicker-overlay" (click)="expirationDatePicker.open()"></div>
                                <input [matDatepicker]="expirationDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onExpirationDateChanged(c.licence, $event.value?.toDate())">
                                <div class="icon-button" *ngIf="c.licence?.expirationDate != null" (click)="onExpirationDateChanged(c.licence, null)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="expirationDatePicker" class="datepicker-button visibility-hidden width-0"></mat-datepicker-toggle>
                                <mat-datepicker #expirationDatePicker></mat-datepicker>
                            </ng-container>
                            <ng-container *ngIf="c.licence.productPurchaseId">
                                <div class="label padding-10">Endet mit Abonnement {{c.licence.expirationDate ? ' am ' + c.licence.expirationDate?.asFormatedString() : ''}}</div>
                            </ng-container>
                        </div>
                        <div class="col col-5 col-sm-4 col-md-3 col-xl-2 order-2 order-sm-4">
                            <div *ngIf="!c.licence.productPurchaseId" class="icon-button small deactivate-license-button" (click)="onDeactivateLicence(c.getLid())">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                </svg>
                                Deaktivieren
                            </div>
                            <div *ngIf="c.licence.productPurchaseId" class="label padding-10">
                                Mit Abonnement verbunden
                            </div>
                        </div>
                    </div>

                    <div class="label">{{filteredClients?.length}} Kunden gefunden</div>
                    
                    <div id="deactivate-licence-dialog" class="dialog-wrapper">
                        <div class="dialog layout-box">
                            <div class="dialog-topbar">
                                <h3 class="margin-0">Lizenz deaktivieren</h3>
                            </div>
                            <div class="dialog-content-wrapper">
                                <p>
                                    Möchtest du die Lizenz wirklich deaktivieren?<br>
                                    Dein:e Kunde:in kann danach nicht mehr in der Coaching Zone von dir betreut werden.
                                </p>
                                <div class="actions">
                                    <button class="button-filled small light" (click)="cancelDeactivation()">Abbrechen</button>
                                    <button class="button-filled small light" (click)="confirmDeactivation()">Deaktivieren</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <chat>
                </chat>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>