import { Activity } from './activity.model';
import { Meal } from './meal.model';
import { NutritionalGoal } from './nutritionalgoal.model';
import { NutritionalSummary } from './nutritionalsummary.model';

export class NutritionStatisticsItem {
    date: Date;
    nutritionalSummary: NutritionalSummary;
    nutritionalGoal: NutritionalGoal;
    meals: Meal[];
    activities: Activity[];

    calories: number;
    calorieGoal: number;
    calorieSurplus: number;
    calorieDeficit: number;

    carbohydrates: number;
    protein: number;
    fat: number;
    carbohydratesGoal: number;
    proteinGoal: number;
    fatGoal: number;

    sugar: number;
    saturatedFat: number;
    fibre: number;
    salt: number;

    getCalories() {
        if (this.calories > 0) return this.calories
        if (this.calorieGoal && this.calorieSurplus) return this.calorieGoal + this.calorieSurplus
        return 0
    }

}