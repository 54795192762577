import { AssignedQuestionaire, Question, Questionaire } from './../model/questionaires.model';
import { TrackedTrainingSession, TrackedTrainingExercise, TrackedVideoRecording } from './../model/training-monitoring.model';
import { TrainingPlan, PlannedTrainingExercise, TrainingSession, IFirebaseTrainingSession, VideoRecordingRequest, RecordingFrequency, SuperSetConfig } from './../model/training-plan.model';
import { MuscleInformation, ExerciseTypeTranslation, ITrainingExercise, ITrainingExerciseOverwrite, TrainingExerciseOverwrite, MergedTrainingExercise, TrainingExercise, MuscleTranslation, MuscleGroupTranslation, MovementTypeMap, EquipmentTranslation } from './../model/training-exercise';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { UtilityService } from 'src/app/services/utility.service';
import { FcmService } from './fcm.service';
import { AutomaticPushNotification } from 'src/app/model/automatic-push-notification.model';
import { Inject, Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { AuthService } from '../auth/auth.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Meal } from '../model/meal.model';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { Food } from '../model/food.model';
import { Licence } from '../model/lid.model';
import { Activity } from '../model/activity.model';
import { BodyData } from '../model/bodydata.model';
import { ToastrService } from 'ngx-toastr';
import { interval, take, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable, combineLatest, of, BehaviorSubject, Subscription, Subject, merge } from 'rxjs';
import { NutritionalGoal } from '../model/nutritionalgoal.model';
import { Chat } from '../model/chat.model';
import { Chatmessage } from '../model/chatmessage.model';
import { PortalSettingsCoach } from '../model/portalsettings-coach.model';
import { WaterIntake } from '../model/waterintake.model';
import { DailyCondition } from '../model/dailycondition.model';
import { ServingInquiry } from '../model/servinginquiry.model';
import { BaseNutritionFact } from '../model/basenutritionfact.model';
import { LicenceMonitoringItem } from '../model/licence-monitoring-item';
import { Coach } from '../model/coach.model';
import { Note } from '../model/note.model';
import { Metric, MetricGroup } from '../model/metric.model';
import { MetricData } from '../model/metricdata.model';
import { Metadata, MetadataUser } from '../model/metadata-user.model';
import { NutritionService } from './nutrition.service';
import { CoachSubscription } from '../model/subscription.model';
import { PortalSettingsLicenceHolder } from '../model/portalsettings-licenceholder.model';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { DxoGroupOperationDescriptionsModule } from 'devextreme-angular/ui/nested';
import { EventLog } from '../model/event-log.model';
import { FirestoreNutritionPlanService } from './firestore-nutritionplan.service';
import { CommonFirebase, IndividualFirebase } from '../app.module';
import { LicenceHolder } from '../model/licenceholder.model';
import { environment } from 'src/environments/environment';
import { SharedFile } from '../model/sharedfile.model';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { NutritionStatisticsItem } from '../model/nutritionstatistics.model';
import { QuestionaireResult } from '../model/questionaires.model';
import { Timestamp, doc } from '@angular/fire/firestore';
import { Product } from '../model/product.model';
import { Payment } from '../model/payment.model';
import { ProductPurchase } from '../model/product-purchase.model';
import { PaymentSettings } from '../model/payment-settings.model';
import { Recipe } from '../model/recipe.model';
import { TrainingVariable } from '../model/training-variables.model';
import { ActivityFact } from '../model/activity-fact.model';
import { TrainingSettingsLicenceHolder } from '../model/training-settings-licence-holder.model';
import { CustomerPaymentSettings } from '../model/customer-payment-settings.model';
import { CoachInvoice } from '../model/invoice-coach.model';
import { LicenceConnectionRequest } from '../model/licence-connection-request';
import { CardioZoneGroup } from '../model/cardio-zone-group.model';
import { DateTime } from '@syncfusion/ej2-angular-charts';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  public APP_VERSION_NUMBER = 45

  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }
  get firebaseFunctions(): AngularFireFunctions {
    return this.mainFirebase.functions
  }

  private nutritionService: NutritionService
  private nutritionPlanService: FirestoreNutritionPlanService

  private user: User;
  public observableUser = new Subject<User>();
  private clientsOfCoach: User[];
  public observableClients = new Subject<{changed: User[], all: User[]}>();
  private clientsOfLicenceHolder: User[];
  private newConnectedClients: User[];
  public clientsLoaded = false
  public coachesOfLicenceHolder: Coach[]

  public activeLicences: Licence[]
  public expiringLicences: Licence[]
  private pendingLicences: Licence[]
  private allLicences: Licence[]
  public licenceConnectionRequests: LicenceConnectionRequest[]

  private weekdaysShort = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  public metrics: Metric[] = []
  public observableMetrics = new Subject<Metric[]>();

  public allFirebaseMetrics: Metric[] = []

  public metricGroups: MetricGroup[] = []

  public fileUploadProgress: Map<string, number> = new Map<string, number>();


  constructor(private mainFirebase: IndividualFirebase, private commonFirebase: CommonFirebase, private toastr: ToastrService, private fcmService: FcmService, private utilityService: UtilityService) {
    this.logout();
  }

  logout() {
    if (this.clientsSubscription) this.clientsSubscription.unsubscribe()
    if (this.licenceSubscription) this.licenceSubscription.unsubscribe()
    this.user = null
    this.clientsOfCoach = []
    this.clientsOfLicenceHolder = []
    this.newConnectedClients = []
    this.expiringLicences = []
    this.activeLicences = []
    this.allLicences = null
    this.pendingLicences = null
    this.clientGroups = []
    this.clientsLoaded = false
    this.coachesOfLicenceHolder = null
  }

  onLoggedIn(uid: string) {
    this.initUser(uid)
  }
  setNutritionService(nutritionService: NutritionService) {
    this.nutritionService = nutritionService
  }
  setNutritionPlanService(nutritionPlanService: FirestoreNutritionPlanService) {
    this.nutritionPlanService = nutritionPlanService
  }

  areLicencesLoaded() {
    return this.allLicences != null
  }

  loadMetrics() {
    this.metrics = []
    if (this.user.licenceHolderUid) {
      this.getMetricsByCreatorUid(this.user.licenceHolderUid).then(metrics => {
        if (metrics != null) {
          metrics.forEach(m => {
            if (!m.public && !this.metrics.find(x => x.id == m.id)) this.metrics.push(m)
          })
          this.observableMetrics.next(this.metrics)
          this.loadMetricGroups()
        }
      })
    }
    this.getPublicMetrics().then(metrics => {
      if (metrics != null) {
        metrics.forEach(m => {
          if(!this.metrics.find(x => x.id == m.id))
          {
            this.metrics.push(m)
          }
        });
        this.observableMetrics.next(this.metrics)
      }
    })
    this.getPublicMetrics(this.mainFirebase.firestore).then(metrics => {
      if (metrics != null) {
        metrics.forEach(m => {
          if(!this.metrics.find(x => x.id == m.id))
          {
            this.metrics.push(m)
          }
        })
        this.observableMetrics.next(this.metrics)
      }
    })
  }

  async loadMetricGroups() {
    try {
      this.metricGroups = []
      const documents = await this.firestore
          .collection('MetricGroups')
          .ref
          .where('licenceHolderUid', 'in', [this.user.licenceHolderUid, 'nutrilize'])
          .get();
      for (const document of documents.docs) {
        const metricGroup = new MetricGroup(document.data() as MetricGroup);
        metricGroup.id = document.id;
        for (const metricId of metricGroup.metricIds) {
          const metric = this.metrics.find(x => x.id == metricId);
          if(metric != null){
            metricGroup.metrics.push(metric);
          }
        }
        this.metricGroups.push(metricGroup);
      }
    } catch (error) {
      console.log(error)
    }
  }

  async saveMetricGroup(metricGroup: MetricGroup) {
    try {
      const result = await this.firestore
        .collection('MetricGroups')
        .add(this.convertMetricGroupTemplateToFirebaseData(metricGroup));
      await this.loadMetricGroups();
    } catch (error) {
      console.error('Error saving metric groups:', error);
    }
  }

  async updateMetricGroup(metricGroup: MetricGroup) {
    try {
      const result = await this.firestore
        .collection('MetricGroups')
        .doc(metricGroup.id)
        .update(this.convertMetricGroupTemplateToFirebaseData(metricGroup));
      await this.loadMetricGroups();
    } catch (error) {
      console.error('Error updating metric groups:', error);
    }
  }

  private convertMetricGroupTemplateToFirebaseData(metricGroup: MetricGroup):unknown{
    return {
      name: metricGroup.name.AsMap(),
      metricIds: metricGroup.metricIds,
      licenceHolderUid: metricGroup.licenceHolderUid,
      timestamp: new Date(),
    }
  }

  async deleteMetricGroup(metricGroup: MetricGroup){
    await this.firestore.collection('MetricGroups').doc(metricGroup.id).delete()
    await this.loadMetricGroups();
  }

  print(text: string) {
    console.log(text)
  }

  coachesSubscription: Subscription

  initUser(uid: string) {
    this.user = new User();
    this.user.uid = uid
    this.user.isCoach = false;
    this.user.isUser = false;
    this.user.meals = [];

    // Fetch Coach data
    this.firestore.collection<any>('Coaches').doc(uid).ref.get().then(coach => {
      if (coach.exists && coach.data() != undefined) {
        this.user.uid = coach.data().uid;
        this.user.coach = new Coach(coach.data() as Coach)
        this.user.licenceHolderUid = coach.data().licenceHolderUid
        this.user.coachUid = uid
        this.user.isCoach = true;
        if(coach.data().chatGenerationKey){
          this.user.chatGenerationKey = coach.data().chatGenerationKey;
        }
        this.user.name = coach.data().name
        this.fcmService.initPushNotififcations(this.user.coach, this);
        this.user.hasRecipeDatabaseAccess = coach.data().hasRecipeDatabaseAccess || false
        this.getPortalSettingsForCoach(this.user.coach)
        if (this.user.licenceHolderUid) {
          var licenceHolderSubscription = this.getLicenceHolderByUid(this.user.licenceHolderUid).subscribe(licenceHolder => {
            if (this.user.licenceHolderUid == this.user.uid) this.user.isLicenceHolder = true
            this.user.licenceHolder = licenceHolder
            this.observableUser.next(this.user)
            this.loadSubscriptions(this.user)
            licenceHolderSubscription.unsubscribe()
            this.loadCoachInvoices(true)
          })
          this.getPortalSettingsForLicenceHolder(this.user)
          this.getTrainingSettingsForLicenceHolder(this.user);
          this.coachesSubscription?.unsubscribe()
          this.coachesSubscription = this.getAllCoachesByLicenceHolderUid(this.user.licenceHolderUid).subscribe(coaches => {
            for (let coach of coaches) {
              if (coach.uid != this.user.uid) {
                this.getPortalSettingsForCoach(coach)
              }
            }
            this.coachesOfLicenceHolder = coaches
          })
        }
        this.observeClientsAndLicences()
        this.user.disabled = coach.data().disabled || false
        this.getProfilePictureForUser(this.user);
        this.firestore.collection('Coaches').doc(uid).set({lastLogin: new Date()}, {merge: true})
      }
      if (!(this.metrics?.length > 0)) this.loadMetrics()
      this.observableUser.next(this.user)
    });

    // Fetch User data.
    this.firestore.collection<any>('Users').doc(uid).ref.get().then(user => {
      if (user.exists && user.data() != undefined) {
        if (this.user.name == undefined || this.user.name.length == 0) this.user.name = user.data().name;
        this.user.birthDate = user.data().birthDate;
        this.user.age = user.data().age;
        this.user.bodyHeight = user.data().bodyHeight;
        this.user.bodyWeight = user.data().bodyWeight;
        this.user.isUser = true;
        this.user.uid = uid
        this.user.versionCode = user.data().versionCode
        this.user.operatingSystem = user.data().operatingSystem
        this.user.fcmToken = user.data().fcmToken
        this.user.gender = user.data().gender
        this.user.licenceId = (user.data() as any).cachedData?.licenceId
        this.user.connectedLicenceHolderUid = (user.data() as any).cachedData?.licenceHolderUid
        if (!this.user.connectedLicenceHolderUid) {
          this.firestore.collection<any>('Users').doc(uid).collection('Licences').doc('Coaching').get().toPromise().then(licence => {
            if (licence.exists && licence.data()) {
              var l = licence.data() as Licence;
              if(l.active) {
                this.user.connectedLicenceHolderUid = l.licenceHolderUid
              }
            }
          })
        }
        this.observableUser.next(this.user)
        this.getProfilePictureForUser(this.user);
      }
    });
  }

  async initIntercomWidget(hasActiveSubscription: boolean, numberOfClients: number) {
    if (environment.isWhitelabel && environment.firebaseProjectId != 'traindoo-app') return;

    var email = this.user.coach.communicationEmailAddress
    if (!email) {
      email = await this.getEmailForUser(this.user.uid)
      if (email) {
        this.user.coach.communicationEmailAddress = email
        await this.firestore.collection('Coaches').doc(this.user.coach.uid).update({communicationEmailAddress: email})
      }
    }
    if (environment.firebaseProjectId == 'traindoo-app') {
      (window as any).Intercom('boot', {
        app_id: 'jurxct14',
        user_id: this.user.coach.uid,
        name: this.user.coach.name,
        email: email,
        company: {
          id: this.user.licenceHolder.uid,
          name: this.user.licenceHolder.name,
          'Is on trial': this.user.licenceHolder.onTrial,
          'Number of clients': numberOfClients,
          'Trial end date': this.user.licenceHolder.trialEndDate
        }
      });
    } else {
      (window as any).Intercom('boot', {
        app_id: 'bnntvtmo',
        user_id: this.user.coach.uid,
        name: this.user.coach.name,
        email: email,
        company: {
          id: this.user.licenceHolder.uid,
          name: this.user.licenceHolder.name,
          'Has active subscription': hasActiveSubscription,
          'Is on trial': this.user.licenceHolder.onTrial,
          'Number of clients': numberOfClients,
        }
      });
    }
    if (this.utilityService.onNativeMobileApp()) {
      (window as any).Intercom('update', {
        "hide_default_launcher": true
      });
    }
  }

  getPortalSettingsForCoach(coach: Coach) {
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').ref.get().then(portal => {
      var portalSettings = new PortalSettingsCoach(portal.data() as PortalSettingsCoach)
      if (portalSettings.lastRecipeDatabaseOpenDate) portalSettings.lastRecipeDatabaseOpenDate = new Date((portal.data() as any).lastRecipeDatabaseOpenDate.seconds * 1000)
      if (portalSettings.maxVersionNumberNewsDisplayed == -1) portalSettings.maxVersionNumberNewsDisplayed = 0;
      portalSettings.clientGroups.forEach(clientGroup => {
        if (!this.clientGroups.includes(clientGroup)) this.clientGroups.push(clientGroup)
        this.sortClientGroups()
      })
      coach.portalSettingsCoach = portalSettings
    });
  }
  updatePortalSettingsForCoach(coach: User) {
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').set({
      maxVersionNumberNewsDisplayed: coach.portalSettingsCoach.maxVersionNumberNewsDisplayed, lastRecipeDatabaseOpenDate: coach.portalSettingsCoach.lastRecipeDatabaseOpenDate, showClientsInListView: coach.portalSettingsCoach.showClientsInListView,
      dashboardUids: coach.portalSettingsCoach.dashboardUids, dashboardClientGroups: coach.portalSettingsCoach.dashboardClientGroups, clientGroups: coach.portalSettingsCoach.clientGroups, selectedEventLogTypes: coach.portalSettingsCoach.selectedEventLogTypes, selectedBodyDataGraphTypNames: coach.portalSettingsCoach.selectedBodyDataGraphTypNames, hideTutorialsHint: coach.portalSettingsCoach.hideTutorialsHint, hideCoachAppHint: coach.portalSettingsCoach.hideCoachAppHint,
      hiddenBodyDataTableColumns: coach.portalSettingsCoach.hiddenBodyDataTableColumns, hiddenNutritionTableColumns: coach.portalSettingsCoach.hiddenNutritionTableColumns,
      selectedChatFilterGroupNames: coach.portalSettingsCoach.selectedChatFilterGroupNames, selectedChatFilterCoachUids: coach.portalSettingsCoach.selectedChatFilterCoachUids, clientAnalyticsPageConfigs: coach.portalSettingsCoach.clientAnalyticsPageConfigs,
      paymentAnalyticsPageConfigs: coach.portalSettingsCoach.paymentAnalyticsPageConfigs
    }, { merge: true });
  }

  updateMaxVersionNumberNewsDisplayed(coach: User){
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').update({
      maxVersionNumberNewsDisplayed: coach.portalSettingsCoach.maxVersionNumberNewsDisplayed
    });
  }

  updatePortalSettingsLastRecipeDatebaseOpenDate(coach: User, lastRecipeDatabaseOpenDate: Date) {
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').set({
      lastRecipeDatabaseOpenDate: lastRecipeDatabaseOpenDate
    }, { merge: true });
  }

  getPortalSettingsForLicenceHolder(coach: User) {
    this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/Settings').doc('Portal').ref.get().then(portal => {
      var portalSettings = new PortalSettingsLicenceHolder(portal.data() as PortalSettingsLicenceHolder)
      coach.portalSettingsLicenceHolder = portalSettings
    });
  }
  updatePortalSettingsForLicenceHolder(coach: User) {
    this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/Settings').doc('Portal').set({
      hideCreateSubscriptionHint: coach.portalSettingsLicenceHolder.hideCreateSubscriptionHint, autoShareNewRecipesEnabled: coach.portalSettingsLicenceHolder.autoShareNewRecipesEnabled, hideRecipeMigrationHint: coach.portalSettingsLicenceHolder.hideRecipeMigrationHint
    }, { merge: true });
  }

  getTrainingSettingsForLicenceHolder(coach: User){
    this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/Settings').doc('Training').ref.get().then(training => {
      var trainingSettings = new TrainingSettingsLicenceHolder(training.data() as TrainingSettingsLicenceHolder)
      coach.trainingSettingsLicenceHolder = trainingSettings
    });
  }
  async updateTrainingSettingsForLicencHolder(coach: User){
    await this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/Settings').doc('Training').set({
      trainingVariables: coach.trainingSettingsLicenceHolder.trainingVariables.map(x => x.asMap()),
      cardioZones: coach.trainingSettingsLicenceHolder.cardioZones.map(x => x.asGlobalMap())
    }, { merge: true });
  }

  async setNotExistingGlobalTrainingVariables(trainingVariables: TrainingVariable[]){
    let trainingSettings = this.getLoggedInUser().trainingSettingsLicenceHolder;
    let missingGlobalVariables = (trainingVariables as TrainingVariable[]).filter((x: TrainingVariable) => !trainingSettings.trainingVariables.find(y => y.id == x.id))
    if(missingGlobalVariables.length > 0){
      trainingSettings.trainingVariables = trainingSettings.trainingVariables.concat(missingGlobalVariables.map(x => x.clone()));
      await this.updateTrainingSettingsForLicencHolder(this.getLoggedInUser());
    }
  }

  async setNotExistingUserTrainingVariables(user: User, trainingVariables: TrainingVariable[]) {
    let existingTrainingVariables = user.trainingVariables;
    let missingTrainingVariables = trainingVariables.filter(x => !existingTrainingVariables.find(y => y.id == x.id));
    if(missingTrainingVariables.length > 0){
      user.trainingVariables = user.trainingVariables.concat(missingTrainingVariables.map(x => x.clone()));
      await this.updateTrainingVariables(user, user.trainingVariables);
    }
  }

  getBrandingSettings(licenceHolderUid: string) {
    return this.firestore.collection<any>('LicenceHolders/' + licenceHolderUid + '/Settings').doc('Branding').get()
  }
  setBrandingSettings(coach: User, customInAppBrandingEnabled: boolean, logoFileName: string, logoLightFileName: string, welcomeDialogHeading: string, welcomeDialogContent: string) {
    this.firestore.collection('LicenceHolders/' + (coach.licenceHolderUid || coach.uid) + '/Settings').doc('Branding').set({customInAppBrandingEnabled: customInAppBrandingEnabled, logoFileName: logoFileName, logoLightFileName: logoLightFileName, welcomeDialogHeading: welcomeDialogHeading, welcomeDialogContent: welcomeDialogContent, lastChangeDate: new Date()})
    var subscription = this.getAllCoachesByLicenceHolderUid(coach.licenceHolderUid || coach.uid).subscribe(coaches => {
      coaches.forEach(coach => {
        this.firestore.collection('Coaches/').doc(coach.uid).update({timestamp: new Date()})
      })
      if (subscription != null) subscription.unsubscribe()
    })
  }
  getBrandingImage(licenceHolderUid: string, fileName: string): Observable<string> {
    return this.fireStorage.ref("licence_holders/" + licenceHolderUid + "/settings/branding/" + fileName).getDownloadURL()
  }
  uploadBrandingImage(coach: User, fileName: string, file: File) {
    return this.fireStorage.upload("licence_holders/" + (coach.licenceHolderUid || coach.uid) + "/settings/branding/" + fileName, file)
  }
  async savePaymentSettings(licenceHolderUid: string, paymentSettings: PaymentSettings) {
    try {
      await this.firestore.collection('LicenceHolders/' + licenceHolderUid + '/Settings').doc('Payment').set(paymentSettings.asMap(), {merge: true})
    } catch (error) {
      console.log(error)
    }
  }
  getPaymentSettings(licenceHolderUid: string): Promise<PaymentSettings> {
    return firstValueFrom(this.firestore.collection<PaymentSettings>('LicenceHolders/' + licenceHolderUid + '/Settings').doc('Payment').get().pipe(
      map(document => {
        if (!document.exists || !document.data()) return null
        var settings = new PaymentSettings(document.data() as PaymentSettings)
        return settings
      })
    ))
  }

  uploadFile(path: string, file: File) {
    return this.fireStorage.upload(path, file).then()
  }
  getDownloadUrl(path: string): Promise<string> {
    return firstValueFrom(this.fireStorage.ref(path).getDownloadURL())
  }

  async uploadImage(image: Blob, path: string, fileName: string) {
    const file = new File([image], fileName, {
        type: 'image/png',
        lastModified: Date.now()
    });
    await this.fireStorage.upload(path, file).then()
  }

  private clientsSubscription: Subscription


  getEditableUserInformationAsFirebaseMap(user: User){
    return {
      name: user.name,
      birthDate: user.birthDate,
      age: user.age,
      bodyHeight: user.bodyHeight,
      gender: user.gender,
      physicalActivityLevel: user.physicalActivityLevel,
      timestamp: new Date(),
    }
  }

  async saveEditabledUserInformation(user: User) {
    await this.firestore.collection('Users/').doc(user.uid).update(this.getEditableUserInformationAsFirebaseMap(user));
  }

  async getLicencesByLicenceHolderUid(licenceHolderUid: string): Promise<Licence[]> {
    var snapshot = await this.firestore.collection('Licences').ref.where('licenceHolderUid', '==', licenceHolderUid).get()
    var licences: Licence[] = []
    snapshot.docs.forEach(document => {
      var licence = new Licence(document.data() as Licence)
      if ((document.data() as any).activationDate != null) licence.redeemDate = new Date((document.data() as any).activationDate.seconds * 1000)
      if ((document.data() as any).deactivationDate != null) licence.deactivationDate = new Date((document.data() as any).deactivationDate.seconds * 1000)
      licences.push(licence)
    })
    return licences
  }

  private licenceSubscription: Subscription

  getChangedPropertiesOfObject(objA: object, objB: object) {
    var changedProperties = []
    for (var key in objA) {
      if (objA[key] != objB[key]) changedProperties.push(key)
    }
    return changedProperties
  }

  getChangedPropertiesOfLicence(licA: Licence, licB: Licence){
    var changedProperties = []
    // for (var key in licA) {
    //   if (licA[key] != licB[key]) changedProperties.push(key)
    // }
    if(licA.lid != licB.lid) changedProperties.push('lid')
    if(licA.coachUid != licB.coachUid) changedProperties.push('coachUid')
    if(licA.userUid != licB.userUid) changedProperties.push('userUid')
    if(licA.issued != licB.issued) changedProperties.push('issued')
    if(licA.active != licB.active) changedProperties.push('active')
    if(licA.deleted != licB.deleted) changedProperties.push('deleted')
    if(licA.licenceType != licB.licenceType) changedProperties.push('licenceType')
    if(licA.licenceHolderUid != licB.licenceHolderUid) changedProperties.push('licenceHolderUid')
    if(licA.expirationDate?.getTime() != licB.expirationDate?.getTime()) changedProperties.push('expirationDate')
    if(licA.redeemDate?.getTime() != licB.redeemDate?.getTime()) changedProperties.push('redeemDate')
    if(licA.plannedActivationDate?.getTime() != licB.plannedActivationDate?.getTime()) changedProperties.push('plannedActivationDate')
    if(licA.presetName != licB.presetName) changedProperties.push('presetName')
    if(licA.email != licB.email) changedProperties.push('email')
    if(licA.licenceHolderUid != licB.licenceHolderUid) changedProperties.push('licenceHolderUid')
    if(licA.coachUid != licB.coachUid) changedProperties.push('coachUid')
    licA.onboardingQuestionaireIds.forEach((questionaireId, index) => {
      if(questionaireId != licB.onboardingQuestionaireIds[index]) {
        changedProperties.push('onboardingQuestionaireIds')
        return;
      }
    });
    licA.assignedMetricIds.forEach((metricId, index) => {
      if(metricId != licB.assignedMetricIds[index]) {
        changedProperties.push('assignedMetricIds')
        return;
      }
    });
    return changedProperties
  }

  private clientsLicencesSubscription: Subscription
  private licenceConnectionRequestSubscription: Subscription

  observeClientsAndLicences() {
    if (this.clientsLicencesSubscription) this.clientsLicencesSubscription.unsubscribe()
    this.clientsLicencesSubscription =  combineLatest(
      this.firestore.collection<User>('Users', ref => ref.where('cachedData.licenceHolderUid', '==', this.user.licenceHolderUid || this.user.uid)).stateChanges(),
      this.firestore.collection<Licence>('Licences', ref => ref.where('licenceHolderUid', '==', this.user.licenceHolderUid || this.user.uid)).snapshotChanges()
    ).subscribe(
      async ([userChanges, licenceChanges]) => {
        //console.log('Result of combineLatest: ' + userChanges.length + ' ' + licenceChanges.length)

        if (this.clientsOfLicenceHolder == null) this.clientsOfLicenceHolder = []

        if (this.pendingLicences == null) this.pendingLicences = []
        if (this.allLicences == null) this.allLicences = []
        if (this.expiringLicences == null) this.expiringLicences = []
        if (this.activeLicences == null) this.activeLicences = []

        var newClients: User[] = []
        var removedClients: User[] = []
        for (let change of userChanges) {

          if (change.type === 'added') {
            var document = change.payload.doc.data()
            var client = new User(document as User)
            client.uid = change.payload.doc.id
            var existing = this.clientsOfLicenceHolder?.filter(c => c.uid == client.uid)?.shift()
            if (!existing) {
              client.coachUid = (document as any).cachedData?.coachUid
              client.licenceHolderUid = (document as any).cachedData?.licenceHolderUid
              client.licenceId = (document as any).cachedData?.licenceId
              this.clientsOfLicenceHolder.push(client)
              newClients.push(client)
            }
          }
          if (change.type === 'modified') {
            var document = change.payload.doc.data()
            var client = new User(document as User)
            client.uid = change.payload.doc.id
            client.coachUid = (document as any).cachedData?.coachUid
            client.licenceHolderUid = (document as any).cachedData?.licenceHolderUid
            client.licenceId = (document as any).cachedData?.licenceId
            this.clientsOfLicenceHolder = this.clientsOfLicenceHolder.filter(c => c.uid != client.uid)
            this.clientsOfLicenceHolder.push(client)
            this.clientsOfCoach = this.clientsOfCoach.filter(c => c.uid != client.uid)
            newClients.push(client)
            removedClients.push(client)

            this.allLicences.forEach(licence => {
              if (licence.userUid == client.uid) {
                licence.user = null
              }
            })
          }
          if (change.type === 'removed') {
            var document = change.payload.doc.data()
            var client = new User(document as User)
            this.clientsOfLicenceHolder = this.clientsOfLicenceHolder.filter(c => c.uid != client.uid)
            this.clientsOfCoach = this.clientsOfCoach.filter(c => c.uid != client.uid)

            removedClients.push(client)
            this.allLicences.forEach(licence => {
              if (licence.userUid == client.uid) {
                licence.user = null
              }
            })
          }
        }

        var addedLicences: Licence[] = []

        for (let change of licenceChanges) {
          var licence = new Licence(change.payload.doc.data())
          if (licence.lid) {
            if (licence.expirationDate != null) licence.expirationDate = new Date((change.payload.doc.data() as any).expirationDate.seconds * 1000)
            if (licence.redeemDate != null) licence.redeemDate = new Date((change.payload.doc.data() as any).redeemDate.seconds * 1000)
            if (licence.plannedActivationDate != null) licence.plannedActivationDate = new Date((change.payload.doc.data() as any).plannedActivationDate.seconds * 1000)

            if (change.type === 'added') {
              if (this.allLicences.find(l => l.lid == licence.lid)) {
                continue
              }
            } else if (change.type === 'modified') {
              var lid = licence.lid
              this.allLicences = this.allLicences.filter(l => l.lid != lid)
              this.pendingLicences = this.pendingLicences.filter(l => l.lid != lid)
              this.activeLicences = this.activeLicences.filter(l => l.lid != lid)
              this.expiringLicences = this.expiringLicences.filter(l => l.lid != lid)
              this.clientsOfLicenceHolder.forEach(client => {
                if (client.licence?.lid == lid) {
                  client.licence = null
                }
              })
            }

            if (licence.licenceType == 'Coaching') {
              addedLicences.push(licence)
              if (licence.coachUid == this.getLoggedInUser().uid) {
                if (licence.userUid) {
                  if (licence.active) {
                    this.activeLicences.push(licence)
                    this.allLicences.push(licence)
                  } else if (licence.plannedActivationDate && !licence.deleted) {
                    this.allLicences.push(licence)
                    this.pendingLicences.push(licence)
                    firstValueFrom(this.getUserByUid(licence.userUid)).then(user => {
                      licence.user = user
                    })
                  }
                } else {
                  if (licence.issued && !licence.deleted) {
                    this.allLicences.push(licence)
                    this.pendingLicences.push(licence)
                  }
                }
              } else if (licence.active || (licence.issued && !licence.userUid && !licence.deleted)) {
                this.allLicences.push(licence)
                if (!licence.userUid) this.pendingLicences.push(licence)
                if (licence.active) {
                  this.activeLicences.push(licence)
                }
              }
            }
          }
        }

        for (var licence of this.activeLicences) {
          if (!licence.user) {
            let client = this.clientsOfLicenceHolder.find(c => c.uid == licence.userUid)
            if (client) {
              client.licence = licence
              licence.user = client
              this.clientsOfCoach = this.clientsOfCoach.filter(c => c.uid != client.uid)
              if (licence.coachUid == this.getLoggedInUser().uid) this.clientsOfCoach.push(client)
              if (licence.coachUid != client.coachUid) {
                console.log('Different coachUids for user ' + client.getName())
                //this.updateUserCachedData(client.uid, { cachedData: { coachUid: null, licenceHolderUid: null, licenceId: null } })
              }
            }
          }

          if (!licence.user && licence.userUid && licence.active) {
            var activeLid = await this.getCurrentCoachingLicenceId(licence.userUid)
            if (activeLid == licence.lid) {
              this.updateUserField(licence.userUid, { cachedData: { coachUid: licence.coachUid, licenceHolderUid: this.user.licenceHolderUid, licenceId: licence.lid } })
            } else {
              var activeLicenceHolderUid = await this.getCurrentCoachingLicenceHolderId(licence.userUid)
              if (activeLicenceHolderUid && activeLicenceHolderUid != this.user.licenceHolderUid) {
                console.log('Deactivate licence ' + licence.lid)
                //this.deactivateLicence(licence.lid)
              }
            }
          }
        }

        for (var client of this.clientsOfLicenceHolder) {
          /*var l = this.activeLicences.filter(l => l.userUid == client.uid)
          console.log('Licences for ' + client.getName() + ': ' + l.length)*/
          if (!client.licence) {
            var licence = this.activeLicences.find(l => l.userUid == client.uid)
            if (licence) {
              client.licence = licence
              licence.user = client
              if (licence.coachUid == this.getLoggedInUser().uid) this.clientsOfCoach = this.clientsOfCoach.filter(c => c.uid != client.uid)
              this.clientsOfCoach.push(client)
            }
          }
          if (!client.licence) {
            console.log('Clear cached data for ' + client.uid)
            this.updateUserField(client.uid, { cachedData: { coachUid: null, licenceHolderUid: null, licenceId: null } })
          }
        }

        for (var client of newClients) {
          if (client.licence) {
            if (environment.firebaseProjectId == 'luke-fit') {
              await this.loadMetadata(client)
            } else {
              this.loadMetadata(client)
            }
            if (!this.utilityService.onNativeMobileApp() && (!client.firstBodyWeight || !client.latestBodyWeight)) this.loadUserKPIs(client)
            if (this.user.portalSettingsCoach?.pendingRedemptionLids.includes(client.licenceId)) {
              this.newConnectedClients.push(client)
            }
          }
        }

        this.clientsOfLicenceHolder = this.clientsOfLicenceHolder.sort((a,b) => (a.getName() ?? "").localeCompare(b.getName() ?? ""))
        this.clientsOfCoach = this.clientsOfCoach.sort((a,b) => (a.getName() ?? "").localeCompare(b.getName() ?? ""))

        this.observableClients.next({changed: newClients.concat(removedClients), all: this.clientsOfLicenceHolder})

        this.refreshExpiringLicences()

        if (!this.clientsLoaded) this.clientsLoaded = true
      }
    )

    if (this.licenceConnectionRequestSubscription) this.licenceConnectionRequestSubscription.unsubscribe()
    this.licenceConnectionRequestSubscription = this.getLicenceConnectionRequests(this.user.coach.trainerCode).subscribe(async requests => {
      for (var request of requests) {
        request.user = await firstValueFrom(this.getUserByUid(request.userUid))
      }
      this.licenceConnectionRequests = requests
    })

  }

  getLicenceConnectionRequests(trainerCode: string) {
    return this.firestore.collection<LicenceConnectionRequest>('LicenceConnectionRequests', ref => ref.where('trainerCode', '==', trainerCode)).valueChanges({ idField: 'id' }).pipe();
  }
  async deleteLicenceConnectionRequest(request: LicenceConnectionRequest) {
    await this.firestore.collection('LicenceConnectionRequests').doc(request.id).delete()
  }

  updateUserField(uid: string, data: any) {
    this.firestore.collection('Users').doc(uid).set(data, {merge: true}).catch(error => console.log(error))
  }
  updateUserCoachReferenceUid(user: User) {
    this.firestore.collection('Users').doc(user.uid).set({ coachReferenceUid: user.coachReferenceUid, timestamp: new Date() }, {merge: true}).catch(error => console.log(error))
  }
  async updateUserHideOnOutreachDashboard(user: User) {
    await this.firestore.collection('Users').doc(user.uid).set({hideOnOutreachDashboard: user.hideOnOutreachDashboard}, {merge: true})
  }

  async getCurrentCoachingLicenceId(uid: string): Promise<string> {
    var snapshot = await this.firestore.collection('Users').doc(uid).collection('Licences').doc('Coaching').ref.get()
    if (snapshot != null && snapshot.data() && snapshot.data().lid != null) {
      return snapshot.data().lid
    }
    return null
  }
  async setCurrentLicenceForUser(licence: Licence) {
    await this.firestore.collection('Users').doc(licence.userUid).collection('Licences').doc('Coaching').set({lid: licence.lid, licenceId: licence.lid, licenceHolderUid: licence.licenceHolderUid, coachUid: licence.coachUid, licenceType: licence.licenceType }, {merge: true})
  }

  async getCurrentCoachingLicenceHolderId(uid: string): Promise<string> {
    var snapshot = await this.firestore.collection('Users').doc(uid).collection('Licences').doc('Coaching').ref.get()
    if (snapshot != null && snapshot.data() && snapshot.data().licenceHolderUid != null) {
      return snapshot.data().licenceHolderUid
    }
    return null
  }

  async getLicenceHolderIdOfCoach(coachUid: string): Promise<string> {
    var snapshot = await this.firestore.collection<any>('Coaches').doc(coachUid).ref.get()
    if (snapshot != null && snapshot.data()?.licenceHolderUid != null) {
      return snapshot.data().licenceHolderUid
    }
    return null
  }

  async getLicenceOfCoachAndUser(licenceHolderUid: string, userUid: string) {
    var snapshot = await firstValueFrom(this.firestore.collection<Licence>('Licences', ref => ref.where('licenceHolderUid', '==', licenceHolderUid).where('userUid', '==', userUid)).get())
    if (snapshot.docs.length > 0) {
      var licence = new Licence(snapshot.docs[0].data() as Licence)
      if ((snapshot.docs[0].data() as any).activationDate != null) licence.redeemDate = new Date((snapshot.docs[0].data() as any).activationDate.seconds * 1000)
      if ((snapshot.docs[0].data() as any).deactivationDate != null) licence.deactivationDate = new Date((snapshot.docs[0].data() as any).deactivationDate.seconds * 1000)
      return licence
    }
    return null
  }

  expiringLicencesThreshold: number = 5
  refreshExpiringLicences() {
    var expirationDateReminderLimit = new Date().addDays(this.expiringLicencesThreshold)
    this.expiringLicences = []
    this.activeLicences.forEach(licence => {
        if (licence.productPurchaseId) licence.productPurchase = this.productPurchases?.find(p => p.id == licence.productPurchaseId && p.status == 'active')
        if (!licence.productPurchase) licence.productPurchase = this.productPurchases?.find(p => p.licenceId == licence.lid && p.status == 'active')
        if (licence.productPurchase) {
          if (licence.productPurchase?.endDate && licence.productPurchase.endDate.getTime() < expirationDateReminderLimit.getTime()) {
            licence.expirationDate = licence.productPurchase.endDate
            this.expiringLicences.push(licence)
          }
        } else {
          if (licence.expirationDate != null && licence.expirationDate.getTime() < expirationDateReminderLimit.getTime()) {
            this.expiringLicences.push(licence)
          }
        }

    })
  }

  getRemoteNutritionalGoalForUser(user: User): Observable<NutritionalGoal> {
    return this.firestore.collection<NutritionalGoal>('Users/' + user.uid + "/Shared/" + user.getLid() + "/NutritionalGoals", ref => ref.where("date", "<=", new Date()).where('applicableWeekdays', 'array-contains', 'all').orderBy('date', 'desc').limit(1)).valueChanges({idField: 'goalId'}).pipe(
      map(documents => {
        if (documents.length == 1) {
          var nutritionalGoal = new NutritionalGoal(documents[0] as NutritionalGoal)
          nutritionalGoal.date = new Date((documents[0] as any).date.seconds * 1000)
          return nutritionalGoal
        } else {
          return null
        }
      })
    )
  }
  getRemoteNutritionalGoalsForUser(user: User): Observable<NutritionalGoal[]> {
    return this.firestore.collection<NutritionalGoal>('Users/' + user.uid + "/Shared/" + user.getLid() + "/NutritionalGoals", ref => ref.where("date", "<=", new Date()).orderBy('date', 'desc').limit(7)).valueChanges({idField: 'goalId'}).pipe(
      map(documents => {
        var nutritionalGoals: NutritionalGoal[] = []
        documents.forEach(document => {
          var nutritionalGoal = new NutritionalGoal(document as NutritionalGoal)
          nutritionalGoal.date = new Date((document as any).date.seconds * 1000)
          if (nutritionalGoals.length == 0 || nutritionalGoal.date.getTime() == nutritionalGoals[0].date.getTime()) nutritionalGoals.push(nutritionalGoal)
        })
        return nutritionalGoals
      })
    )
  }
  async loadLicenceSettingsForUser(user: User) {
    if (user.getLid()) {
      var shared = await this.firestore.collection<any>('Users/' + user.uid + "/Shared/").doc(user.getLid()).ref.get()
      user.remoteControlEnabled = shared?.data()?.remoteControlEnabled || false;
      user.hideNutritionValues = shared?.data()?.hideNutritionValues || false;
      user.hideChat = shared?.data()?.hideChat || false;
      user.recipeSuggestionsEnabled = shared?.data()?.recipeSuggestionsEnabled != null ? shared?.data()?.recipeSuggestionsEnabled : null
      user.coacheeTrainingPlanCreationEnabled = shared?.data()?.coacheeTrainingPlanCreationEnabled != null ? shared?.data()?.coacheeTrainingPlanCreationEnabled : null
      user.coacheeVoiceMessageEnabled = shared?.data()?.coacheeVoiceMessageEnabled != null ? shared?.data()?.coacheeVoiceMessageEnabled : null
      user.trainingVariables = shared?.data()?.trainingVariables?.map(x => new TrainingVariable(x)) ?? [];
      user.coacheeNutritionalGoalEditingEnabled = shared?.data()?.coacheeNutritionalGoalEditingEnabled != null ? shared?.data()?.coacheeNutritionalGoalEditingEnabled : null
      user.nutritionEnabled = shared?.data()?.nutritionEnabled != null ? shared?.data()?.nutritionEnabled : null

      user.nextCompetitionDate = shared?.data()?.nextCompetitionDate ? new Date((shared?.data()?.nextCompetitionDate as any).seconds * 1000) : null
      user.defaultExertionParameter = shared?.data()?.defaultExertionParameter ?? null

      let cardioZoneGroups = shared?.data()?.cardioZoneGroups?.map(x => new CardioZoneGroup(x, user.age));
      let globalCardioZones = this.getLoggedInUser().trainingSettingsLicenceHolder.cardioZones;

      if (cardioZoneGroups?.length > 0){
        user.cardioZoneGroups = cardioZoneGroups;
        if(globalCardioZones){
          user.filterCardioZoneGroupZones(globalCardioZones);
        }
      } else {
        let defaultCardioZoneGroup = CardioZoneGroup.getEmptyDefaultZoneGroup(user?.age);
        if(globalCardioZones){
          defaultCardioZoneGroup.zones = globalCardioZones.map(x => x.clone());
          defaultCardioZoneGroup.resetMaxCardioZone(user?.age);
          defaultCardioZoneGroup.recalculateCardioZones(globalCardioZones);
        }
        user.cardioZoneGroups = [defaultCardioZoneGroup];
      }


    } else {
      user.remoteControlEnabled = false;
      user.hideNutritionValues = false;
      user.hideChat = false
      user.recipeSuggestionsEnabled = null
      user.coacheeTrainingPlanCreationEnabled = null
      user.coacheeVoiceMessageEnabled = null
      user.trainingVariables = []
      user.coacheeNutritionalGoalEditingEnabled = null
      user.nextCompetitionDate = null
      user.nutritionEnabled = null
      user.defaultExertionParameter = null

      let globalCardioZones = this.getLoggedInUser().trainingSettingsLicenceHolder.cardioZones;
      let defaultCardioZoneGroup = CardioZoneGroup.getEmptyDefaultZoneGroup(user?.age);
      if(globalCardioZones){
        defaultCardioZoneGroup.zones = globalCardioZones.map(x => x.clone());
        defaultCardioZoneGroup.resetMaxCardioZone(user?.age);
        defaultCardioZoneGroup.recalculateCardioZones(globalCardioZones);
      }
      user.cardioZoneGroups = [defaultCardioZoneGroup];

    }
  }

  async updateTrainingVariables(user: User, trainingVariables: TrainingVariable[]) {
    if (user.getLid()){
      await this.firestore.collection('Users/' + user.uid + "/Shared/").doc(user.getLid()).set({ trainingVariables: trainingVariables.map(x => x.asMap()) }, { merge: true })
      user.trainingVariables = trainingVariables;

      await this.setNotExistingGlobalTrainingVariables(trainingVariables);
    }
  }

  async updateTrainingPlanVariables(user: User, trainingPlan: TrainingPlan, trainingVariables: TrainingVariable[]) {
    await this.firestore.collection('Users/' + user.uid + "/TrainingPlans/").doc(trainingPlan.id).set({ trainingVariables: trainingVariables.map(x => x.asMap()) }, { merge: true })
    trainingPlan.trainingVariables = trainingVariables
    await this.setNotExistingGlobalTrainingVariables(trainingVariables);
  }

  async updateCardioZoneGroups(user: User, cardioZoneGroups: CardioZoneGroup[]) {
    if (user.getLid()){
      await this.firestore.collection('Users/' + user.uid + "/Shared/").doc(user.getLid()).set({ cardioZoneGroups: cardioZoneGroups.map(x => x.asMap()) }, { merge: true })
      user.cardioZoneGroups = cardioZoneGroups;
    }
  }


  updateLicenceSettings(user: User) {
    if (user.getLid()){
      this.firestore.collection('Users/' + user.uid + "/Shared/").doc(user.getLid()).set({ hideNutritionValues: user.hideNutritionValues, hideChat: user.hideChat, coacheeVoiceMessageEnabled: user.coacheeVoiceMessageEnabled, recipeSuggestionsEnabled: user.recipeSuggestionsEnabled, nutritionEnabled: user.nutritionEnabled, coacheeTrainingPlanCreationEnabled: user.coacheeTrainingPlanCreationEnabled, coacheeNutritionalGoalEditingEnabled: user.coacheeNutritionalGoalEditingEnabled, nextCompetitionDate: user.nextCompetitionDate, defaultExertionParameter: user.defaultExertionParameter }, { merge: true })
      this.firestore.collection('Users/').doc(user.uid).update({ timestamp: new Date() })
    }
  }
  async updateLicenceSettingsChat(user: User) {
    if (user.getLid()){
      await this.firestore.collection('Users/' + user.uid + "/Shared/").doc(user.getLid()).set({ hideChat: user.hideChat, coacheeVoiceMessageEnabled: user.coacheeVoiceMessageEnabled }, { merge: true })
      await this.firestore.collection('Users/').doc(user.uid).update({ timestamp: new Date() })
    }
  }

  retrieveNutritionalGoalFromCoachForUser(cust: User) {
    cust.nutritionalGoalFromCoach = undefined
    this.firestore.collection<any>('Users/' + cust.uid + "/Shared/").doc(cust.getLid()).ref.get().then(shared => {
      var enabled = shared?.data()?.remoteControlEnabled || false;
      cust.remoteControlEnabled = enabled
    });
    this.firestore.collection<any>('Users/' + cust.uid + "/Shared/" + cust.getLid() + "/NutritionalGoals").ref.where("date", "<=", new Date()).orderBy('date', 'desc').limit(1).get().then(goals => {
      goals.forEach(goal => {
        cust.nutritionalGoalFromCoach = new NutritionalGoal(goal.data() as NutritionalGoal)
        var date = new Date();
        date.setTime(goal.data().date.seconds * 1000)
        cust.nutritionalGoalFromCoach.date = date
        cust.nutritionalGoalFromCoach.goalId = goal.id;
        cust.nutritionalGoalFromCoach.adjustWithActivityCalories = goal.data().adjustWithActivityCalories
        if (cust.nutritionalGoalFromCoach.adjustWithActivityCalories == null) {
          cust.nutritionalGoalFromCoach.adjustWithActivityCalories = cust.adjustNutritionalGoalWithActivities
        } else if (goal.data().applied) {
          cust.adjustNutritionalGoalWithActivities = goal.data().adjustWithActivityCalories
        }
      });
    });
  }

  loadUserKPIs(user: User) {
    // Fetch latest and first BodyData with body weight.
    this.getLatestBodyDataWithBodyWeight(user).then(bodyData => {
      if (bodyData && bodyData.bodyWeight && bodyData.bodyWeight > 0) {
        user.latestBodyWeight = bodyData.bodyWeight
      }
    })
    this.getFirstBodyDataWithBodyWeight(user).then(bodyData => {
      if (bodyData && bodyData.bodyWeight && bodyData.bodyWeight > 0) {
        user.firstBodyWeight = bodyData.bodyWeight
      }
    })
  }

  async loadMetadata(user: User) {
    var migrateEndDate = !this.getLoggedInUser().coach?.portalSettingsCoach?.migratedPlanEndDateUids?.includes(user.uid)

    var document = await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Metadata/').doc('Portal').get().toPromise()
    if (document && document.data()) {
      var metadataUser = new MetadataUser(document.data() as MetadataUser)
      user.metadataUser = metadataUser
      metadataUser.assignedClientGroups.forEach(tag => {
        if (!this.clientGroups.includes(tag)) this.clientGroups.push(tag)
        this.sortClientGroups()
      })
    } else {
      user.metadataUser = new MetadataUser()
    }

    if (user.metadata && !migrateEndDate) {
      return
    }

    // Query NutritionPlans:
    var maxDismissedEndDate = null
    var maxEndDate = null
    await this.nutritionPlanService.loadNutritionPlanConfigs(user)
    user.planConfigs.forEach(planConfig => {
      if (planConfig.expiringNutritionPlanHintDismissed && planConfig.endDate != null) {
        if (maxDismissedEndDate == null || planConfig.endDate > maxDismissedEndDate) maxDismissedEndDate = planConfig.endDate
      }
    })
    user.planConfigs.forEach(planConfig => {
      if (planConfig.isActive && planConfig.endDate != null && (maxDismissedEndDate == null || maxDismissedEndDate.getTime() < planConfig.endDate.getTime()) && (maxEndDate == null || maxEndDate.getTime() < planConfig.endDate.getTime())) {
        maxEndDate = planConfig.endDate
      }
    })

    let reminderDateRangeStart: Date = new Date()
    reminderDateRangeStart.setHours(0,0,0,0);
    reminderDateRangeStart.setDate(reminderDateRangeStart.getDate() - 30);
    let reminderDateRangeEnd = new Date()
    reminderDateRangeEnd.setHours(0,0,0,0);
    reminderDateRangeEnd.setDate(reminderDateRangeEnd.getDate() + 2);
    if (maxEndDate > reminderDateRangeStart && maxEndDate < reminderDateRangeEnd) {
      user.metadataUser.latestNutritionPlanEndDate = maxEndDate
    }
    if (!user.metadataUser.latestNutritionPlanEndDate) {
      // Query deprecated NutritionPlans:
      await this.nutritionService.retrieveNutritionPlansForUser(user).toPromise().then()
      user.nutritionPlans.forEach(nutritionPlan => {
        if (nutritionPlan.expiringNutritionPlanHintDismissed) {
          if (maxDismissedEndDate == null || nutritionPlan.getEndDate() > maxDismissedEndDate) maxDismissedEndDate = nutritionPlan.getEndDate()
        }
      })
      user.nutritionPlans.forEach(nutritionPlan => {
        if (nutritionPlan.distributed && (maxDismissedEndDate == null || maxDismissedEndDate.getTime() < nutritionPlan.getEndDate().getTime()) && (maxEndDate == null || maxEndDate.getTime() < nutritionPlan.getEndDate().getTime())) {
          maxEndDate = nutritionPlan.getEndDate()
        }
      })
      if (maxEndDate > reminderDateRangeStart && maxEndDate < reminderDateRangeEnd) {
        user.metadataUser.latestNutritionPlanEndDate = maxEndDate
      }
    }

    /*var yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 14)
    if (maxEndDate > yesterday) {
      console.log('End date', user.uid, user.metadata.nutritionPlanEndDate, maxEndDate)
      user.metadata.nutritionPlanEndDate = maxEndDate
      await this.updateMetadata(user)
    }
    return null*/

    if (user.metadata && !user.metadata.nutritionPlanEndDate && user.metadata.nutritionPlanEndDate?.getTime() != maxEndDate?.getTime() && maxEndDate > reminderDateRangeStart) {
      user.metadata.nutritionPlanEndDate = maxEndDate
      await this.updateMetadata(user)

      this.getLoggedInUser().coach.portalSettingsCoach?.migratedPlanEndDateUids.push(user.uid)
      this.firestore.collection('Coaches/' + this.getLoggedInUser().coach.uid + '/Settings').doc('Portal').set({
        migratedPlanEndDateUids: this.getLoggedInUser().coach.portalSettingsCoach?.migratedPlanEndDateUids
      }, { merge: true });

      return
    }

    await this.loadTrainingPlans(user, this.getLoggedInUser())
    var maxTrainingPlanEndDate: Date = undefined
    user.trainingPlans.forEach(plan => {
      if (plan.deleted) return
      if (plan.endDate) {
        if (maxTrainingPlanEndDate === undefined || maxTrainingPlanEndDate && plan.endDate.isSameOrAfterDate(maxTrainingPlanEndDate)) {
          maxTrainingPlanEndDate = plan.endDate
        }
      } else {
        maxTrainingPlanEndDate = null
      }
    })
    var metadata = new Metadata()
    metadata.nutritionPlanEndDate = user.metadataUser.latestNutritionPlanEndDate
    metadata.trainingPlanEndDate = maxTrainingPlanEndDate || null
    user.metadata = metadata
    //console.log('# Update metaData: ' + user.getName() + ' ' + metadata.nutritionPlanEndDate?.asFormatedString() + ' ' + metadata.trainingPlanEndDate?.asFormatedString())
    this.updateMetadata(user)

    this.getLoggedInUser().coach.portalSettingsCoach?.migratedPlanEndDateUids.push(user.uid)
    this.firestore.collection('Coaches/' + this.getLoggedInUser().coach.uid + '/Settings').doc('Portal').set({
      migratedPlanEndDateUids: this.getLoggedInUser().coach.portalSettingsCoach?.migratedPlanEndDateUids
    }, { merge: true });
  }

  async updateMetadata(user: User) {
    await this.firestore.collection('Users/').doc(user.uid).set({ metadata: { trainingPlanEndDate: user.metadata.trainingPlanEndDate, nutritionPlanEndDate: user.metadata.nutritionPlanEndDate } }, {merge: true}).catch(error => {})
  }

  updateCoachingMetaData(user: User, updateUserTimestamp: boolean = true) {
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Metadata/').doc('Portal').set({ userName: user.metadataUser.userName, assignedClientGroups: user.metadataUser.assignedClientGroups, timestamp: new Date() })
    if (updateUserTimestamp) this.firestore.collection('Users/').doc(user.uid).update({ timestamp: new Date() })
  }

  dismissExpiringNutritionPlanHint(user: User) {
    if (user.metadata) {
      user.metadata.nutritionPlanEndDate = null
      this.updateMetadata(user)
      return
    }
    user.planConfigs?.forEach(planConfig => {
      if (planConfig.isActive && planConfig.endDate != null && planConfig.endDate.getTime() == user.metadataUser.latestNutritionPlanEndDate.getTime()) {
        planConfig.expiringNutritionPlanHintDismissed = true
        user.metadataUser.latestNutritionPlanEndDate = null
        this.nutritionPlanService.updateNutritionPlanConfigExpiringHint(planConfig, user)
      }
    })
    user.nutritionPlans.forEach(nutritionPlan => {
      if (nutritionPlan.distributed && nutritionPlan.getEndDate().getTime() == user.metadataUser.latestNutritionPlanEndDate.getTime()) {
        nutritionPlan.expiringNutritionPlanHintDismissed = true
        user.metadataUser.latestNutritionPlanEndDate = null
        this.firestore.collection('Users/' + user.uid + "/Shared/" + user.getLid() + "/NutritionPlans").doc(nutritionPlan.id).set({
          expiringNutritionPlanHintDismissed: true
        }, {merge: true})
      }
    })
  }
  dismissExpiringTrainingPlanHint(user: User) {
    if (user.metadata) {
      user.metadata.trainingPlanEndDate = null
      this.updateMetadata(user)
    }
  }

  private profilePicturePromiseMap = new Map<string, Promise<string>>()
  getProfilePictureForUser(user: User, reload: boolean = false) {
    if (reload) user.profilePictureUrl = undefined
    if (user && user.profilePictureUrl !== null) {
      if (user.profilePictureUrl) return new Promise<string>((resolve, reject) => resolve(user.profilePictureUrl))
      if (this.profilePicturePromiseMap.has(user.uid)) {
        if (!reload) {
          return this.profilePicturePromiseMap.get(user.uid).then((link) => {
            user.profilePictureUrl = link;
            return link
          })
        } else {
          this.profilePicturePromiseMap.delete(user.uid)
        }
      }
      if (user.profileImagePath !== null) {
        var promise: Promise<string> = new Promise((resolve, reject) => {
          firstValueFrom(this.fireStorage.ref(user.profileImagePath ?? "users/" + user.uid + "/profile_picture.jpg").getDownloadURL()).then((link) => {
            user.profilePictureUrl = link;
            if (user.profileImagePath === undefined) {
              this.firestore.collection('Users').doc(user.uid).update({profileImagePath: "users/" + user.uid + "/profile_picture.jpg"})
            }
            resolve(link)
          }).catch(error => {
            //this.firestore.collection('Users').doc(user.uid).update({profileImagePath: null})
          })
        })
      } else {
        user.profilePictureUrl = null
        var promise = new Promise<string>((resolve, reject) => resolve(user.profilePictureUrl))
      }
      this.profilePicturePromiseMap.set(user.uid, promise)
      return promise;
    }
    return null
  }

  public baseNutritionFactCache: Map<string, BaseNutritionFact> = new Map()
  getBaseNutritionFactById(id: string, useCache: boolean = true): Observable<BaseNutritionFact> {
    if (id == null || id == undefined || id.startsWith('cst') || id.startsWith('cnf')) return of(null)
    if (useCache && this.baseNutritionFactCache.has(id)) {
      return of(this.baseNutritionFactCache.get(id))
    }
    if (id.startsWith('ntf') || id.startsWith('brc') || id.startsWith('bnd') || id.startsWith('bls') || id.startsWith('snd')) {
      return this.commonFirebase.firestore.collection<BaseNutritionFact>('BaseNutritionFacts').doc(id).get().pipe( map( document => {
        if (!document.exists || !document.data()) return null
        var nutritionFact = new BaseNutritionFact(document.data() as BaseNutritionFact);
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
            nutritionFact.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
          } else {
            nutritionFact.setNutritionalValue(nutritionalValue, null)
          }
        })
        if (useCache) this.baseNutritionFactCache.set(id, nutritionFact)
        return nutritionFact;
      }))
    } else if (id.startsWith('off') || id.startsWith('crd')) {
      return this.commonFirebase.firestore.collection<BaseNutritionFact>('BrandedNutritionFacts').doc(id.replace('off', '').replace('crd', '')).get().pipe( map( document => {
        if (!document.exists || !document.data()) return null
        var nutritionFact = new BaseNutritionFact(document.data() as BaseNutritionFact);
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
            nutritionFact.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
          } else {
            nutritionFact.setNutritionalValue(nutritionalValue, null)
          }
        })
        if (useCache) this.baseNutritionFactCache.set(id, nutritionFact)
        return nutritionFact;
      }))
    }
  }
  getBaseNutritionFactByBarcode(barcode: string): Observable<BaseNutritionFact> {
    return this.commonFirebase.firestore.collection<BaseNutritionFact>('BaseNutritionFacts', ref => ref.where('barcode', '==', barcode).limit(1)).get().pipe( map( document => {
      if (document.docs?.length > 0) {
        var baseNutritionFact = new BaseNutritionFact(document.docs[0].data() as BaseNutritionFact)
        baseNutritionFact.id = document.docs[0].id
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.docs[0].data()[nutritionalValue] != null && document.docs[0].data()[nutritionalValue] != undefined && document.docs[0].data()[nutritionalValue].toString().length > 0) {
            baseNutritionFact.setNutritionalValue(nutritionalValue, parseFloat(document.docs[0].data()[nutritionalValue]))
          } else {
            baseNutritionFact.setNutritionalValue(nutritionalValue, null)
          }
        })
        return baseNutritionFact
      } else {
        return null;
      }
    }))
  }
  getAdminSettings(): Observable<any> {
    return this.firestore.collection('Coaches/' + this.getLoggedInUser().uid + '/Settings').doc('Admin').valueChanges().pipe()
  }
  updateMaxBaseNutritionFactBndId(id: string) {
    this.firestore.collection('Coaches/' + this.getLoggedInUser().uid + '/Settings').doc('Admin').update({maxBaseNutritionFactBndId: id})
  }
  updateMaxBaseNutritionFactNtfId(id: string) {
    this.firestore.collection('Coaches/' + this.getLoggedInUser().uid + '/Settings').doc('Admin').update({maxBaseNutritionFactNtfId: id})
  }
  updateDatabaseAdminInUse(value: boolean) {
    this.firestore.collection('Coaches/' + this.getLoggedInUser().uid + '/Settings').doc('Admin').update({databaseAdminInUse: value})
  }
  getNumberOfBaseNutritionFactInquiries(): Observable<number> {
    return this.firestore.collection<BaseNutritionFact>('BaseNutritionFactUpdateInquiries').get().pipe( map( snapshot => {
      return snapshot.size
    }))
  }
  loadNewBaseNutritionFactInquiry(): Observable<BaseNutritionFact> {
    return this.firestore.collection<BaseNutritionFact>('BaseNutritionFactUpdateInquiries', ref => ref.orderBy('date', 'desc').limit(1)).get().pipe( map( document => {
      if (document.docs?.length > 0) {
        var inquiry = new BaseNutritionFact(document.docs[0].data() as BaseNutritionFact)
        inquiry.id = document.docs[0].id
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.docs[0].data()[nutritionalValue] != null && document.docs[0].data()[nutritionalValue] != undefined && document.docs[0].data()[nutritionalValue].toString().length > 0) {
            inquiry.setNutritionalValue(nutritionalValue, parseFloat(document.docs[0].data()[nutritionalValue]))
          } else {
            inquiry.setNutritionalValue(nutritionalValue, null)
          }
        })
        return inquiry
      } else {
        return null;
      }
    }))
  }
  loadNewBaseNutritionFactInquiryOFF(): Observable<BaseNutritionFact> {
    return this.firestore.collection<any>('BaseNutritionFactUpdateInquiriesOFF', ref => ref.limit(1)).get().pipe( map( document => {
      if (document.docs?.length > 0) {
        var inquiry = new BaseNutritionFact(document.docs[0].data() as BaseNutritionFact)
        inquiry.id = document.docs[0].id
        inquiry.fromOFF = true
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.docs[0].data()[nutritionalValue] != null && document.docs[0].data()[nutritionalValue] != undefined && document.docs[0].data()[nutritionalValue].toString().length > 0) {
            inquiry.setNutritionalValue(nutritionalValue, parseFloat(document.docs[0].data()[nutritionalValue]))
          } else {
            inquiry.setNutritionalValue(nutritionalValue, null)
          }
        })
        if (document.docs[0].data().packageSize) {
          if (inquiry.servingSizes) {
            inquiry.servingSizes = inquiry.servingSizes + ';' + 'PACKAGE:' + document.docs[0].data().packageSize
          } else {
            inquiry.servingSizes = 'PACKAGE:' + document.docs[0].data().packageSize
          }
          inquiry.servingSizes = inquiry.servingSizes.replace(' ','')
        }
        if (document.docs[0].data().servingSize) {
          if (inquiry.servingSizes) {
            inquiry.servingSizes = inquiry.servingSizes + ';' + 'SERVING:' + document.docs[0].data().servingSize
          } else {
            inquiry.servingSizes = 'SERVING:' + document.docs[0].data().servingSize
          }
          inquiry.servingSizes = inquiry.servingSizes.replace(' ','')
        }
        return inquiry
      } else {
        return null;
      }
    }))
  }
  loadNewBaseNutritionFactWithMistake(): Observable<BaseNutritionFact> {
    return this.firestore.collection<BaseNutritionFact>('BaseNutritionFacts', ref => ref.where('calories', '==', null).limit(1)).get().pipe( map( document => {
      if (document.docs?.length > 0) {
        var inquiry = new BaseNutritionFact(document.docs[0].data() as BaseNutritionFact)
        inquiry.id = document.docs[0].id
        return inquiry
      } else {
        return null;
      }
    }))
  }
  deleteBaseNutritionFactInquiry(inquiry: BaseNutritionFact): Promise<void> {
    return this.firestore.collection<BaseNutritionFact>('BaseNutritionFactUpdateInquiries').doc(inquiry.id).delete()
  }
  deleteBaseNutritionFactInquiryOFF(inquiry: BaseNutritionFact): Promise<void> {
    return this.firestore.collection<BaseNutritionFact>('BaseNutritionFactUpdateInquiriesOFF').doc(inquiry.id).delete()
  }
  updateBaseNutritionFactServingSize(baseNutritionFact: BaseNutritionFact) {
    this.firestore.collection<BaseNutritionFact>('BaseNutritionFacts').doc(baseNutritionFact.id).update({servingSizes: baseNutritionFact.servingSizes})
  }
  saveBaseNutritionFact(baseNutritionFact: BaseNutritionFact): Promise<void> {
    if (!baseNutritionFact.id || baseNutritionFact.id?.length == 0) return null;
    var data = {
      id: baseNutritionFact.id,
      barcode: baseNutritionFact.barcode,
      nameDe: baseNutritionFact.nameDe,
      nameEn: baseNutritionFact.nameEn,
      nameTranslation: baseNutritionFact.nameTranslation.AsMap(),
      synonymsDe: baseNutritionFact.synonymsDe,
      brands: baseNutritionFact.brands,
      servingSizes: baseNutritionFact.servingSizes,
      unit: baseNutritionFact.unit,
      region: baseNutritionFact.region,
      valid: baseNutritionFact.valid,
      detailed: baseNutritionFact.detailed || false,
      fromBLS: baseNutritionFact.fromBLS || false,

      nutriScore: baseNutritionFact.nutriScore,
      nutriScoreGrade: baseNutritionFact.nutriScoreGrade,
      offCategory1: baseNutritionFact.offCategory1,
      offCategory2: baseNutritionFact.offCategory2,
      imageUrl: baseNutritionFact.imageUrl,

      lastChangeDate: new Date()
    }
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (baseNutritionFact.getNutritionalValue(nutritionalValue) != null && baseNutritionFact.getNutritionalValue(nutritionalValue) != undefined) {
        data[nutritionalValue] = baseNutritionFact.getNutritionalValue(nutritionalValue)
      }
    })

    return this.firestore.collection<any>('BaseNutritionFacts').doc(baseNutritionFact.id).set(data)
  }
  deleteBaseNutritionFact(baseNutritionFact: BaseNutritionFact) {
    if (!baseNutritionFact.id || baseNutritionFact.id?.length == 0) return
    this.firestore.collection<BaseNutritionFact>('BaseNutritionFacts').doc(baseNutritionFact.id).delete()
  }
  deleteServingInquiry(servingInquiry: ServingInquiry) {
    this.firestore.collection<ServingInquiry>('CustomServingSizes').doc(servingInquiry.id).delete()
  }

  getChatsByUser(user: User): Observable<Array<Chat>> {
    return this.firestore.collection<Chat>('Chats', ref => ref.where('participantsUids', 'array-contains', user.uid)).valueChanges({ idField: 'chatId' }).pipe();
  }
  getChatsByLicenceHolder(licenceHolderUid: string, coachUid: string): Observable<Array<Chat>> {
    return this.firestore.collection<Chat>('Chats', ref => ref.where('participantsUids', 'array-contains-any', [licenceHolderUid, coachUid])).valueChanges({ idField: 'chatId' }).pipe();
  }
  getAllChats(): Observable<Array<Chat>> {
    return this.firestore.collection<Chat>('Chats').valueChanges({ idField: 'chatId' }).pipe();
  }

  getChatForUser(uid: string): Promise<Array<Chat>> {
    return new Promise((resolve, reject) => {
      var subscription = this.firestore.collection<Chat>('Chats', ref => ref.where('participantsUids', 'array-contains', uid)).valueChanges({ idField: 'chatId' }).pipe().subscribe(chats => {
        subscription.unsubscribe()
        resolve(chats)
      }, error => {
        subscription.unsubscribe()
        resolve([])
      })
    })
  }

  async createChat(participantsUids: string[]): Promise<Chat> {
    var res = await this.firestore.collection('Chats').add({participantsUids: participantsUids})
    var chat = new Chat()
    chat.chatId = res.id
    chat.participantsUids = participantsUids
    return chat
  }


  observeChatMessages(chatid: string, user: User, limit: number): Observable<Chatmessage[]> {
    return this.firestore.collection<Chatmessage>('Chats/' + chatid + "/Messages", ref => ref.orderBy('time', 'desc').limit(limit)).valueChanges({idField: 'messageId'}).pipe(
      map( documents => {
        var messages = []
        documents.forEach(document => {
          var message = new Chatmessage(document as Chatmessage)
          messages.push(message)
        })
        return messages
      })
    );
  }

  getChatMessagesAfter(chatId: string, startAfterDate: Date, limit: number): Promise<Chatmessage[]> {
    if (startAfterDate == null || chatId == null || !startAfterDate) return Promise.resolve([])
    return firstValueFrom(this.firestore.collection<Chatmessage>('Chats/' + chatId + "/Messages", ref => ref.orderBy('time', 'desc').startAfter(startAfterDate).limit(limit)).valueChanges({idField: 'messageId'}).pipe(
      map( documents => {
        var messages = []
        documents.forEach(document => {
          var message = new Chatmessage(document as Chatmessage)
          messages.push(message)
        })
        return messages
      })
    ))
  }


  private messageContentPromiseMap = new Map<string, Promise<string>>()

  loadChatMessageContents(messages: Chatmessage[], user: User) {
    for (let message of messages) {
      if (message.isDeleted) continue
      if (message.attachedFileName && !message.attachmentUrl) {
        if (!this.messageContentPromiseMap.has(message.chatId + '_' + message.messageId)) {
          var promise = new Promise<string>((resolve, reject) => {
            this.fireStorage.ref("chats/" + message.chatId + "/" + message.attachedFileName).getDownloadURL().toPromise().then(async link => {
              if (link) {
                message.attachmentUrl = link
                await this.firestore.collection('Chats/' + message.chatId + "/Messages").doc(message.messageId).update({attachmentUrl: link})
              }
              resolve(link)
            })
          })
          this.messageContentPromiseMap.set(message.chatId + '_' + message.messageId, promise)
        }
      }
      if(message.hasSharedFileReference() && !message.sharedFile) {
        this.getSharedFileById(user, message.sharedFileId).toPromise().then(sharedFile => {
          if (sharedFile?.deleted) {
            return;
          }
          message.sharedFile = sharedFile
          if (sharedFile == null) {
            this.getPublicSharedFileById(message.sharedFileId).toPromise().then(sharedFile => {
              if (sharedFile?.deleted) {
                return;
              }
              message.sharedFile = sharedFile
            });
          }
        }).catch(error => {
          this.getPublicSharedFileById(message.sharedFileId).toPromise().then(sharedFile => {
            if (sharedFile?.deleted) {
              return;
            }
            message.sharedFile = sharedFile
          });
        });
      }
    }
  }

  async getLatestChatMessage(chatid: string): Promise<Chatmessage> {
    return this.firestore.collection<Chatmessage>('Chats/' + chatid + "/Messages", ref => ref.orderBy('time', 'desc').limit(1)).get().pipe(map(documents => {
      if (documents.docs.length == 0) return null
      var document = documents.docs[0]
      var message = new Chatmessage(document.data() as Chatmessage)
      message.time = new Date((message as any).time.seconds * 1000)
      return message
    })).toPromise()
  }

  getUserByUid(uid: string): Observable<User> {
    return combineLatest(
      this.firestore.collection<User>('Users').doc(uid).valueChanges(),
      this.firestore.collection<User>('Coaches').doc(uid).valueChanges()
    ).pipe(
      map(([rawUser, rawCoach]) => {
        var user = new User(rawUser as User);
        var coach = rawCoach as User;
        if (user == undefined) {
          coach.isCoach = true;
          coach.isUser = false;
          return coach;
        } else {
          user.isUser = true;
          if (coach != undefined) {
            user.isCoach = true;
            if (user.name == undefined || user.name.length == 0) user.name = coach.name;
          } else {
            user.isCoach = false;
          }
          return user;
        }
      })
    );
  }

  async getAllUsers(): Promise<User[]> {
    var documents = await firstValueFrom(this.firestore.collection<User>('Users').get())
    let users: User[] = []
    documents.docs.forEach(doc => {
      var user = new User(doc.data() as User)
      user.uid = doc.id
      users.push(user)
    })
    return users
  }

  async getConnectedLicencesByUser(user: User): Promise<any[]> {
    var documents = await firstValueFrom(this.firestore.collection<User>('Users').doc(user.uid).collection('Licences').get())
    var data = []
    documents.docs.forEach(doc => {
      var d = doc.data()
      d.licenceId = d.id
      d.id = doc.id
      d.licenceType = d.id
      data.push(d)
    })
    return data
  }

  async getUserIdsWithAssignedDailyCheckId(weeklyCheckId: string): Promise<string[]> {
    const snapshots = await firstValueFrom(this.firestore.collectionGroup('AssignedQuestionaires', ref => ref.where('dailyQuestionaireId', '==', weeklyCheckId)).get())
    return snapshots.docs.map(doc => doc.ref.parent.parent.id)
  }

  createFirebaseUser(email: string, password: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createUser')
    return callable({ email: email, password: password })
  }
  getFirebaseUserUid(email: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('getUser')
    return callable({ email: email })
  }
  updateLicenceHolder(licenceHolder: LicenceHolder): Promise<void> {
    return this.firestore.collection('LicenceHolders/').doc(licenceHolder.uid).set({uid: licenceHolder.uid, name: licenceHolder.name, onTrial: licenceHolder.onTrial, trialEndDate: licenceHolder.trialEndDate, availableTrialLicences: licenceHolder.availableTrialLicences, referralCounter: licenceHolder.referralCounter, customDataProcessingAgreement: licenceHolder.customDataProcessingAgreement }, {merge: true})
  }
  updateLandingPageSlug(licenceHolderUid: string, customLandingPageSlug: string): Promise<void> {
    return this.firestore.collection('LicenceHolders/').doc(licenceHolderUid).set({customLandingPageSlug: customLandingPageSlug}, {merge: true})
  }
  getLicenceHolderUidBySlug(customLandingPageSlug: string) {
    return this.firestore.collection<LicenceHolder>('LicenceHolders', ref => ref.where('customLandingPageSlug', '==', customLandingPageSlug).limit(1)).valueChanges().pipe(
      map( documents => {
        if (documents.length == 0) return null
        return documents[0].uid
      })
    )
  }
  updateAdminRights(coach: Coach) {
    this.firestore.collection('Coaches/').doc(coach.uid).update({hasLicenceHolderRights: coach.hasLicenceHolderRights})
  }
  updateCoach(coach: Coach): Promise<void> {
    return this.firestore.collection('Coaches/').doc(coach.uid).set({uid: coach.uid, licenceHolderUid: coach.licenceHolderUid, name: coach.name, trainerCode: coach.trainerCode, hasRecipeDatabaseAccess: coach.hasRecipeDatabaseAccess, canAccessAllUsers: coach.canAccessAllUsers, accessibleUserUids: coach.accessibleUserUids, accessibleGroupNames: coach.accessibleGroupNames, accessibleCoachUids: coach.accessibleCoachUids, canAssignCoachesAndLicences: coach._canAssignCoachesAndLicences, trainingEnabled: coach.trainingEnabled, recipeSharingEnabled: coach.recipeSharingEnabled, recipeSuggestionsEnabled: coach.recipeSuggestionsEnabled, hideChatMessageSender: coach.hideChatMessageSender, recipeQRCodeSharingEnabled: coach.recipeQRCodeSharingEnabled, canAccessPayment: coach.canAccessPayment, timestamp: new Date()}, {merge: true})
  }
  updateCoachNotificationSettings(coach: Coach) {
    return this.firestore.collection('Coaches/').doc(coach.uid).set({ emailNotificationSettings: coach.emailNotificationSettings, pushNotificationSettings: coach.pushNotificationSettings }, {merge: true})
  }
  updateCoachCommunicationEmailAddress(coach: Coach) {
    return this.firestore.collection('Coaches/').doc(coach.uid).set({ communicationEmailAddress: coach.communicationEmailAddress }, {merge: true})
  }
  updateVoiceMessagesEnabled(coach: Coach, enabled: boolean) {
    var subscription = this.getAllCoachesByLicenceHolderUid(coach.licenceHolderUid || coach.uid).subscribe(coaches => {
      coaches.forEach(coach => {
        this.firestore.collection('Coaches/').doc(coach.uid).update({coacheeVoiceMessageEnabled: enabled, timestamp: new Date()})
      })
      coach.coacheeVoiceMessageEnabled = enabled
      if (subscription != null) subscription.unsubscribe()
    })
  }
  updateOnTrial(licenceHolder: LicenceHolder) {
    var data = {
      onTrial: licenceHolder.onTrial,
    }
    if (licenceHolder.onTrial) {
      data['trialEndDate'] = null
    }
    this.firestore.collection('LicenceHolders/').doc(licenceHolder.uid).update(data)
  }
  async updateRecipeSharingEnabled(user: User, value: boolean) {
    user.coach.recipeSharingEnabled = value
    await this.firestore.collection('Coaches/').doc(user.uid).update({ recipeSharingEnabled: value, timestamp: new Date() })
    await this.firestore.collection('LicenceHolders/').doc(user.licenceHolderUid).update({ recipeSharingEnabled: value })
  }
  async updateRecipeSuggestionsEnabled(user: User, value: boolean) {
    user.coach.recipeSuggestionsEnabled = value
    await this.firestore.collection('Coaches/').doc(user.uid).update({recipeSuggestionsEnabled: value, timestamp: new Date()})
    await this.firestore.collection('LicenceHolders/').doc(user.licenceHolderUid).update({ recipeSuggestionsEnabled: value })
  }
  updateNutritionEnabled(user: User, value: boolean) {
    user.coach.nutritionEnabled = value
    this.firestore.collection('Coaches/').doc(user.uid).update({nutritionEnabled: value, timestamp: new Date()})
  }
  updateCoacheeTrainingPlanCreationEnabled(user: User, value: boolean) {
    user.coach.coacheeTrainingPlanCreationEnabled = value
    this.firestore.collection('Coaches/').doc(user.uid).update({coacheeTrainingPlanCreationEnabled: value, timestamp: new Date()})
  }
  updateAutoRecipeSharingEnabled(coach: User, value: boolean) {
    this.firestore.collection('LicenceHolders/' + (coach.licenceHolderUid || coach.uid) + '/Settings').doc('Portal').set({autoShareNewRecipesEnabled: value})
  }
  updateUnitSystem(coach: User) {
    this.firestore.collection('Coaches/').doc(coach.uid).update({ unitSystem: coach.coach.unitSystem })
  }
  getAutoRecipeSharingEnabled(coach: User): Promise<boolean> {
    return this.firestore.collection<any>('LicenceHolders/' + (coach.licenceHolderUid || coach.uid) + '/Settings').doc('Portal').get().pipe(map(document => {
      return document.data().autoShareNewRecipesEnabled || false
    })).toPromise()
  }
  updateTrainingPlanTemplateSharingEnabled(user: User, value: boolean) {
    user.coach.trainingPlanTemplateSharingEnabled = value
    this.firestore.collection('Coaches/').doc(user.uid).update({trainingPlanTemplateSharingEnabled: value, timestamp: new Date()})
  }
  getUserByUidForChat(uid: string): Observable<User> {
    if (this.user.isCoach) {
      return this.getUserByUid(uid);
    } else {
      return this.firestore.collection<User>('Coaches').doc(uid).get().pipe(map (rawCoach => {
        return rawCoach.data() as User;
      }));
    }
  }

  async updateTrackedMealForUser(user: User, meal: Meal): Promise<void> {
    let ref = this.firestore.collection('Users/' + user.uid + '/Meals').doc(meal.id)
    await ref.update(this.getMealAsFirebaseMap(meal))
    let foodDocuments = await ref.collection('Foods').get().pipe().toPromise();
    for(let foodDoc of foodDocuments.docs){
      if(!meal.foods.find(food => food.id == foodDoc.id)){
        await ref.collection('Foods').doc(foodDoc.id).delete()
      }
    }
    for(let food of meal.foods){
      if(foodDocuments.docs.find(doc => doc.id == food.id)){
        await ref.collection('Foods').doc(food.id).update(this.getFoodAsFirebaseMap(food));
      }
      else {
        await ref.collection('Foods').add(this.getFoodAsFirebaseMap(food));
      }
    }
  }

  async createTrackedMealForUser(user: User, meal: Meal): Promise<void> {
    let ref = await this.firestore.collection('Users/' + user.uid + '/Meals').add(this.getMealAsFirebaseMap(meal))
    for(let food of meal.foods){
      await ref.collection('Foods').add(this.getFoodAsFirebaseMap(food));
    }
  }
  async deleteTrackedMealForUser(user: User, meal: Meal): Promise<void> {
    await this.firestore.collection('Users/' + user.uid + '/Meals').doc(meal.id).update(
      {
        deleted: true,
        timestamp: new Date()
      });
  }

  getMealAsFirebaseMap(meal: Meal){
    return {
      name: meal.name,
      date: meal.date,
      comment: meal.comment,
      commentAcknowledged: true,
      show: meal.show,
      mealType: meal.mealType,
      imagePath: meal.imagePath,
      calories: meal.calories || null,
      carbohydrates: meal.carbohydrates || null,
      protein: meal.protein || null,
      fat: meal.fat || null,
      sugar: meal.sugar || null,
      fibre: meal.fibre || null,
      saturatedFat: meal.saturatedFat || null,
      salt: meal.salt || null,
      coacheeNote: meal.coacheeNote,
      instructions: meal.instructions,
      plannedMealId: meal.plannedMealId,
      timestamp: new Date()
    }
  }

  getFoodAsFirebaseMap(food: Food){
    let map = {}
    map["name"] = food.name
    map["weight"] = food.weight
    if (food.nutritionFactId) {
      map["nutritionFactId"] = food.nutritionFactId
    }
    map["isDummy"] = food.isDummy
    map["brands"] = food.brands
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (food[nutritionalValue]) {
        map[nutritionalValue] = food[nutritionalValue]
      }
    })
    return map;
  }

  getMealsWithFoodsByDate(user: User, date: Date, loadImage: boolean, loadFoods: boolean = true, timezoneOffset: number = null): Observable<Meal[]> {
    var startOfToday = new Date();
    startOfToday.setTime(date.getTime());
    startOfToday.setHours(0,0,0,0);
    if (timezoneOffset) startOfToday.setTime(startOfToday.getTime() + timezoneOffset);
    var endOfToday = new Date();
    endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);

    var result: Observable<Meal[]> = new Observable<Meal[]>((observer) => {
      var meals = []
      this.firestore.collection<any>('Users/' + user.uid + '/Meals').ref.orderBy('date').where('date', '>=', startOfToday).where('date', '<=', endOfToday).get().then(documents => {
        var promises = []
        for (let document of documents.docs) {
          if (document.data().deleted == true) continue
          var meal = new Meal(document.data() as Meal)
          if (!meal.id) meal.id = document.id
          meal.date = new Date((meal as any).date.seconds * 1000)
          meal.foods = [];
          meal.show = false;
          if (loadFoods) {
            // Fetch Foods for this Meal.
            var promiseFood = this.loadFoodsForMeal(user, meal).toPromise().then()
            promises.push(promiseFood)
          } else {
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
                meal.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
              }
              else {
                meal.setNutritionalValue(nutritionalValue, null)
              }
            })
          }
          if (loadImage) {
            // Fetch image for this Meal.
            var promiseImage = this.loadMealImageLinkForMeal(user, meal).toPromise().then()
            promises.push(promiseImage)
          }
          meals.push(meal)
        }
        if (promises.length > 0) {
          Promise.all(promises).then(result => {
            observer.next(meals)
            observer.complete()
          })
        } else {
          observer.next(meals)
          observer.complete()
        }
      })
    });
    return result;
  }

  getAllMealsForDateRange(user: User, startDate: Date, endDate: Date, loadImage: boolean, loadFoods: boolean = true, timezoneOffset: number = null): Observable<Meal[]> {
    var startOfToday = new Date()
    startOfToday.setTime(startDate.getTime())
    startOfToday.setHours(0,0,0,0)
    if (timezoneOffset) startOfToday.setTime(startOfToday.getTime() + timezoneOffset)
    var endOfToday = new Date()
    endOfToday.setTime(endDate.getTime())
    endOfToday.setHours(23, 59, 59, 999)
    if (timezoneOffset) endOfToday.setTime(endOfToday.getTime() + timezoneOffset)

    var result: Observable<Meal[]> = new Observable<Meal[]>((observer) => {
      var meals = []
      this.firestore.collection<any>('Users/' + user.uid + '/Meals').ref.orderBy('date').where('date', '>=', startOfToday).where('date', '<=', endOfToday).get().then(documents => {
        var promises = []
        for (let document of documents.docs) {
          if (document.data().deleted == true) continue
          var meal = new Meal(document.data() as Meal)
          if (!meal.id) meal.id = document.id
          meal.date = new Date((meal as any).date.seconds * 1000)
          meal.foods = [];
          meal.show = false;
          if (loadFoods) {
            // Fetch Foods for this Meal.
            var promiseFood = this.loadFoodsForMeal(user, meal).toPromise().then()
            promises.push(promiseFood)
          } else {
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
                meal.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
              }
              else {
                meal.setNutritionalValue(nutritionalValue, null)
              }
            })
          }
          if (loadImage) {
            // Fetch image for this Meal.
            var promiseImage = this.loadMealImageLinkForMeal(user, meal).toPromise().then()
            promises.push(promiseImage)
          }
          meals.push(meal)
        }
        if (promises.length > 0) {
          Promise.all(promises).then(result => {
            observer.next(meals)
            observer.complete()
          })
        } else {
          observer.next(meals)
          observer.complete()
        }
      })
    });
    return result;
  }

  async getAwaitableAllActivitiesByDateRange(user: User, startDate: Date, endDate: Date): Promise<Activity[]> {
    let startOfToday = new Date();
    startOfToday.setTime(startDate.getTime());
    startOfToday.setHours(0,0,0,0);
    let endOfToday = new Date();
    endOfToday.setTime(endDate.getTime());
    endOfToday.setHours(23, 59, 59, 999);
    let activities = []
    let dcuments = await this.firestore.collection<any>('Users/' + user.uid + '/Activities').ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday).get()
    for(let document of dcuments.docs){
      if(document.data()?.deleted == true) continue
      let activity = new Activity(document.data() as Activity)
      activity.id = document.id
      activities.push(activity)
    }
    return activities;
  }

  getAllActivitiesByDateRange(user: User, startDate: Date, endDate: Date): Observable<Activity[]> {
    return new Observable((observer) => {
      var startOfToday = new Date();
      startOfToday.setTime(startDate.getTime());
      startOfToday.setHours(0,0,0,0);
      var endOfToday = new Date();
      endOfToday.setTime(endDate.getTime());
      endOfToday.setHours(23, 59, 59, 999)
      this.firestore.collection<any>('Users/' + user.uid + '/Activities').ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday).get().then(documents => {
        var activities = []
        documents.forEach(document => {
          if (document.data()?.deleted == true) return
          var activity = new Activity(document.data() as Activity)
          activity.id = document.id
          activities.push(activity)
        })
        observer.next(activities)
        observer.complete()
      })
    })
  }
  getAllActivities(user: User): Observable<Activity[]> {
    return new Observable((observer) => {
      this.firestore.collection<Activity>('Users/' + user.uid + '/Activities').ref.get().then(documents => {
        var activities = []
        documents.forEach(document => {
          if ((document.data() as any)?.deleted == true) return
          var activity = new Activity(document.data() as Activity)
          activity.id = document.id
          activities.push(activity)
        })
        observer.next(activities)
        observer.complete()
      })
    })
  }

  private activityFacts: ActivityFact[];
  async getAllActivityFacts(){
    if(this.activityFacts?.length > 0) return this.activityFacts;
    let docs = await this.firestore.collection<any>('ActivityFacts').ref.get();
    let facts: ActivityFact[] = []
    for(let doc of docs.docs){
      let fact = new ActivityFact(doc.data() as ActivityFact)
      if(fact.valid){
        fact.id = doc.id
        facts.push(fact)
      }
    }
    this.activityFacts = facts;
    return this.activityFacts;
  }

  getActivityAsFirebaseMap(activity: Activity){
    return {
      date: activity.date,
      activityFactId: activity.activityFactId || null,
      caloriesBurned: activity.caloriesBurned || null,
      duration: activity.duration || 0,
      name: activity.name,
      trainingSession: activity.trainingSession || null,
      timestamp: new Date()
    }
  }

  saveOrUpdateActivity(activity: Activity, user: User){
    if(activity.id){
      return this.firestore.collection('Users/' + user.uid + '/Activities').doc(activity.id).update(this.getActivityAsFirebaseMap(activity))
    }
    else {
      return this.firestore.collection('Users/' + user.uid + '/Activities').add(this.getActivityAsFirebaseMap(activity))
    }
  }
  deleteActivity(activity: Activity, user: User){
    if(activity.id){
      return this.firestore.collection('Users/' + user.uid + '/Activities').doc(activity.id).update(
        {
          deleted: true,
          timestamp: new Date()
        })
    }
  }

  getImagePathFromMealImagePath(user: User, meal: Meal): string {
    if (meal.imagePath) {
      if(meal.imagePath.startsWith('meal_templates')){
        return meal.imagePath;
      }
      else{
        return "users/" + user.uid + "/" + meal.imagePath;
      }
    } else {
      return "users/" + user.uid + "/meal_images/" + meal.id + ".jpg";
    }
  }

  loadMealImageLinkForMeal(user: User, meal: Meal): Observable<boolean> {
    return new Observable((observer) => {
      // Fetch image for Meal.
      var path = this.getImagePathFromMealImagePath(user, meal);

      this.fireStorage.ref(path).getDownloadURL().toPromise().then(link => {
        meal.imageLink = link
        observer.next(true)
        observer.complete()
      }, error => {
        var link
        switch (meal.mealType) {
          case "breakfast":
            link = environment.MEAL_DUMMY_IMAGE_BREAKFAST_URL
            break;
          case "lunch":
            link = environment.MEAL_DUMMY_IMAGE_LUNCH_URL
            break;
          case "dinner":
            link = environment.MEAL_DUMMY_IMAGE_DINNER_URL
            break;
          case "snack":
            link = environment.MEAL_DUMMY_IMAGE_SNACK_URL
            break;
          case null:
          case undefined:
          case "":
          default:
              link = environment.MEAL_DUMMY_IMAGE_UNDEFINED_URL
              break;
        }
        meal.imageLink = link
        observer.next(true)
        observer.complete()
      });
    })
  }

  loadFoodsForMeal(user: User, meal: Meal): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('Users/' + user.uid + '/Meals/' + meal.id + "/Foods").ref.get().then(async documents => {
        var foods = []
        for (let document of documents.docs) {
          var food = new Food(document.data() as Food)
          food.id = document.id
          BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
              food.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
            }
            else {
              food.setNutritionalValue(nutritionalValue, null)
            }
          })
          foods.push(food)
        }
        meal.foods = foods
        observer.next(true)
        observer.complete()
      })
    })
  }

  getMealsByDate(user: User, date: Date): Observable<Meal[]> {
    var startOfToday = new Date();
    startOfToday.setTime(date.getTime());
    startOfToday.setHours(0,0,0,0);
    var endOfToday = new Date();
    endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
    return this.firestore.collection<Meal>('Users/' + user.uid + '/Meals', ref => ref.orderBy('date').where('date', '>=', startOfToday).where('date', '<=', endOfToday)).valueChanges().pipe(
      map(documents => {
        var meals = []
        documents.forEach(document => {
          var meal = new Meal(document as Meal)
          meal.date = new Date((meal as any).date.seconds * 1000)
          meal.foods = [];
          meal.show = false;
          meals.push(meal)
        });
        return meals;
      })
    );
  }

  getActivitiesByDate(user: User, date: Date): Observable<Activity[]> {
    return new Observable((observer) => {
      var startOfToday = new Date();
      startOfToday.setTime(date.getTime());
      startOfToday.setHours(0,0,0,0);
      var endOfToday = new Date();
      endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
      this.firestore.collection<any>('Users/' + user.uid + '/Activities').ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday).get().then(documents => {
        var activities = []
        for (let document of documents.docs) {
          if (document.data().deleted == true) continue
          var activity = new Activity(document.data() as Activity)
          activity.id = document.id
          activities.push(activity)
        }
        observer.next(activities)
        observer.complete()
      })
    })
  }

  getWaterIntakesByDate(user: User, date: Date): Observable<WaterIntake[]> {
    var startOfToday = new Date();
    startOfToday.setTime(date.getTime());
    startOfToday.setHours(0,0,0,0);
    var endOfToday = new Date();
    endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
    return this.firestore.collection<WaterIntake>('Users/' + user.uid + '/WaterIntake', ref => ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday)).valueChanges();
  }

  static getMetricDataAsFirebaseMap(metricData: MetricData[]){
    let map = new Map<any, any>();
    for(let metricD of metricData){
      if(!map.has(metricD.metricId)){
        map.set(metricD.metricId, metricD.value);
      }
    }
    return Object.fromEntries(map);
  }

  getMetricDataDailyConditionAsFirebaseMap(dailyCondition: DailyCondition){
    return {
      ["date"]: dailyCondition.date,
      ["metricData"]: FirestoreService.getMetricDataAsFirebaseMap(dailyCondition.metricData),
      ["waterIntake"]: dailyCondition.waterIntake,
      ["timestamp"]: new Date(),
    }
  }

  async saveOrUpdateMetricDataChanges(user: User, dailyCondition: DailyCondition) {
    var startOfToday = new Date();
    startOfToday.setTime(dailyCondition.date.getTime());
    startOfToday.setHours(0,0,0,0);
    var endOfToday = new Date();
    endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);

    let dbRef = await this.firestore.collection<any>('Users/' + user.uid + '/DailyConditions').ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday).limit(1).get()
    let metricDataDailyCondition = this.getMetricDataDailyConditionAsFirebaseMap(dailyCondition);
    var existingId = null
    if (dbRef.docs.length > 0){
      let dailyConditionRef = dbRef.docs[0].ref;
      existingId = dbRef.docs[0].id
      await dailyConditionRef.update(metricDataDailyCondition);
      // await this.firestore.collection('Users/' + user.uid + '/DailyConditions').doc(dailyCondition.id).set(metricDataDailyCondition, {merge: true})
    } else {
      var res = await this.firestore.collection('Users/' + user.uid + '/DailyConditions').add(metricDataDailyCondition)
      dailyCondition.id = res.id
    }

    if (user.dailyConditions.find(x => x.id == existingId)) {
      user.dailyConditions[user.dailyConditions.findIndex(x => x.id == existingId)] = dailyCondition
    } else {
      user.dailyConditions.push(dailyCondition)
      user.dailyConditions.sort((a, b) => b.date.getTime() - a.date.getTime())
    }

    this.updateBodyWeightMeta(user, dailyCondition)
  }

  async updateBodyWeightMeta(user: User, changedDailyCondition: DailyCondition) {
    var metricId = environment.isWhitelabel ? 'NUT_bodyWeight' : 'bodyWeight'
    if (changedDailyCondition.getMetricDatasByMetricIdWithQuestionaireResults(metricId)) {
      var bodyWeights: MetricData[] = []
      user.dailyConditions.forEach(dc => {
        var newBodyWeights = dc.getMetricDatasByMetricIdWithQuestionaireResults(metricId).filter(x => x.value != null)
        newBodyWeights.forEach(bw => {
          bw.date = dc.date
        })
        bodyWeights = bodyWeights.concat(newBodyWeights)
      })
      bodyWeights.sort((a, b) => b.date.getTime() - a.date.getTime())
      if (bodyWeights?.length > 0) await this.firestore.collection('Users/').doc(user.uid).update({firstBodyWeight: bodyWeights[bodyWeights.length - 1]?.value ?? null, latestBodyWeight: bodyWeights[0]?.value ?? null})
    }
  }

  async getLatestDailyCondition(user: User): Promise<DailyCondition> {
    let snapshot = await this.firestore.collection<any>('Users/' + user.uid + '/DailyConditions').ref.orderBy('date', 'desc').limit(1).get();
    if(snapshot.docs.length > 0){
      let dailyCondition = new DailyCondition(snapshot.docs[0].data() as DailyCondition);
      dailyCondition.id = snapshot.docs[0].id;
      dailyCondition.date = new Date((dailyCondition as any).date.seconds * 1000);
      dailyCondition.metricData = [];
      for(let metricId in dailyCondition.metricData){
        let metricData = new MetricData();
        metricData.metricId = metricId;
        metricData.value = dailyCondition.metricData[metricId];
        dailyCondition.metricData.push(metricData);
      }
      return dailyCondition;
    }
    return null;
  }

  getDailyConditionByDate(user: User, date: Date): Observable<DailyCondition> {
    return new Observable((observer) => {
      var startOfToday = new Date();
      startOfToday.setTime(date.getTime());
      startOfToday.setHours(0,0,0,0);
      var endOfToday = new Date();
      endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);

      this.firestore.collection<any>('Users/' + user.uid + '/DailyConditions').ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday).limit(3).get().then(async document => {
        if (document.docs?.length > 0) {
          for (var doc of document.docs) {
            if (doc.data().deleted == true) continue

            var dailyCondition = new DailyCondition(doc.data() as DailyCondition)
            dailyCondition.id = doc.id
            dailyCondition.date = new Date(doc.data().date.seconds * 1000)
            if (doc.data().waterintake > 0) dailyCondition.waterIntake = doc.data().waterintake
            dailyCondition.questionaireResults = ((dailyCondition as any).questionaireResults as any[])?.map(x => new QuestionaireResult(x)) || [];

            for (let index = 0; index < dailyCondition.questionaireResults.length; index++) {
              const questionaireResult = dailyCondition.questionaireResults[index];
              let questionaireMetricDataObject = questionaireResult.metricData as any
              let questionaireMetricData = await this.convertJsonObjectsToMetricData(questionaireMetricDataObject, dailyCondition?.date, user.uid);

              try {
                questionaireResult.metricData = []
                questionaireResult.assignedQuestionaire = await this.getAssigendQuestioniareForUserById(user, questionaireResult.questionaireId);
                if(questionaireResult.assignedQuestionaire?.questions?.length > 0){
                  for (let question of dailyCondition.questionaireResults[index].assignedQuestionaire?.questions) {
                    let metricData = questionaireMetricData.find(x => x.metricId == question.metricId)
                    if (metricData) {
                      metricData.position = question.position
                      questionaireResult.metricData.push(metricData)
                    }
                    else {
                      if(!question.isInfoSlide){
                        let metricData = new MetricData()
                        metricData.metricId = question.metricId
                        metricData.position = question.position
                        metricData.date = dailyCondition.date
                        if(question.metricId){
                          metricData.metric = this.getMetricByMetricId(question.metricId)
                          if (!metricData.metric) metricData.metric = await this.fetchMetricByMetricId(metricData.metricId)
                        }
                        if(metricData.metric){
                          questionaireResult.metricData.push(metricData);
                        }
                      }
                    }
                  }
                }
              }
              catch(ex){
                console.error(ex);
              }
              dailyCondition.questionaireResults[index].metricData?.sort((a, b) => a.position - b.position);
            }

            var jsonObject = (dailyCondition as any).metricData
            dailyCondition.metricData = await this.convertJsonObjectsToMetricData(jsonObject, dailyCondition?.date, user.uid);
            dailyCondition.metricData.forEach(x => {
              let assignedMetric = user.assignedMetrics?.find(y => y.metricId == x.metricId);
              if(assignedMetric?.displayOrder !== null && assignedMetric?.displayOrder !== undefined){
                x.position = assignedMetric?.displayOrder || 0;
              }
              else {
                x.position = Infinity;
              }
            });
            dailyCondition.metricData.sort((a, b) => a.position - b.position);
            await this.setCompletedAssignedQuestionairesOfDailyCondition(dailyCondition, user);
            this.addPendingQuestionairesToDailyConditions([dailyCondition], user.assignedQuestionaires);
            dailyCondition.questionaireResults.sort((a, b) => b.assignedQuestionaire.assignmentDate?.getTime() - a.assignedQuestionaire.assignmentDate?.getTime()).sort((a, b) => b.assignedQuestionaire.completionDate?.getTime() - a.assignedQuestionaire.completionDate?.getTime());

            observer.next(dailyCondition)
            observer.complete()
            return

          }
        } else {
          let emptyDailyCondition = new DailyCondition();
          emptyDailyCondition.date = startOfToday;
          emptyDailyCondition.questionaireResults = [];
          this.addPendingQuestionairesToDailyConditions([emptyDailyCondition], user.assignedQuestionaires);
          observer.next(emptyDailyCondition)
          observer.complete()
        }
      })
    })
  }

  dailyConditionObservables: Map<string, Observable<DailyCondition[]>> = new Map<string, Observable<DailyCondition[]>>();

  getDailyConditionsForUser(user: User, startDate: Date = null, endDate: Date = null): Observable<DailyCondition[]> {
    if (startDate == null && this.dailyConditionObservables.has(user.uid)) {
      return this.dailyConditionObservables.get(user.uid);
    }
    var observable: Observable<DailyCondition[]> = new Observable((observer) => {
      var query = this.firestore.collection<any>('Users/' + user.uid + '/DailyConditions').snapshotChanges()
      if (startDate && endDate) query = this.firestore.collection<any>('Users/' + user.uid + '/DailyConditions', ref => ref.where('date', '>=', startDate).where('date', '<=', endDate)).snapshotChanges()
      query.pipe( map( async documents => {
        await this.loadAllAssignedQuestionairesForUser(user);
        var dailyConditions: DailyCondition[] = []
        for (var i = 0; i < documents.length; i++) {
          var document = documents[i]
          if ((document.payload.doc.data() as any).deleted == true) continue

          var dailyCondition = new DailyCondition(document.payload.doc.data() as DailyCondition)
          dailyCondition.id = document.payload.doc.id
          dailyCondition.date = new Date(document.payload.doc.data().date.seconds * 1000)
          dailyCondition.questionaireResults = ((dailyCondition as any).questionaireResults as any[])?.map(x => new QuestionaireResult(x)) || [];
          for (let index = 0; index < (dailyCondition.questionaireResults?.length ?? 0); index++) {
            const questionaireResult = dailyCondition.questionaireResults[index];
            questionaireResult.assignedQuestionaire = this.getAssignedQuestionaire(user, questionaireResult.questionaireId, dailyCondition.date);
            let questionaireMetricDataObject = questionaireResult.metricData as any
            let questionaireMetricData = await this.convertJsonObjectsToMetricData(questionaireMetricDataObject, dailyCondition?.date, user.uid);
            questionaireResult.metricData = []
            if (questionaireResult.assignedQuestionaire?.questions?.length > 0) {
                for (let question of dailyCondition.questionaireResults[index].assignedQuestionaire?.questions) {
                  let metricData = questionaireMetricData.find(x => x.metricId == question.metricId)
                  if (metricData) {
                    metricData.position = question.position
                    questionaireResult.metricData.push(metricData)
                  } else {
                    if(!question.isInfoSlide){
                      let metricData = new MetricData()
                      metricData.metricId = question.metricId
                      metricData.position = question.position
                      metricData.date = dailyCondition.date
                      if(question.metricId){
                        metricData.metric = this.getMetricByMetricId(question.metricId)
                        if (!metricData.metric) metricData.metric = await this.fetchMetricByMetricId(metricData.metricId)
                      }
                      if(metricData.metric){
                        questionaireResult.metricData.push(metricData);
                      }
                    }
                  }
                }
              }
            dailyCondition.questionaireResults[index].metricData.forEach(x => {
              x.position = dailyCondition.questionaireResults[index].assignedQuestionaire?.questions?.find(y => y.metricId == x.metricId)?.position || 0;
            });
            dailyCondition.questionaireResults[index].metricData.sort((a, b) => a.position - b.position);

            if(user.questionaireResults.find(x => x.questionaireId == questionaireResult.questionaireId) == null){
              user.questionaireResults.push(questionaireResult);
            }
          }
          if ((document.payload.doc.data() as any).waterintake > 0) dailyCondition.waterIntake = (document.payload.doc.data() as any).waterintake
          var jsonObject = (dailyCondition as any).metricData
          dailyCondition.metricData = await this.convertJsonObjectsToMetricData(jsonObject, dailyCondition?.date, user.uid);
          dailyCondition.metricData.forEach(x =>
            {
              x.position = user.assignedMetrics?.find(y => y.metricId == x.metricId)?.displayOrder || 0;
            });
          dailyCondition.metricData.sort((a, b) => a.position - b.position);
          dailyConditions.push(dailyCondition)
        }
        let pendingAssignedQuestionaires = user.assignedQuestionaires.filter(x => !x.completed);
        this.addPendingQuestionairesToDailyConditions(dailyConditions, pendingAssignedQuestionaires);
        dailyConditions.forEach(dailyCondition => {
          dailyCondition.questionaireResults.sort((a, b) => b.assignedQuestionaire?.assignmentDate?.getTime() - a.assignedQuestionaire?.assignmentDate?.getTime()).sort((a, b) => b.assignedQuestionaire?.completionDate?.getTime() - a.assignedQuestionaire?.completionDate?.getTime());
        });
        observer.next(dailyConditions)
      })).toPromise().then()
    })
    if (startDate == null) this.dailyConditionObservables.set(user.uid, observable)
    return observable
  }

  //order metricData by AssignedQuestionaire.Questions


  addPendingQuestionairesToDailyConditions(dailyConditions: DailyCondition[], assignedQuestionaires: AssignedQuestionaire[]){
    this.addEmptyDailyConditions(dailyConditions, assignedQuestionaires);

    dailyConditions = dailyConditions?.sort((a, b) => b.date?.getTime() - a.date?.getTime())?.filter((v,i,a)=>a.findIndex(t=>(t.date?.isSameDate(v.date)))===i);;

    for (let dcIndex = 0; dcIndex < dailyConditions.length; dcIndex++) {
      const dailyCondition = dailyConditions[dcIndex];
      this.addPendingQuestionairesToDailyCondition(dailyCondition, assignedQuestionaires);
    }
  }

  addEmptyDailyConditions(dailyConditions: DailyCondition[], assignedQuestionaires: AssignedQuestionaire[]){
    try {
      for (let index = 0; index < assignedQuestionaires.length; index++) {
        const assignedQuestionaire = assignedQuestionaires[index];
        let compareDate = assignedQuestionaire.completionDate || assignedQuestionaire.assignmentDate;
        if(compareDate == null) continue;
        if(dailyConditions.find(x => x.date?.isSameDate(compareDate)) == null){
          let dailyCondition = new DailyCondition();
          dailyCondition.date = compareDate;
          dailyConditions.push(dailyCondition);
        }
      }
    }
    catch(ex){
      console.log(ex);
    }
  }

  addPendingQuestionairesToDailyCondition(dailyCondition: DailyCondition, assignedQuestionaires: AssignedQuestionaire[]){
    let questionairesToRemove = dailyCondition.questionaireResults.filter(x => !x.assignedQuestionaire?.completed);
    questionairesToRemove.forEach(questionaireResult => {
      let deleteIndex = dailyCondition.questionaireResults.indexOf(questionaireResult);
      dailyCondition.questionaireResults.splice(deleteIndex, 1);
    });
    for (let index = 0; index < assignedQuestionaires.length; index++) {
      const assignedQuestionaire = assignedQuestionaires[index];
      let compareDate = assignedQuestionaire.completionDate || assignedQuestionaire.assignmentDate;
      if((compareDate == null || !compareDate.isSameDate(dailyCondition.date))) continue;
      if(dailyCondition.questionaireResults.find(x => x.questionaireId == assignedQuestionaire.id) == null){
        let questionaireResult = new QuestionaireResult();
        questionaireResult.assignedQuestionaire = assignedQuestionaire;
        questionaireResult.questionaireId = assignedQuestionaire.id;
        dailyCondition.questionaireResults.push(questionaireResult)
      }
    }
  }

  async updateQuestionaireResults(dailyCondition: DailyCondition, user: User){
    let timestamp = new Date();
    if (dailyCondition?.id) {
      await this.firestore.doc('Users/' + user.uid + '/DailyConditions/' + dailyCondition.id).update({
        questionaireResults: dailyCondition.questionaireResults.map(x => x.asMap()),
        timestamp: timestamp,
      });
    } else {
      var startOfToday = new Date();
      startOfToday.setTime(timestamp.getTime());
      startOfToday.setHours(0,0,0,0);
      var res = await this.firestore.collection('Users/' + user.uid + '/DailyConditions').add({
        questionaireResults: dailyCondition.questionaireResults.map(x => x.asMap()),
        timestamp: timestamp,
        date: startOfToday
      });
      dailyCondition.id = res.id
    }
    for(let questionaireResults of dailyCondition.questionaireResults){
      let assignedQuestionaire = questionaireResults.assignedQuestionaire;
      if(!assignedQuestionaire.completed && questionaireResults.metricData?.length > 0){
        await this.firestore.doc('Users/' + user.uid + '/AssignedQuestionaires/' + assignedQuestionaire.id).update({
          timestamp: timestamp,
          completed: true,
          completionDate: assignedQuestionaire.timestamp
        });
      }
    }
    if (user.dailyCondition?.id == dailyCondition.id){
      user.dailyCondition.questionaireResults = dailyCondition.questionaireResults;
    }
    if (user.dailyConditions.find(x => x.id == dailyCondition.id)) {
      user.dailyConditions[user.dailyConditions.findIndex(x => x.id == dailyCondition.id)].questionaireResults = dailyCondition.questionaireResults;
    } else {
      user.dailyConditions.push(dailyCondition)
      user.dailyConditions.sort((a, b) => b.date.getTime() - a.date.getTime())
    }
    this.updateBodyWeightMeta(user, dailyCondition)
  }

  getAssignedQuestionaire(user: User, questionaireId: string, date: Date):AssignedQuestionaire{
    if(date == null) return null;
    let assignedQuestainaires = user.assignedQuestionaires.filter(x => x.id == questionaireId);
    if(assignedQuestainaires?.length > 0) return assignedQuestainaires[0];
    else return null;
  }

  async loadAllAssignedQuestionairesForUser(user: User){
    var assignedQuestionaires = [];
    let documents = await this.firestore.collection<any>('Users/' + user.uid + '/AssignedQuestionaires/').ref.get();

    for (const document of documents.docs) {
      if (document.id == 'AssignedQuestionaireSettings') {
        var data = document.data()
        user.weeklyQuestionaireId = data.weeklyQuestionaireId || null
        user.dailyQuestionaireId = data.dailyQuestionaireId || null
        user.nextWeeklyQuestionaireDate = data.nextWeeklyQuestionaireDate ? new Date(data.nextWeeklyQuestionaireDate.seconds * 1000) : null
        user.trainingFeedbackQuestionaireId = data.trainingFeedbackQuestionaireId || null
      } else {
        const assignedQuestionaire = new AssignedQuestionaire(document.data() as AssignedQuestionaire);
        if (assignedQuestionaire.deleted) continue;
        assignedQuestionaire.id = document.id;

        if (document.data().completionDate) {
          assignedQuestionaire.completionDate = new Date(document.data().completionDate.seconds * 1000);
        }

        if (document.data().assignmentDate) {
          assignedQuestionaire.assignmentDate = new Date(document.data().assignmentDate.seconds * 1000);
        }

        assignedQuestionaires.push(assignedQuestionaire);
      }
    }

    assignedQuestionaires.sort((a, b) => a.assignmentDate?.getTime() - b.assignmentDate?.getTime())

    user.assignedQuestionaires = assignedQuestionaires;
  }

  async saveAssignedQuestionaireSettings(user: User) {
    await this.firestore.collection('Users/' + user.uid + '/AssignedQuestionaires').doc('AssignedQuestionaireSettings').set({ weeklyQuestionaireId: user.weeklyQuestionaireId, dailyQuestionaireId: user.dailyQuestionaireId, nextWeeklyQuestionaireDate: user.nextWeeklyQuestionaireDate, trainingFeedbackQuestionaireId: user.trainingFeedbackQuestionaireId }, { merge: true })
  }

  async getAssigendQuestioniareForUserById(user: User, questionaireId: string): Promise<AssignedQuestionaire> {
    try {
      let document = await this.firestore.collection<any>('Users/' + user.uid + '/AssignedQuestionaires/').doc(questionaireId).ref.get();
      let assignedQuestionaire = new AssignedQuestionaire(document.data() as AssignedQuestionaire);

      assignedQuestionaire.id = document.id;
      if (document.data().completionDate) {
        assignedQuestionaire.completionDate = new Date(document.data().completionDate.seconds * 1000);
      }

      if (document.data().assignmentDate) {
        assignedQuestionaire.assignmentDate = new Date(document.data().assignmentDate.seconds * 1000);
      }

      return assignedQuestionaire
    }
    catch(ex){
      console.error(ex);
      return null;
    }
  }

  async setCompletedAssignedQuestionairesOfDailyCondition(dailyCondition: DailyCondition, user: User){
    try {
      var startOfToday = new Date();
      startOfToday.setTime(dailyCondition.date.getTime());
      startOfToday.setHours(0,0,0,0);
      var endOfToday = new Date();
      endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
      let documents = await this.firestore.collection<any>('Users/' + user.uid + '/AssignedQuestionaires/').ref.where('completed', '==', true).where('completionDate', '>=', startOfToday).where('completionDate', '<=', endOfToday).get();
      documents.docs.forEach(document => {
        var assignedQuestionaire = new AssignedQuestionaire(document.data() as AssignedQuestionaire)
        assignedQuestionaire.id = document.id
        if (document.data().completionDate) assignedQuestionaire.completionDate = new Date(document.data().completionDate.seconds * 1000)
        if (document.data().assignmentDate) assignedQuestionaire.assignmentDate = new Date(document.data().assignmentDate.seconds * 1000)

        let existingResult = dailyCondition.questionaireResults.find(x => x.questionaireId == document.id);
        if(existingResult != null) {
          existingResult.assignedQuestionaire = assignedQuestionaire;
        }
        else {
          let newResult = new QuestionaireResult();
          newResult.assignedQuestionaire = assignedQuestionaire;
          newResult.questionaireId = document.id;
          dailyCondition.questionaireResults.push(newResult);
        }
      });
    }
    catch(err){
      console.error(err)
    }
  }

  async deleteAssignedQuestionaire(user: User, assignedQuestionaire: AssignedQuestionaire){
    try {
      let ref = await this.firestore
      .collection<any>('Users/' + user.uid + '/AssignedQuestionaires')
      .doc(assignedQuestionaire.id);
      let getRef = await ref.ref.get();
      let completed = getRef.data()?.completed;

      if(completed == true){
        return;
      }
      await ref.update({deleted: true, timestamp: new Date()})
      await this.loadAllAssignedQuestionairesForUser(user);
      this.addPendingQuestionairesToDailyConditions(user.dailyConditions, user.assignedQuestionaires);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  getLatestNutritionalGoalByDate(user: User, date: Date): Observable<NutritionalGoal> {
    return new Observable((observer) => {
      var startOfToday = new Date();
      startOfToday.setTime(date.getTime());
      startOfToday.setHours(0,0,0,0);
      var endOfToday = new Date();
      endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
      var weekday = this.weekdaysShort[startOfToday.getDayNumber()];
      combineLatest(
        this.firestore.collection<NutritionalGoal>('Users/' + user.uid + '/NutritionalGoals', ref => ref.where('date', '>=', startOfToday).where('date', '<=', endOfToday).where('isOverwrite', '==', true).orderBy('date', 'desc').limit(1)).valueChanges(),
        this.firestore.collection<NutritionalGoal>('Users/' + user.uid + '/NutritionalGoals', ref => ref.where('date', '<=', endOfToday).orderBy('date', 'desc').where('applicableWeekdays', 'array-contains-any', ['all', '', weekday]).limit(1)).valueChanges()
      ).pipe(
        map(([overwrittenNutritionalGoal, plannedNutritionalGoal]) => {
          if (overwrittenNutritionalGoal.length == 0 && plannedNutritionalGoal.length == 0) {
            observer.next(new NutritionalGoal({calories: user.basicCaloriesRequirement, carbohydrates: user.basicCarbohydratesRequirement, protein: user.basicProteinRequirement, fat: user.basicFatRequirement, adjustWithActivityCalories: user.adjustNutritionalGoalWithActivities} as NutritionalGoal))
            observer.complete()
            return
          }
          var nutritionalGoal = overwrittenNutritionalGoal.length == 1 ? overwrittenNutritionalGoal[0] as NutritionalGoal : plannedNutritionalGoal[0] as NutritionalGoal;
          if (nutritionalGoal != undefined) {
            nutritionalGoal.date = new Date((nutritionalGoal as any).date.seconds * 1000)
          }
          observer.next(nutritionalGoal)
          observer.complete()
        })
      ).toPromise().then()
    })
  }

  convertJsonObjectsToMetricData(jsonObject: any, dailyConditionDate: Date, userUid: string): MetricData[]{
    let metricData: MetricData[] = []
    Object.keys(jsonObject).forEach(async (key) => {
      var data = new MetricData()
      data.metricId = key
      data.id = key
      data.value = jsonObject[key]
      data.date = dailyConditionDate
      data.metric = this.getMetricByMetricId(data.metricId)
      if (!data.metric) data.metric = await this.fetchMetricByMetricId(data.metricId)
      /*if (data.metric?.isMetricTypeImage() || data.metric?.isMetricTypeVideo()) {
        this.fireStorage.ref("users/" + userUid + "/metric_data/" + data.getPrintableValue()).getDownloadURL().toPromise().then(link => {
          data.mediaLink = link;
        });
      }*/
      metricData.push(data)
    });
    return metricData;
  }

  async getMetricDataMediaLink(metricData: MetricData, userUid: string): Promise<string> {
    try{
      if (metricData.getPrintableValue() == null) return null
      return await firstValueFrom(this.fireStorage.ref("users/" + userUid + "/metric_data/" + metricData.getPrintableValue()).getDownloadURL())
    }
    catch(ex){
      console.error(ex)
      return null
    }
  }

  getNutritionalGoalIncludingActivitiesByDate(user: User, date: Date): Observable<NutritionalGoal> {
    return combineLatest(
      this.getLatestNutritionalGoalByDate(user, date),
      this.getActivitiesByDate(user, date)
    ).pipe(
      map(([nutritionalGoal, activities]) => {
        if (nutritionalGoal) {
          var dailyCondition = user.getDailyConditionForDate(date)
          var overwriteGoal = user.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, dailyCondition?.selectedSituationId ?? null)
          if (overwriteGoal) {
            nutritionalGoal.calories = overwriteGoal.getCalories()
            nutritionalGoal.carbohydrates = overwriteGoal.getCarbohydrates()
            nutritionalGoal.protein = overwriteGoal.getProtein()
            nutritionalGoal.fat = overwriteGoal.getFat()
            nutritionalGoal.adjustWithActivityCalories = overwriteGoal.adjustWithActivityCalories
          }
        }
        if ((nutritionalGoal.adjustWithActivityCalories != null && nutritionalGoal.adjustWithActivityCalories == true) || (nutritionalGoal.adjustWithActivityCalories == null && user.adjustNutritionalGoalWithActivities)) {
          var caloriesBurned = 0
          activities.forEach(activity => {
              caloriesBurned = caloriesBurned + activity.caloriesBurned
          })
          nutritionalGoal.carbohydrates += nutritionalGoal.carbohydrates / nutritionalGoal.calories * caloriesBurned
          nutritionalGoal.protein += nutritionalGoal.protein / nutritionalGoal.calories * caloriesBurned
          nutritionalGoal.fat += nutritionalGoal.fat / nutritionalGoal.calories * caloriesBurned
          nutritionalGoal.calories += caloriesBurned
          nutritionalGoal.carbohydrates = Math.floor(nutritionalGoal.carbohydrates)
          nutritionalGoal.protein = Math.floor(nutritionalGoal.protein)
          nutritionalGoal.fat = Math.floor(nutritionalGoal.fat)
          nutritionalGoal.calories = Math.floor(nutritionalGoal.calories)
        }
        return nutritionalGoal;
      })
    );
  }

  getBodyDataForDateRange(user: User, date: Date, daysAgo: number): Observable<BodyData[]> {
    var startOfToday = new Date();
    startOfToday.setTime(date.getTime());
    startOfToday.setHours(0,0,0,0);
    var endOfToday = new Date();
    endOfToday.setTime(startOfToday.getTime() + (1000*60*60*24) - 1);
    var today = new Date();
    var dateXdaysAgo = new Date();
    dateXdaysAgo.setDate(today.getDate()-daysAgo);
    dateXdaysAgo.setHours(0,0,0,0);
    if (daysAgo == 1) {
      return this.firestore.collection<BodyData>('Users/' + user.uid + '/BodyData', ref => ref.where('date', '<=', today)).valueChanges();
    } else {
      return this.firestore.collection<BodyData>('Users/' + user.uid + '/BodyData', ref => ref.where('date', '<=', today).where('date', '>=', dateXdaysAgo)).valueChanges();
    }
  }

  async getLatestBodyDataWithBodyWeight(user: User): Promise<BodyData> {
    var bodyDatas = await this.firestore.collection<BodyData>('Users/' + user.uid + '/BodyData', ref => ref.orderBy('date', 'desc').limit(30)).get().toPromise()
    var bodyData: BodyData = null
    bodyDatas.docs.forEach(doc => {
      var b = new BodyData(doc.data() as BodyData)
      if (b.bodyWeight != null && b.bodyWeight > 0 && (bodyData == null || b.date > bodyData.date)) bodyData = b
    })
    return bodyData
  }
  async getFirstBodyDataWithBodyWeight(user: User): Promise<BodyData> {
    var bodyDatas = await this.firestore.collection<BodyData>('Users/' + user.uid + '/BodyData', ref => ref.orderBy('date', 'asc').limit(10)).get().toPromise()
    var bodyData: BodyData = null
    bodyDatas.docs.forEach(doc => {
      var b = new BodyData(doc.data() as BodyData)
      if (b.bodyWeight != null && b.bodyWeight > 0 && (bodyData == null || b.date < bodyData.date)) bodyData = b
    })
    return bodyData
  }
  getAllBodyDataForUser(user: User): Observable<BodyData[]> {
    return this.firestore.collection<BodyData>('Users/' + user.uid + '/BodyData').valueChanges().pipe();
  }

  updateMealComment(user: User, meal: Meal) {
    if (meal.comment?.length > 0) {
      this.firestore.collection('Users/' + user.uid + '/Meals').doc( '' + meal.id).update({comment: meal.comment, commentAcknowledged: false, timestamp: new Date() }).then(() => {
        this.toastr.success("Feedback wurde gesendet!", "" ,  {
          positionClass: 'toast-bottom-center'
        });
        this.createUserNotificationEntry(user, 'Mahlzeiten-Feedback erhalten', 'Dein Coach hat dir Feedback zu deiner Mahlzeit gesendet.', 'newMealFeedback', meal.id?.toString(), null)
        if (user.fcmToken && user.fcmToken?.length > 0) {
          const callable = user.isAndroidUser() ? this.firebaseFunctions.httpsCallable('sendNotificationHttps') : this.firebaseFunctions.httpsCallable('sendNotificationiOSHttps');
          callable({ type: 'MEAL_FEEDBACK', mealId: meal.id.toString(), date: meal.date.getTime().toString(), title: 'Du hast neues Feedback!', body: '', fcmToken: user.fcmToken }).pipe()
          .subscribe(resp => {}, err => {});
        }
      }).catch((e) => {
        this.toastr.error("Feedback konnte leider nicht gesendet werden.");
      });
    } else {
      this.firestore.collection('Users/' + user.uid + '/Meals').doc( '' + meal.id).update({comment: null, commentAcknowledged: false, timestamp: new Date() }).then(() => {}).catch((e) => {
        this.toastr.error("Feedback konnte leider nicht gesendet werden.");
      });
    }
  }

  clientGroups: string[] = ['Alle']
  observableClientGroups: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(this.clientGroups)

  getClientGroups(): string[] {
    return this.clientGroups
  }
  sortClientGroups() {
    // console.log("sortClientGroups")
    var sorted = this.clientGroups.sort((a, b) => a.localeCompare(b))
    this.clientGroups = sorted
    // this.observableClients.next(this.clientsOfCoach)
    this.observableClientGroups.next(this.clientGroups);
  }

  addClientToGroup(user: User, group: string) {
    if (!user.metadataUser.assignedClientGroups.includes(group)) user.metadataUser.assignedClientGroups.push(group)
    this.updateCoachingMetaData(user)
  }
  removeClientFromGroup(user: User, group: string) {
    if (user.metadataUser.assignedClientGroups.includes(group)) {
      user.metadataUser.assignedClientGroups.splice(user.metadataUser.assignedClientGroups.indexOf(group), 1)
      this.updateCoachingMetaData(user)
    }
  }

  updateLicenceIssued(licence: Licence) {
    this.firestore.collection('Licences').doc( '' + licence.lid).update({issued: licence.issued}).then(() => {});
  }

  async allocateLicence(onboardingQuestionaireIds: string[], name: string, email: string): Promise<Licence> {
    var date =  Date()
    var licence = new Licence()
    licence.licenceHolderUid = this.user.licenceHolderUid
    licence.coachUid = this.user.uid
    licence.licenceType = 'Coaching'
    licence.issued = true
    licence.onboardingQuestionaireIds = onboardingQuestionaireIds
    licence.presetName = name
    licence.email = email
    var res = await this.firestore.collection('Licences').add({licenceHolderUid: licence.licenceHolderUid, coachUid: licence.coachUid, licenceType: licence.licenceType, validityPeriod: -1, allocationDate: date, issued: licence.issued, presetName: licence.presetName, email: licence.email, onboardingQuestionaireIds: licence.onboardingQuestionaireIds, assignedMetricIds: licence.assignedMetricIds})
    licence.lid = res.id
    await this.firestore.collection('Licences').doc(res.id).update({lid: res.id})
    this.addPendingRedemptionLid(this.user, licence.lid)
    return licence
  }

  async allocateActiveLicence(uid: string): Promise<Licence> {
    var date =  Date()
    var licence = new Licence()
    licence.licenceHolderUid = this.user.licenceHolderUid
    licence.coachUid = this.user.uid
    licence.licenceType = 'Coaching'
    licence.issued = true
    licence.userUid = uid
    var res = await this.firestore.collection('Licences').add({ userUid: uid, licenceHolderUid: licence.licenceHolderUid, coachUid: licence.coachUid, licenceType: licence.licenceType, validityPeriod: -1, allocationDate: date, issued: licence.issued, presetName: licence.presetName, email: licence.email, onboardingQuestionaireIds: licence.onboardingQuestionaireIds, assignedMetricIds: licence.assignedMetricIds, active: true, redeemDate: new Date() })
    licence.lid = res.id
    await this.firestore.collection('Licences').doc(res.id).update({lid: res.id})
    return licence
  }

  updateLicencePresetName(licence: Licence) {
    this.firestore.collection('Licences').doc(licence.lid).update({presetName: licence.presetName})
  }
  updateLicence(licence: Licence) {
    return this.firestore.collection('Licences').doc(licence.lid).update({licenceHolderUid: licence.licenceHolderUid, coachUid: licence.coachUid, licenceType: licence.licenceType, issued: licence.issued, presetName: licence.presetName, email: licence.email, onboardingQuestionaireIds: licence.onboardingQuestionaireIds, assignedMetricIds: licence.assignedMetricIds, assignedClientGroups: licence.assignedClientGroups, hideChat: licence.hideChat, hideNutritionValues: licence.hideNutritionValues, productPurchaseId: licence.productPurchaseId})
  }
  updateLicenceExpirationDate(licence: Licence) {
    this.firestore.collection('Licences').doc(licence.lid).update({expirationDate: licence.expirationDate})
  }
  updateLicenceIsTrialLicence(licence: Licence) {
    this.firestore.collection('Licences').doc(licence.lid).update({isTrialLicence: licence.isTrialLicence})
  }
  async reactivateLicence(licence: Licence, coachUid: string) {
    const callable = this.firebaseFunctions.httpsCallable('reactivateLicence')
    return firstValueFrom(callable({ licenceId: licence.lid, coachUid: coachUid }))
  }

  async updateAssignedCoach(user: User, coachUid: string) {
    var lid = user.licence.lid
    if (!lid || !coachUid) return
    await this.firestore.collection('Licences').doc(lid).update({coachUid: coachUid})
    var activeLicence = await this.firestore.collection('Users').doc(user.uid).collection('Licences').doc('Coaching').get().toPromise()
    if (activeLicence.exists && activeLicence.data()['lid'] == lid) {
      await this.firestore.collection('Users').doc(user.uid).collection('Licences').doc('Coaching').update({ coachUid: coachUid, licenceHolderUid: user.licenceHolderUid, licenceId: lid })
      await this.firestore.collection('Users').doc(user.uid).set({ cachedData: { coachUid: coachUid, licenceHolderUid: user.licenceHolderUid, licenceId: lid }, timestamp: new Date() }, {merge: true}).catch(error => console.log(error))
    }
  }

  deactivateLicence(lid: string) {
    var client: User = null
    this.clientsOfCoach.forEach( (c, index) => {
      if (c.getLid() === lid) {
        client = c
      }
    })
    this.firestore.collection('Licences').doc(lid).update({active: false, deactivationDate: new Date()}).then(res => {
      if (client) this.updateUserField(client.uid, { cachedData: { coachUid: null, licenceHolderUid: null, licenceId: null } })
    })
  }

  async deleteLicence(licence: Licence) {
    if (licence.productPurchaseId) {
      await this.firestore.collection('Licences').doc(licence.lid).set({deleted: true}, {merge: true})
    } else {
      await this.firestore.collection('Licences').doc(licence.lid).delete()
    }
    this.pendingLicences.forEach( (item, index) => {
      if (item.lid === licence.lid) this.pendingLicences.splice(index, 1);
    });
    this.removePendingRedemptionLid(this.user, licence.lid)
  }

  updateDashboardItems(coach: User) {
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').set({
      dashboardUids: coach.portalSettingsCoach.dashboardUids, dashboardClientGroups: coach.portalSettingsCoach.dashboardClientGroups, clientGroups: coach.portalSettingsCoach.clientGroups
    }, { merge: true });
  }
  addPendingRedemptionLid(coach: User, lid: string) {
    coach.portalSettingsCoach.pendingRedemptionLids.push(lid)
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').set({
      pendingRedemptionLids: coach.portalSettingsCoach.pendingRedemptionLids
    }, { merge: true });
  }
  removePendingRedemptionLid(coach: User, lid: string) {
    coach.portalSettingsCoach.pendingRedemptionLids.forEach( (item, index) => {
      if (item === lid) coach.portalSettingsCoach.pendingRedemptionLids.splice(index, 1);
    });
    this.newConnectedClients.forEach( (item, index) => {
      if (item.getLid() === lid) this.newConnectedClients.splice(index, 1);
    });
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').set({
      pendingRedemptionLids: coach.portalSettingsCoach.pendingRedemptionLids
    }, { merge: true });
  }
  addMigratedUserUid(coach: User, uid: string) {
    coach.portalSettingsCoach.migratedAppUserUids.push(uid)
    this.firestore.collection('Coaches/' + coach.uid + '/Settings').doc('Portal').set({
      migratedAppUserUids: coach.portalSettingsCoach.migratedAppUserUids
    }, { merge: true });
  }

  deleteUserFromDashboard(uidToDelete: string) {
    var newUids = [];
    this.user.portalSettingsCoach.dashboardUids.forEach(u => {
      if (u != uidToDelete) {
        newUids.push(u);
      }
    });
    this.user.portalSettingsCoach.dashboardUids = newUids;
    this.firestore.collection('Coaches/' + this.user.uid + '/Settings').doc('Portal').set({
      dashboardUids: newUids,
    }, { merge: true });
  }

  insertNewNutritionalGoalsFromCoach(user: User, nutritionalGoals: NutritionalGoal[]) {
    this.firestore.collection<NutritionalGoal>('Users/' + user.uid + "/Shared/" + user.getLid() + "/NutritionalGoals").ref.where("applied", "==", false).get().then(documents => {
      documents.forEach(document => {
        document.ref.delete()
      });
      var date: Date
      nutritionalGoals.forEach(nutritionalGoal => {
        this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionalGoals').add({
          applicableWeekdays: nutritionalGoal.applicableWeekdays,
          applied: false,
          name: nutritionalGoal.name || null,
          calories: nutritionalGoal.calories,
          carbohydrates: nutritionalGoal.carbohydrates,
          date: nutritionalGoal.date,
          fat: nutritionalGoal.fat,
          protein: nutritionalGoal.protein,
          adjustWithActivityCalories: nutritionalGoal.adjustWithActivityCalories == null ? false : nutritionalGoal.adjustWithActivityCalories
        });
        if (nutritionalGoal.date > new Date() && (!date || nutritionalGoal.date < date)) date = nutritionalGoal.date
      })
      this.sendPushNotification('NEW_ASSIGNMENTS', 'Du hast neue Nährwertziele erhalten!', 'Tippe, um sie anzuwenden.', user)
      this.createUserNotificationEntry(user, 'Neues Nährwertziel erhalten', 'Dein Coach hat dein Nährwertziel' + (date != null ? ' ab dem ' + date.asFormatedString() : '') + ' aktualisiert.', 'newNutritionalGoal', null, null)
    });
  }

  createGoalForUser(cust: User, nutritionalGoal: NutritionalGoal) {
    var today = new Date();
    today.setHours(0,0,0,0);
    if (cust.nutritionalGoalFromCoach != undefined && today.getTime() == cust.nutritionalGoalFromCoach.date.getTime()) {
      this.firestore.collection('Users/' + cust.uid + "/Shared/" + cust.getLid() + "/NutritionalGoals").doc(cust.nutritionalGoalFromCoach.goalId).update({
        applicableWeekdays: nutritionalGoal.applicableWeekdays,
        applied: false,
        calories: nutritionalGoal.calories,
        carbohydrates: nutritionalGoal.carbohydrates,
        date: today,
        fat: nutritionalGoal.fat,
        protein: nutritionalGoal.protein,
        adjustWithActivityCalories: nutritionalGoal.adjustWithActivityCalories == null ? false : nutritionalGoal.adjustWithActivityCalories
      });
    } else {
      this.firestore.collection('Users/' + cust.uid + '/Shared/' + cust.getLid() + '/NutritionalGoals').add({
        applicableWeekdays: nutritionalGoal.applicableWeekdays,
        applied: false,
        calories: nutritionalGoal.calories,
        carbohydrates: nutritionalGoal.carbohydrates,
        date: today,
        fat: nutritionalGoal.fat,
        protein: nutritionalGoal.protein,
        adjustWithActivityCalories: nutritionalGoal.adjustWithActivityCalories == null ? false : nutritionalGoal.adjustWithActivityCalories
      });
    }
    this.retrieveNutritionalGoalFromCoachForUser(cust);
  }

  async sendChatMessage(message: Chatmessage, sender: User, receiver: User) {
    message.messageFromCoach = true
    if (message.attachment) {
      await this.fireStorage.upload('chats/' + message.chatId + "/" + message.attachedFileName, message.attachment)
      await this.firestore.collection('Chats/' + message.chatId + '/Messages').add({message: message.message, time: new Date(), uid: sender.uid, read: false, attachedFileName: message.attachedFileName, messageFromCoach: message.messageFromCoach})
    } else {
      await this.firestore.collection('Chats/' + message.chatId + '/Messages').add({message: message.message, time: new Date(), uid: sender.uid, read: false, attachedFileName: message.attachedFileName, messageFromCoach: message.messageFromCoach})
    }
  }

  async deleteMessage(message: Chatmessage) {
    await this.firestore.collection('Chats/' + message.chatId + '/Messages').doc(message.messageId).update({
      isDeleted: true
    })
    message.isDeleted = true
  }

  getNewServingInquiry(): Observable<ServingInquiry> {
    return this.firestore.collection<ServingInquiry>('CustomServingSizes', ref => ref.limit(1)).get().pipe( map( document => {
      if (document.docs?.length > 0) {
        var servingInquiry = new ServingInquiry(document.docs[0].data() as ServingInquiry)
        servingInquiry.id = document.docs[0].id
        return servingInquiry
      } else {
        return null;
      }
    }))
  }

  markMessagesAsRead(chat: Chat) {
    this.firestore.collection<Chatmessage>('Chats/' + chat.chatId + '/Messages').ref.where('read', '==', false).get().then(messages => {
      messages.forEach(m => {
        var mes = m.data() as Chatmessage;
        if (!mes.read && mes.uid != this.user.uid) {
          this.firestore.collection('Chats/' + chat.chatId + '/Messages').doc(m.id).update({
            read: true
          });
        }
      });
    });
  }

  markLastMessageAsUnread(chat: Chat) {
    // this.firestore.collection('Chats/' + chat.chatId + '/Messages').doc(chat?.messages[0]?.messageId).update({
    //   read: false
    // });
    this.firestore.collection<Chatmessage>('Chats/' + chat.chatId + '/Messages').ref.where('read', '==', true).where('uid', '==', chat.chatPartner.uid).orderBy('time', 'desc').limit(1).get().then(messages => {
      messages.forEach(m => {
        var mes = m.data() as Chatmessage;
        if (mes.read && mes.uid != this.user.uid) {
          this.firestore.collection('Chats/' + chat.chatId + '/Messages').doc(m.id).update({
            read: false
          });
        }
      });
    });
  }

  markMessageAsUnread(chatId: string, messageId: string){
    this.firestore.collection('Chats/' + chatId + '/Messages').doc(messageId).update({
      read: false
    });
  }

  uploadProfileImage(pic: File) {
    var path = 'users/' + this.user.uid + '/profile_picture.jpg'
    this.fireStorage.upload(path, pic).then(async () => {
      await this.firestore.collection('Users').doc(this.user.uid).update({ profileImagePath: path })
      this.getProfilePictureForUser(this.getLoggedInUser(), true)
    })
  }

  getActiveLicencesByType(type: string): Promise<any[]> {
    return firstValueFrom(this.firestore.collection('Licences/', ref => ref.where('licenceType', '==', type).where('active', '==', true)).get().pipe(
      map(documents => {
        var licences = []
        documents.forEach(document => {
          var licence = document.data()
          licences.push(licence)
        });
        return licences
      })
    ))
  }
  getAllCoachingLicenceMonitoringItems(): Observable<LicenceMonitoringItem[]> {
    return this.firestore.collection<LicenceMonitoringItem>('Monitoring/Licences/Coaching/').valueChanges({ idField: 'id' }).pipe(
      map(documents => {
        var items = []
        documents.forEach(document => {
          var item = new LicenceMonitoringItem(document as LicenceMonitoringItem)
          item.date = new Date((item as any).date.seconds * 1000)
          var map1 = new Map<string, number>()
          var jsonObject = (item as any).activeLicencesPerCoach
          Object.keys(jsonObject).forEach((key) => {
            map1.set(key, jsonObject[key])
          })
          item.activeLicencesPerCoach = map1

          var map2 = new Map<string, number>()
          var jsonObject = (item as any).trialLicencesPerCoach
          Object.keys(jsonObject).forEach((key) => {
            map2.set(key, jsonObject[key])
          })
          item.trialLicencesPerCoach = map2
          items.push(item)
        });
        return items;
      })
    )
  }
  getAllLicenceMonitoringItemsByType(type: string): Observable<LicenceMonitoringItem[]> {
    return this.firestore.collection<LicenceMonitoringItem>('Monitoring/Licences/' + type + '/').valueChanges({ idField: 'id' }).pipe(
      map(documents => {
        var items = []
        documents.forEach(document => {
          var item = new LicenceMonitoringItem(document as LicenceMonitoringItem)
          item.date = new Date((item as any).date.seconds * 1000)
          var map1 = new Map<string, number>()
          var jsonObject = (document as any).activeLicencesPerProductType
          if (jsonObject) {
            Object.keys(jsonObject)?.forEach((key) => {
              map1.set(key, jsonObject[key])
            })
            item.activeLicencesPerCoach = map1
          }

          var map2 = new Map<string, number>()
          var jsonObject = (document as any).trialLicencesPerProductType
          if (jsonObject) {
            Object.keys(jsonObject)?.forEach((key) => {
              map2.set(key, jsonObject[key])
            })
            item.trialLicencesPerCoach = map2
          }
          items.push(item)
        });
        return items;
      })
    )
  }
  insertCoachingLicenceMonitoringItem(item: LicenceMonitoringItem) {
    let jsonMap1 = {}
    item.activeLicencesPerCoach.forEach((value, key) => {
        jsonMap1[key] = value
    })
    let jsonMap2 = {}
    item.trialLicencesPerCoach.forEach((value, key) => {
        jsonMap2[key] = value
    })
    let jsonMap3 = {}
    item.nutritionActivationsPerCoach.forEach((value, key) => {
        jsonMap3[key] = value
    })
    if (!item.id) {
      this.firestore.collection('Monitoring/Licences/Coaching/').add({date: item.date, activeLicencesPerCoach: jsonMap1, trialLicencesPerCoach: jsonMap2, nutritionActivationsPerCoach: jsonMap3 })
    } else {
      this.firestore.collection('Monitoring/Licences/Coaching/').doc(item.id).set({date: item.date, activeLicencesPerCoach: jsonMap1, trialLicencesPerCoach: jsonMap2})
    }
  }
  insertLicenceMonitoringItem(item: LicenceMonitoringItem, type: string) {
    let jsonMap1 = {}
    item.activeLicencesPerCoach?.forEach((value, key) => {
        jsonMap1[key] = value
    })
    let jsonMap2 = {}
    item.trialLicencesPerCoach?.forEach((value, key) => {
        jsonMap2[key] = value
    })
    if (!item.id) {
      this.firestore.collection('Monitoring/Licences/' + type + '/').add({date: item.date, activeLicencesPerProductType: jsonMap1, trialLicencesPerProductType: jsonMap2})
    } else {
      this.firestore.collection('Monitoring/Licences/' + type + '/').doc(item.id).set({date: item.date, activeLicencesPerProductType: jsonMap1, trialLicencesPerProductType: jsonMap2})
    }
  }
  getAllLicenceHolders(): Observable<LicenceHolder[]> {
    return this.firestore.collection<LicenceHolder>('LicenceHolders/').valueChanges().pipe(
      map(documents => {
        var coaches = []
        documents.forEach(document => {
          var c = new LicenceHolder(document as LicenceHolder)
          coaches.push(c)
        });
        return coaches
      })
    )
  }
  getAllCoachesByLicenceHolderUid(licenceHolderUid: string): Observable<Coach[]> {
    return this.firestore.collection<Coach>('Coaches', ref => ref.where('licenceHolderUid', '==', licenceHolderUid)).valueChanges().pipe(
      map(documents => {
        var coaches = []
        documents.forEach(document => {
          if (document.disabled) return
          var c = new Coach(document as Coach)
          coaches.push(c)
        });
        return coaches
      })
    )
  }
  getLicenceHolderByUid(uid: string): Observable<LicenceHolder> {
    return this.firestore.collection<any>('LicenceHolders/').doc(uid).valueChanges().pipe(
      map(document => {
        var licenceHolder = new LicenceHolder(document as LicenceHolder)
        return licenceHolder
      })
    )
  }
  hasLicenceHolderWithUid(uid: string): Promise<boolean> {
    return firstValueFrom(this.firestore.collection<any>('LicenceHolders/').doc(uid).get().pipe(
      map(document => {
        if (!document.exists || !document.data()) return false
        return true
      })
    ))
  }

  getSharedFiles(user: User): Observable<SharedFile[]> {
    let personalPath = this.getPersonalRootPath(user);
    return this.firestore.collection<SharedFile>('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var files = []
        documents.forEach(document => {
          var file = new SharedFile(document as SharedFile)
          file.timestamp = new Date((file as any).timestamp.seconds * 1000)
          if (!file.deleted)
          {
            if(file.thumbnailLink == null){
              if(file.isImage()){
                this.fireStorage.ref(personalPath + '/' + file.getFullPath()).getDownloadURL().toPromise().then(link => {
                  file.thumbnailLink = link;
                  file.url = link;
                }).catch(ex => console.error(ex));
              }
              else if(file.thumbnailPath){
                this.fireStorage.ref(personalPath + '/' + file.getFullThumbnailPath()).getDownloadURL().toPromise().then(link => {
                  file.thumbnailLink = link;
                }).catch(ex => console.error(ex));
              }
            }
            files.push(file)
          }
        });
        return files
      })
    )
  }

  getSharedFileById(user: User, id: string): Observable<SharedFile> {
    return this.firestore.collection<SharedFile>('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').doc(id).get().pipe(
      map(document => {
        if(!document?.data()) return null;
        var file = new SharedFile(document.data() as SharedFile)
        file.timestamp = new Date((file as any).timestamp.seconds * 1000)
        file.id = document.id
        if (!file.deleted) {
          if(file.thumbnailLink == null){
            if(file.isImage()){
              this.fireStorage.ref(this.getPersonalRootPath(user) + '/' + file.getFullPath()).getDownloadURL().toPromise().then(link => {
                file.thumbnailLink = link;
                file.url = link;
              }).catch(ex => console.error(ex));
            }
            else if(file.thumbnailPath){
              this.fireStorage.ref(this.getPersonalRootPath(user) + '/' + file.getFullThumbnailPath()).getDownloadURL().toPromise().then(link => {
                file.thumbnailLink = link;
              }).catch(ex => console.error(ex));
            }
          }
        }
        return file
      })
    )
  }

  public getPublicRootPath(): string {
    return 'licence_holders/' + this.getLoggedInUser().coach.licenceHolderUid + '/shared_files/';
  }

  public getPersonalRootPath(user: User): string {
    return 'users/' + user.uid + '/shared/' + user.licenceId + '/shared_files/';
  }

  public getRootPath(personal: boolean, user: User): string {
    return personal ? this.getPersonalRootPath(user) : this.getPublicRootPath();
  }

  async saveSharedFile(user: User, file: SharedFile, removeThumbnail: boolean = false, newThumbnail: Blob = null, newFile: File = null): Promise<any> {
    if(removeThumbnail){
      file.thumbnailLink = null;
      file.thumbnailPath = null;
    }
    if(newFile){
      file.isHidden = true;
    }
    if (file.id) {
      await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').doc(file.id).set(file.toMap())
    } else {
      let ref = await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').add(file.toMap())
      file.id = ref.id;
    }

    if(newFile){
      await this.uploadPersonalSharedFile(file, newFile, user);
    }

    if(newThumbnail){
      await this.uploadPersonalFileThumbnail(file, newThumbnail, user);
      await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').doc(file.id).set({timestamp: new Date()}, {merge: true})
    }
  }



  async updateSharedFileTimestamp(user: User, folder: SharedFile) {
    await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').doc(folder.id).set({timestamp: new Date()}, {merge: true})
  }

  async uploadPersonalFileThumbnail(file: SharedFile, newThumbnail: Blob, user: User): Promise<any> {
    await this.fireStorage.upload(this.getPersonalRootPath(user) + file.getFullThumbnailPath(), newThumbnail).catch(e => console.log(e))
  }
  async uploadPublicFileThumbnail(file: SharedFile, newThumbnail: Blob): Promise<any> {
    await this.fireStorage.upload(this.getPublicRootPath() + file.getFullThumbnailPath(), newThumbnail).catch(e => console.log(e))
  }

  async uploadPersonalSharedFile(file: SharedFile, newFile: File, user: User): Promise<any> {
    var activeToast = this.toastr.info("Datei-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true})
    let toastrInstance = this.toastr.toasts?.find(toast => toast.toastId == activeToast.toastId)?.toastRef?.componentInstance;
    try{
      let subscription = this.fireStorage.upload(this.getPersonalRootPath(user) + file.getFullPath(), newFile).percentageChanges().subscribe(async percentage => {
        if(percentage >= 100 || isNaN(percentage)){
          subscription.unsubscribe();
          if(this.fileUploadProgress.has(file.id)) {
            this.fileUploadProgress.delete(file.id);
          }

          if(toastrInstance != null){
            this.toastr.clear(toastrInstance.toastId);
          }

          this.toastr.success("Datei-Upload abgeschlossen.")
          await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/SharedFiles').doc(file.id).set({timestamp: new Date(), isHidden: false}, {merge: true})
        }

        else {
          this.fileUploadProgress.set(file.id, percentage.roundToInt());
          if(toastrInstance != null){
            toastrInstance.message = "Datei-Upload zu " + percentage.roundToInt() + "% abgeschlossen"
          }
        }
      });
    }
    catch(ex){
      if(toastrInstance != null){
        this.toastr.clear(toastrInstance.toastId);
      }
      this.toastr.error("Datei-Upload fehlgeschlagen.");
    }
  }


  async uploadPublicSharedFile(file: SharedFile, newFile: File, coach: Coach): Promise<any> {
    var activeToast = this.toastr.info("Datei-Upload gestartet.", "", { extendedTimeOut: 0, timeOut: 0, disableTimeOut: true})
    let toastrInstance = this.toastr.toasts.find(toast => toast.toastId == activeToast.toastId).toastRef.componentInstance;
    try{
      let subscription = this.fireStorage.upload(this.getPublicRootPath() + file.getFullPath(), newFile).percentageChanges().subscribe(async percentage => {
        if(percentage >= 100 || isNaN(percentage)){
          subscription.unsubscribe();
          if(this.fileUploadProgress.has(file.id)) {
            this.fileUploadProgress.delete(file.id);
          }

          if(toastrInstance != null){
            this.toastr.clear(toastrInstance.toastId);
          }

          this.toastr.success("Datei-Upload abgeschlossen.")
          await this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/SharedFiles').doc(file.id).set({timestamp: new Date(), isHidden: false}, {merge: true})
        }

        else {
          this.fileUploadProgress.set(file.id, percentage.roundToInt());
          if(toastrInstance != null){
            toastrInstance.message = "Datei-Upload zu " + percentage.roundToInt() + "% abgeschlossen"
          }
        }
      });
    }
    catch(ex){
      this.toastr.clear(toastrInstance.toastId);
      this.toastr.error("Datei-Upload fehlgeschlagen.");
    }
  }

  getPublicSharedFiles(): Observable<SharedFile[]> {
    let publicPath = this.getPublicRootPath();
    return this.firestore.collection<SharedFile>('LicenceHolders/' + this.getLoggedInUser().coach.licenceHolderUid + '/SharedFiles').valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var files = []
        documents.forEach(document => {
          var file = new SharedFile(document as SharedFile)
          file.timestamp = new Date((file as any).timestamp.seconds * 1000)
          file.personal = false
          if (!file.deleted) {
              if(file.thumbnailLink == null){
                if(file.isImage()){
                  this.fireStorage.ref(publicPath + '/' + file.getFullPath()).getDownloadURL().toPromise().then(link => {
                    file.thumbnailLink = link;
                    file.url = link;
                  }).catch(ex => console.error(ex));
                }
                else if(file.thumbnailPath){
                  if(file.thumbnailPath && file.thumbnailLink == null){
                    this.fireStorage.ref(publicPath + '/' + file.getFullThumbnailPath()).getDownloadURL().toPromise().then(link => {
                      file.thumbnailLink = link;
                    });
                }
              }
            }
            files.push(file)
          }
        });
        return files
      })
    )
  }

  getPublicSharedFileById(id: string): Observable<SharedFile> {
    let publicPath = this.getPublicRootPath();
    return this.firestore.collection<SharedFile>('LicenceHolders/' + this.getLoggedInUser().coach.licenceHolderUid + '/SharedFiles').doc(id).get().pipe(
      map(document => {
        var file = new SharedFile(document.data() as SharedFile)
        file.timestamp = new Date((file as any).timestamp.seconds * 1000)
        file.personal = false
        file.id = document.id
        if (!file.deleted) {
            if(file.thumbnailLink == null){
              if(file.isImage()){
                this.fireStorage.ref(publicPath + '/' + file.getFullPath()).getDownloadURL().toPromise().then(link => {
                  file.thumbnailLink = link;
                  file.url = link;
                }).catch(ex => console.error(ex));
              }
              else if(file.thumbnailPath){
                if(file.thumbnailPath && file.thumbnailLink == null){
                  this.fireStorage.ref(publicPath + '/' + file.getFullThumbnailPath()).getDownloadURL().toPromise().then(link => {
                    file.thumbnailLink = link;
                  }).catch(ex => console.error(ex));
                }
              }
            }
        }
        return file
      })
    )
  }

  async updatePublicSharedFileTimestamp(coach: Coach, folder: SharedFile) {
    await this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/SharedFiles').doc(folder.id).set({timestamp: new Date()}, {merge: true})
  }

  async savePublicSharedFile(coach: Coach, file: SharedFile, removeThumbnail: boolean = false, newThumbnail: Blob = null, newFile: File = null): Promise<any> {
    if(removeThumbnail){
      file.thumbnailLink = null;
      file.thumbnailPath = null;
    }
    if(newFile){
      file.isHidden = true;
    }

    if (file.id) {
      await this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/SharedFiles').doc(file.id).set(file.toMap())
    } else {
      let ref = await this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/SharedFiles').add(file.toMap())
      file.id = ref.id;
    }


    if(newFile){
      await this.uploadPublicSharedFile(file, newFile, coach);
    }

    if(newThumbnail){
      await this.uploadPublicFileThumbnail(file, newThumbnail);
      await this.firestore.collection('LicenceHolders/' + coach.licenceHolderUid + '/SharedFiles').doc(file.id).set({timestamp: new Date()}, {merge: true})
    }
  }

  getAllNotesForUser(user: User): Observable<Note[]> {
    return this.firestore.collection<Note>('Users/' + user.uid + '/Shared/' + user.getLid() + '/Notes', ref => ref.orderBy('date', 'asc')).valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var notes = []
        documents.forEach(document => {
          var note = new Note(document as Note)
          note.date = new Date((note as any).date.seconds * 1000)
          if (note.attachedFileName) {
            this.fireStorage.ref('licence_holders/' + (this.getLoggedInUser().licenceHolderUid || this.getLoggedInUser().uid) + "/notes/" + user.getLid() + "/" + note.attachedFileName).getDownloadURL().toPromise().then(link => {
              note.attachmentUrl = link
            })
          }
          if(note.voiceRecordingFileName) {
            this.fireStorage.ref('licence_holders/' + (this.getLoggedInUser().licenceHolderUid || this.getLoggedInUser().uid) + "/notes/" + user.getLid() + "/" + note.voiceRecordingFileName).getDownloadURL().toPromise().then(link => {
              note.voiceRecordingUrl = link
            })
          }
          notes.push(note)
        });
        return notes
      })
    )
  }

  private async uploadNoteAttachment(note: Note, user: User): Promise<string> {
    let res = await this.fireStorage.upload('licence_holders/' + (this.getLoggedInUser().licenceHolderUid || this.getLoggedInUser().uid) + "/notes/" + user.getLid() + "/" + note.attachedFileName, note.attachment);
    return res.ref.getDownloadURL();
  }
  private async uploadNoteVoiceRecording(note: Note, user: User): Promise<string> {
    let res = await this.fireStorage.upload('licence_holders/' + (this.getLoggedInUser().licenceHolderUid || this.getLoggedInUser().uid) + "/notes/" + user.getLid() + "/" + note.voiceRecordingFileName, note.tempVoiceRecordingFile);
    return res.ref.getDownloadURL();
  }

  async insertNoteForUser(note: Note, user: User) {
    if (user.notes == null) user.notes = []
    if (note.attachment) {
      let attachementUrl = await this.uploadNoteAttachment(note, user);
      note.attachmentUrl = attachementUrl;
    }
    if(note.tempVoiceRecordingFile) {
      let voiceRecordingUrl = await this.uploadNoteVoiceRecording(note, user);
      note.voiceRecordingUrl = voiceRecordingUrl;
    }
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Notes').add(note.getFirebaseMap()).then((res) => {
      note.id = res.id
      user.notes.push(note)
    })
  }

  async updateNoteForUser(note: Note, user: User) {
    if(note.attachment) {
      let attachementUrl = await this.uploadNoteAttachment(note, user);
      note.attachmentUrl = attachementUrl;
    }
    if(note.tempVoiceRecordingFile) {
      let voiceRecordingUrl = await this.uploadNoteVoiceRecording(note, user);
      note.voiceRecordingUrl = voiceRecordingUrl;
    }
    // if (note.attachment) {
    //   this.fireStorage.upload('licence_holders/' + (this.getLoggedInUser().licenceHolderUid || this.getLoggedInUser().uid) + "/notes/" + user.getLid() + "/" + note.attachedFileName, note.attachment).then((res) => {
    //     res.ref.getDownloadURL().then(link => {
    //       note.attachmentUrl = link
    //     })
    //     this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Notes').add(note.getFirebaseMap()).then((res) => {
    //       note.id = res.id
    //       user.notes.push(note)
    //     })
    //   })
    // } else {
    // }
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Notes').doc(note.id).set(note.getFirebaseMap(), {merge: true}).then((res) => {
      user.notes.forEach( (item, index) => {
        if (item.id === note.id) user.notes.splice(index, 1, note);
      });
    })
  }
  deleteNoteForUser(note: Note, user: User) {
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Notes').doc(note.id).delete().then((res) => {
      user.notes.forEach( (item, index) => {
        if (item.id === note.id) user.notes.splice(index, 1);
      });
    })
  }

  getPublicMetrics(firestore: AngularFirestore = this.commonFirebase.firestore): Promise<Metric[]> {
    return firstValueFrom(firestore.collection<Metric>('Metrics', ref => ref.where('public', '==', true)).valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var metrics: Metric[] = []
        documents.forEach(document => {
          var metric = new Metric(document as Metric)
          if (firestore == this.commonFirebase.firestore && this.commonFirebase.name != this.mainFirebase.name) metric.id = 'NUT_' + metric.id
          metric.metricId = metric.id
          metrics.push(metric)
        })
        return metrics
      })
    ))
  }
  getMetricsByCreatorUid(uid: string): Promise<Metric[]> {
    return firstValueFrom(this.firestore.collection<Metric>('Metrics', ref => ref.where('creatorUid', '==', uid)).valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var metrics: Metric[] = []
        documents.forEach(document => {
          var metric = new Metric(document as Metric)
          metric.metricId = metric.id
          metrics.push(metric)
        })
        return metrics
      })
    ))
  }

  getMetricByMetricId(metricId: string): Metric {
    return this.metrics?.find(m => m.metricId == metricId) || this.allFirebaseMetrics?.find(m => m.metricId == metricId);
  }

  fetchMetricByMetricId(metricId: string): Promise<Metric> {
    if (this.metricPromiseMap.has(metricId)) {
      return this.metricPromiseMap.get(metricId)
    } else {
      var firestore = this.mainFirebase.firestore
      var useCommonFirestore = false
      var rawMetricId = metricId
      if (metricId.includes('NUT_')) {
        firestore = this.commonFirebase.firestore
        useCommonFirestore = true
        rawMetricId = metricId.replace('NUT_', '')
      }
      var promise = firstValueFrom(firestore.collection<Metric>('Metrics').doc(rawMetricId).get().pipe(
        map(document => {
          var metric = new Metric(document.data() as Metric)
          metric.id = document.id
          if (this.commonFirebase.name != this.mainFirebase.name && useCommonFirestore) metric.id = 'NUT_' + metric.id
          metric.metricId = metric.id
          this.allFirebaseMetrics?.push(metric)
          return metric
        })
      ))
      this.metricPromiseMap.set(metricId, promise)
      return promise
    }
  }

  private metricPromiseMap = new Map<string, Promise<Metric>>()

  async loadAllFirebaseMetrics(){
    return

    console.log('loading metrics')
    var documents = await firstValueFrom(this.firestore.collection<Metric>('Metrics').get())
    let metrics: Metric[] = []
    documents.docs.forEach(doc => {
      var metric = new Metric(doc.data() as Metric)
      metric.id = doc.id
      metric.metricId = doc.id
      metrics.push(metric)
    })
    this.allFirebaseMetrics = metrics;
  }
  getAssignedMetricsForUser(user: User): Observable<Metric[]> {
    return this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Metrics', ref => ref.orderBy('assignmentDate', 'desc')).valueChanges({idField: 'id'}).pipe(
      map(documents => {
        var metrics: Metric[] = []
        var maxDate: Date = null
        documents.forEach(document => {
          var metricId = (document as any).metricId
          var assignmentDate = new Date((document as any).assignmentDate.seconds * 1000)

          if (maxDate == null || assignmentDate.getTime() >= maxDate.getTime()) {
            maxDate = assignmentDate
            var metric: Metric = null
            this.metrics.forEach(m => {
              if (metric == null && (metricId == null || metricId == m.metricId)) {
                metric = metricId != null ? m.clone() : new Metric()
                metric.assignmentDate = assignmentDate
                metric.id = document.id
                metric.applied = (document as any).applied
                if ((document as any).targetValue) metric.targetValue = (document as any).targetValue
                metric.displayOrder = (document as any).displayOrder ?? 0
                metric.groupHeading = (document as any).groupHeading
                metric.questionaireId = (document as any).questionaireId
                metric.required = (document as any).required ?? false
                metric.imagePath = (document as any).imagePath
                metric.content = (document as any).content
                metrics.push(metric)
              }
            })
          }
        })
        metrics = metrics.sort((a, b) => a.displayOrder - b.displayOrder)
        return metrics
      })
    )
  }
  loadAssignedMetricsForUser(user: User) {
    var subscriptionMetrics = this.getAssignedMetricsForUser(user).subscribe(metrics => {
      if (metrics != null) {
        user.assignedMetrics = metrics
        subscriptionMetrics.unsubscribe()
      }
    })
  }
  insertAssignedMetrics(user: User, metrics: Metric[]) {
    var date = new Date()
    this.firestore.collection<Metric>('Users/' + user.uid + "/Shared/" + user.getLid() + "/Metrics").ref.where("applied", "==", false).get().then(documents => {
      documents.forEach(document => {
        document.ref.delete()
      });
      var i = 0
      metrics.forEach(metric => {
        this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/Metrics').add({
          metricId: metric.metricId,
          assignmentDate: metric.assignmentDate,
          displayOrder: i,
          applied: false,
          targetValue: metric.targetValue || null,
          required: metric.required,
          imagePath: metric.imagePath ?? null,
          content: metric.content ?? null,
          groupHeading: metric.groupHeading ?? null,
          questionaireId: metric.questionaireId ?? null,
          timestamp: date
        });
        i = i + 1
      })
      var title = user?.appLocale == 'en' ? 'You have received new metrics.' : 'Neue Metriken erhalten'
      var message = user?.appLocale == 'en' ? 'You can find them on the progress page in the menu.' : 'Dein Coach hat dir neue Metriken zugewiesen.'
      if (environment.firebaseProjectId == 'traindoo-app') {
        title = 'Daily Check aktualisiert'
        message = 'Dein Coach hat deinen Daily Check-Fragebogen aktualisiert.'
      }
      this.createUserNotificationEntry(user, title, message, 'newMetricsAssigned', null, null)
      this.sendPushNotification('NEW_ASSIGNMENTS', title, message, user)
    });
  }

  sendExerciseFeedbackNotification(user: User, trackedSessionId: string, trackedExerciseId: string, setNumber: number) {
    if (user.fcmToken && user.fcmToken?.length > 0) {
      const callable = this.firebaseFunctions.httpsCallable('sendNotification');
      callable({ title: 'Video-Feedback erhalten', subtitle: 'Du hast Feedback zu einer Videoaufnahme erhalten.', fcmToken: user.fcmToken, data: {type: 'EXERCISE_FEEDBACK', trackedSessionId: trackedSessionId, trackedExerciseId: trackedExerciseId, setNumber: setNumber?.toString()} }).pipe()
      .subscribe(resp => {console.log(resp)}, err => {console.log(err)});
    }
  }
  sendNewFileNotification(user: User, sharedFileId: string) {
    if (user.fcmToken && user.fcmToken?.length > 0) {
      const callable = this.firebaseFunctions.httpsCallable('sendNotification');
      callable({ title: 'Neue Datei geteilt', subtitle: 'Dein Coach hat dir eine neue Datei freigegeben.', fcmToken: user.fcmToken, data: { type: 'NEW_FILE', sharedFileId: sharedFileId} }).pipe()
      .subscribe(resp => {console.log(resp)}, err => {console.log(err)});
    }
  }

  sendPushNotification(type: string, title: string, body: string, user: User) {
    if (user && user.fcmToken && user.fcmToken?.length > 0) {
      if (type == 'NEW_ASSIGNMENTS' && (user.isAndroidUser() && user.versionCode < 84 || !user.isAndroidUser && user.versionCode < 97)) return
      const callable = user.isAndroidUser() ? this.firebaseFunctions.httpsCallable('sendNotificationHttps') : this.firebaseFunctions.httpsCallable('sendNotificationiOSHttps');
      callable({ type: type, title: title, body: body, fcmToken: user.fcmToken }).pipe()
      .subscribe(resp => {console.log(resp)}, err => {console.log(err)});
    }
  }

  sendCheckInNotification(user: User, questionaire: AssignedQuestionaire) {
    var questionaireId = questionaire.id
    this.createUserNotificationEntry(user, 'Check-In erhalten', 'Dein Coach hat dir den Check-In ' + (questionaire?.name ?? '') + ' zugesendet.', 'newCheckIn', questionaireId, null)
    if (user.fcmToken && user.fcmToken?.length > 0) {
      const callable = this.firebaseFunctions.httpsCallable('sendNotification');
      callable({ title: 'Check-In erhalten', subtitle: 'Du hast einen neuen Check-In erhalten.', fcmToken: user.fcmToken, data: {type: 'NEW_CHECKIN', questionaireId: questionaireId} }).pipe()
      .subscribe(resp => {console.log(resp)}, err => {console.log(err)});
    }
  }

  async createUserNotificationEntry(user: User, title: string, message: string, type: string, typeId: string, subTypeId: string, sendPushNotification: boolean = false) {
    await this.firestore.collection('Users').doc(user.uid).collection('Notifications').add({
      title: title,
      message: message,
      type: type,
      typeId: typeId ?? null,
      subTypeId: subTypeId ?? null,
      sendPushNotification: sendPushNotification,
      read: false,
      timestamp: new Date()
    })
  }

  sendCheckInFeedback(user: User, questionaire: AssignedQuestionaire) {
    var questionaireId = questionaire.id
    if (user.fcmToken && user.fcmToken?.length > 0) {
      const callable = this.firebaseFunctions.httpsCallable('sendNotification');
      callable({ title: 'Check-In-Feedback erhalten', subtitle: 'Du hast Feedback zu einem Check-In erhalten.', fcmToken: user.fcmToken, data: {type: 'CHECKIN_FEEDBACK', questionaireId: questionaireId} }).pipe()
      .subscribe(resp => {console.log(resp)}, err => {console.log(err)});
    }
    this.createUserNotificationEntry(user, 'Check-In-Feedback erhalten', 'Dein Coach hat dir Feedback zu ' + (questionaire.name ?? 'Check-In') + ' gesendet.', 'newCheckInFeedback', questionaireId, null)
  }

  insertNewCustomMetric(user: User, metric: Metric) {
    this.firestore.collection('Metrics').add({
      creatorUid: user.licenceHolderUid,
      metricType: metric.metricType,
      dataType: metric.dataType,
      nameDe: metric.nameDe,
      nameEn: metric.nameEn,
      nameTranslation: metric.nameTranslation.AsMap(),
      descriptionDe: metric.descriptionDe,
      questionText: metric.questionText,
      questionTextTranslation: metric.questionTextTranslation.AsMap(),
      unit: metric.unit,
      unitTranslation: metric.unitTranslation.AsMap(),
      valueExplanation: metric.valueExplanation,
      valueExplanationTranslation: metric.valueExplanationTranslation.AsMap(),
      selectableValues: metric.selectableValues,
      selectableValuesTranslation: metric.selectableValuesTranslation.AsMap(),
      minValue: metric.minValue,
      maxValue: metric.maxValue,
      targetValue: metric.targetValue,
      deleted: false,
      public: metric.public
    }).then(result => {
      this.loadMetrics()
    })
  }
  deleteCustomMetric(metric: Metric) {
    this.firestore.collection('Metrics').doc(metric.metricId).set({
      deleted: true,
    }, { merge: true });
  }

  updateCustomMetric(metric: Metric) {
    this.firestore.collection('Metrics').doc(metric.metricId).update({
      nameDe: metric.nameDe,
      nameEn: metric.nameEn,
      nameTranslation: metric.nameTranslation.AsMap(),
      descriptionDe: metric.descriptionDe,
      questionText: metric.questionText,
      questionTextTranslation: metric.questionTextTranslation.AsMap(),
      valueExplanation: metric.valueExplanation,
      valueExplanationTranslation: metric.valueExplanationTranslation.AsMap(),
      selectableValues: metric.selectableValues,
      selectableValuesTranslation: metric.selectableValuesTranslation.AsMap(),
      unit: metric.unit,
      unitTranslation: metric.unitTranslation.AsMap(),
      minValue: metric.minValue,
      maxValue: metric.maxValue,
      targetValue: metric.targetValue,
    }).then(result => {
      this.loadMetrics()
    });
  }

  getSpecificMeal(mealid: number) {
    return this.user.meals[mealid - 1];
  }

  getLoggedInUser() {
    return this.user;
  }

  getClientsOfCoach() {
    return this.clientsOfCoach;
  }
  getAccessibleClients() {
    return this.clientsOfLicenceHolder?.filter(c => this.getLoggedInUser()?.coach?.canAccessUser(c)) ?? []
  }
  getObservableClients() {
    return this.observableClients
  }
  getNewConnectedClients() {
    return this.newConnectedClients
  }
  getAllClientsOfLicenceHolder() {
    return this.clientsOfLicenceHolder ?? [];
  }
  getClient(uid: string) {
    return this.getAccessibleClients().filter(client => client.uid == uid).shift()
  }

  getAccessiblePendingLicences() {
    return this.pendingLicences?.filter(licence => this.getLoggedInUser()?.coach?.canAccessLicence(licence)) || []
  }
  getTeamLicences() {
    return this.activeLicences;
  }
  async getLicenceById(id: string, fetchOnline: boolean = false): Promise<Licence> {
    var licence = this.allLicences?.filter(licence => licence.lid == id).shift()
    if (licence || !fetchOnline) return licence
    licence = await firstValueFrom(this.firestore.collection<Licence>('Licences').doc(id).get().pipe(
      map(document => {
        if (!document.exists) return null
        var licence = new Licence(document.data() as Licence)
        licence.lid = document.id
        if (licence.expirationDate != null) licence.expirationDate = new Date((document.data() as any).expirationDate.seconds * 1000)
        if (licence.redeemDate != null) licence.redeemDate = new Date((document.data() as any).redeemDate.seconds * 1000)
        if (licence.plannedActivationDate != null) licence.plannedActivationDate = new Date((document.data() as any).plannedActivationDate.seconds * 1000)
        return licence
      })
    ))
    return licence
  }

  async getOldLicences(): Promise<Licence[]> {
    var licences: Licence[] = await firstValueFrom(this.firestore.collection<Licence>('/Licences', ref => ref.where('licenceHolderUid', '==', this.user.licenceHolderUid || this.user.uid)).valueChanges().pipe(
      map(documents => {
        var licences = []
        documents.forEach(document => {
          var licence = new Licence(document as Licence)
          if (licence.lid) {
            if (licence.licenceType == 'Coaching' && licence.userUid != null && !licence.active) {
              if (licence.expirationDate != null) licence.expirationDate = new Date((document as any).expirationDate.seconds * 1000)
              if (licence.redeemDate != null) licence.redeemDate = new Date((document as any).redeemDate.seconds * 1000)
              if (licence.plannedActivationDate != null) licence.plannedActivationDate = new Date((document as any).plannedActivationDate.seconds * 1000)
              licences.push(licence)
            }
          }
        })
        return licences
      })
    ))
    for (var licence of licences) {
      licence.user = await firstValueFrom(this.getUserByUid(licence.userUid))
    }
    return licences
  }

  createStripeCustomerPortalSession(customerId: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeCustomerPortalSession')
    return callable({ customerId: customerId })
  }
  createStripeUsageRecord(subscriptionItemId: string, quantity: number): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeUsageRecord')
    return callable({ subscriptionItemId: subscriptionItemId, quantity: quantity })
  }
  createStripeCheckoutSession(customerId: string, priceId: string, addStarterPackage: boolean, allowPaymentMethodCreditCard: boolean): Observable<any> {
    if (!customerId) return
    const callable = this.firebaseFunctions.httpsCallable('createStripeCheckoutSession')
    return callable({ priceId: priceId, customerId: customerId, addStarterPackage: addStarterPackage, allowPaymentMethodCreditCard: allowPaymentMethodCreditCard })
  }
  createStripeCustomer(firebaseUid: string, email: string, name: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeCustomer')
    return callable({ firebaseUid: firebaseUid, email: email, name: name })
  }

  createStripeConnectAccount(firebaseUid: string, email: string, name: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeAccount')
    return callable({ firebaseUid: firebaseUid, email: email, name: name })
  }
  createStripeConnectAccountLink(accountId: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectAccountLink')
    return callable({ accountId: accountId, type: 'account_onboarding' })
  }
  isStripeAccountVerified(accountId: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('isStripeAccountVerified')
    return callable({ accountId: accountId })
  }
  getStripeAccountBalance(accountId: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('getStripeAccountBalance')
    return callable({ accountId: accountId })
  }
  getStripeAccountPaymentMethods(accountId: string): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('getStripeAccountPaymentMethods')
    return firstValueFrom(callable({ accountId: accountId }))
  }
  async saveProduct(product: Product) {
    /*const callable = this.firebaseFunctions.httpsCallable('createStripeProduct')
    var result = await firstValueFrom(callable({ accountId: accountId, name: product.name, price: product.price, currency: product.currency, recurring: product.recurring }))
    console.log(result)
    product.stripeProductId = result.productId
    product.stripePriceId = result.priceId*/
    if (product.id) {
      await this.firestore.collection('Products').doc(product.id).set(product.asMap(), { merge: true })
    } else {
      var res = await this.firestore.collection('Products').add(product.asMap())
      product.id = res.id
    }
  }
  async deleteStripeProduct(product: Product) {
    if (product.id) {
      await this.firestore.collection('Products').doc(product.id).update({deleted: true})
    }
  }
  async createStripeConnectCustomer(accountId: string, firebaseUid: string, name: string): Promise<string> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectCustomer')
    var result = await firstValueFrom(callable({ accountId: accountId, firebaseUid: firebaseUid, name: name }))
    return result?.customerId
  }
  async saveProductPurchase(subscription: ProductPurchase): Promise<ProductPurchase> {
    if (subscription.id) {
      await this.firestore.collection('ProductPurchases').doc(subscription.id).set(subscription.asMap(), { merge: true })
    } else {
      var res = await this.firestore.collection('ProductPurchases').add(subscription.asMap())
      subscription.id = res.id
    }
    return subscription
  }
  async updateProductPurchasePaymentMethod(subscription: ProductPurchase, paymentMethod: string): Promise<ProductPurchase> {
    if (subscription.id) {
      await this.firestore.collection('ProductPurchases').doc(subscription.id).update({ selectedPaymentMethod: paymentMethod })
    }
    return subscription
  }
  createStripeConnectCheckoutSession(productPurchaseId: string, productId: string, customerUid: string, paymentMethod: string | null): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectCheckoutSession')
    return callable({ productPurchaseId: productPurchaseId, productId: productId, customerUid: customerUid, paymentMethod: paymentMethod })
  }
  createStripeConnectPaymentMethodUpdateSession(licenceHolderUid: string, customerUid: string, paymentId: string, paymentMethod: string | null): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectPaymentMethodUpdateSession')
    return callable({ licenceHolderUid: licenceHolderUid, customerUid: customerUid, paymentId: paymentId, paymentMethod: paymentMethod })
  }
  createStripeConnectBillingPortalLink(productPurchaseId: string, customerUid: string, paymentMethod: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectBillingPortalLink')
    return callable({ productPurchaseId: productPurchaseId, customerUid: customerUid, paymentMethod: paymentMethod })
  }
  async cancelProductPurchase(purchase: ProductPurchase, cancelNow: boolean = false) {
    const callable = this.firebaseFunctions.httpsCallable('cancelProductPurchase')
    return await firstValueFrom(callable({ productPurchaseId: purchase.id, cancelNow: cancelNow }))
  }
  completeProductPurchase(productPurchaseId: string, productId: string, customerUid: string, paymentMethod: string | null ): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('completeProductPurchase')
    return callable({ productPurchaseId: productPurchaseId, productId: productId, customerUid: customerUid, paymentMethod: paymentMethod })
  }
  updateStripeConnectCustomer(address: any, firebaseUid: string, accountId: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('updateStripeConnectCustomer')
    return callable({ address: { street: address.street, city: address.city, postalCode: address.postalCode, country: address.country }, name: address.name, firebaseUid: firebaseUid, accountId: accountId })
  }
  getStripeConnectCustomerData(firebaseUid: string, accountId: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('getStripeConnectCustomerData')
    return callable({ firebaseUid: firebaseUid, accountId: accountId })
  }
  activateLicenceForProductPurchase(licenceId: string, productPurchaseId: string): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('activateLicenceForProductPurchase')
    return firstValueFrom(callable({ licenceId: licenceId, productPurchaseId: productPurchaseId }))
  }
  updateProductPurchaseStartDate(productPurchaseId: string, startDate: Date): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('updateProductPurchaseStartDate')
    return firstValueFrom(callable({ productPurchaseId: productPurchaseId, startDate: startDate }))
  }
  updateProductPurchaseAvailablePaymentMethods(productPurchase: ProductPurchase): Promise<any> {
    return this.firestore.collection('ProductPurchases').doc(productPurchase.id).update({ availablePaymentMethods: productPurchase.availablePaymentMethods })
  }
  updateProductPurchaseRuntime(productPurchaseId: string, endDate: Date, durationMultiplier: number): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('updateProductPurchaseRuntime')
    return firstValueFrom(callable({ productPurchaseId: productPurchaseId, endDate: endDate, durationMultiplier: durationMultiplier }))
  }
  updateProductPurchasePaymentDate(productPurchaseId: string, shift: number): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('updateProductPurchasePaymentDate')
    return firstValueFrom(callable({ productPurchaseId: productPurchaseId, shift: shift }))
  }
  createPayment(licenceHolderUid: string, stripeAccountId: string, customerUid: string, amount: number, currency: string, items: any[]): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('createPayment')
    return firstValueFrom(callable({ licenceHolderUid: licenceHolderUid, stripeAccountId: stripeAccountId, customerUid: customerUid, amount: amount, currency: currency, items: items }))
  }
  updateDefaultPaymentMethod(customerUid: string, licenceHolderUid: string, paymentMethodId: string, paymentMethodType: string): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('updateDefaultPaymentMethod')
    return callable({ customerUid: customerUid, licenceHolderUid: licenceHolderUid, paymentMethodId: paymentMethodId, paymentMethodType: paymentMethodType })
  }
  async deleteProductPurchase(purchase: ProductPurchase) {
    if (purchase.id) {
      await this.firestore.collection('ProductPurchases').doc(purchase.id).set( { deleted: true, nextPaymentDate: null }, { merge: true })
    }
  }
  async updatePaymentPayoutData(payment: Payment) {
    await this.firestore.collection('Payments').doc(payment.id).set({ payoutDate: payment.payoutDate, reversePayoutDate: payment.reversePayoutDate }, { merge: true })
  }
  async refundPayment(payment: Payment, accountId: string) {
    const callable = this.firebaseFunctions.httpsCallable('refundPayment')
    var res = await firstValueFrom(callable({ paymentId: payment.id, accountId: accountId }))
    console.log(res)
    return res
  }
  async voidPayment(payment: Payment, accountId: string) {
    const callable = this.firebaseFunctions.httpsCallable('voidPayment')
    var res = await firstValueFrom(callable({ paymentId: payment.id, accountId: accountId }))
    console.log(res)
  }
  async reChargePayment(payment: Payment, accountId: string) {
    const callable = this.firebaseFunctions.httpsCallable('reChargePayment')
    var res = await firstValueFrom(callable({ paymentId: payment.id, accountId: accountId }))
    console.log(res)
  }
  async markPaymentAsPaid(payment: Payment, accountId: string) {
    const callable = this.firebaseFunctions.httpsCallable('markPaymentAsPaid')
    var res = await firstValueFrom(callable({ paymentId: payment.id, accountId: accountId }))
    console.log(res)
  }
  async skipNextPaymentDate(productPurchase: ProductPurchase) {
    const callable = this.firebaseFunctions.httpsCallable('skipNextPaymentDate')
    var res = await firstValueFrom(callable({ productPurchaseId: productPurchase.id }))
    console.log(res)
  }
  async recreateInvoicePdf(payment: Payment) {
    const callable = this.firebaseFunctions.httpsCallable('recreateInvoicePdf')
    var res = await firstValueFrom(callable({ paymentId: payment.id }))
    console.log(res)
  }
  async recreateCancellationInvoicePdf(payment: Payment) {
    const callable = this.firebaseFunctions.httpsCallable('recreateCancellationInvoicePdf')
    var res = await firstValueFrom(callable({ paymentId: payment.id }))
    console.log(res)
  }
  async resendPaymentInstructions(payment: Payment, accountId: string) {
    const callable = this.firebaseFunctions.httpsCallable('resendPaymentInstructionsEmail')
    var res = await firstValueFrom(callable({ paymentId: payment.id, accountId: accountId }))
    return res
  }

  deleteCoachInvoiceFromSevDesk(invoice: CoachInvoice): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('deleteInvoiceFromSevDesk')
    return firstValueFrom(callable({ id: invoice.id }))
  }
  markSevDeskCoachInvoiceAsPaid(invoice: CoachInvoice): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('markSevDeskInvoiceAsPaid')
    return firstValueFrom(callable({ id: invoice.id }))
  }
  updateCoachInvoicePaymentStatus(invoice: CoachInvoice, status: string): Promise<any> {
    return this.firestore.collection('Invoices').doc(invoice.id).update({ status: status })
  }

  /*async createPayoutReport(accountId: string, licenceHolderUid: string, startDate: Date, endDate: Date): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectPayoutReport')
    var res = await firstValueFrom(callable({ accountId: accountId, licenceHolderUid: licenceHolderUid, startDate: startDate, endDate: endDate}))
    console.log(res)
  }
  getStripeReport(reportId: string): Observable<any> {
    return this.firestore.collection('LicenceHolders').doc(this.getLoggedInUser().licenceHolderUid).collection('StripeReports').doc(reportId).valueChanges().pipe(
      map(document => {
        console.log(document)
      })
    )
  }*/

  async createCustomToken(uid: string) {
    const callable = this.firebaseFunctions.httpsCallable('createCustomToken')
    var res = await firstValueFrom(callable({ uid: uid }))
    console.log(res)
    return res
  }

  /*async createStripeConnectSubscription(accountId: string, subscription: ProductSubscription): Promise<ProductSubscription> {
    console.log('Create subscription')
    const callable = this.firebaseFunctions.httpsCallable('createStripeConnectSubscription')
    var result = await firstValueFrom(callable({ accountId: accountId, customerId: subscription.stripeCustomerId, priceId: subscription.stripePriceId }))
    console.log(result)
    if (result?.subscriptionId) {
      subscription.stripeSubscriptionId = result.subscriptionId
      if (subscription.id) {
        await this.firestore.collection('ProductSubscriptions').doc(subscription.id).set(subscription.asMap(), { merge: true })
      } else {
        await this.firestore.collection('ProductSubscriptions').add(subscription.asMap())
      }
      return subscription
    }
    return null
  }*/

  getProducts(licenceHolderUid: string): Observable<Product[]> {
    return this.firestore.collection<Product>('Products', ref => ref.where( 'licenceHolderUid', '==', licenceHolderUid )).valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var products = []
        documents.forEach(document => {
          var product = new Product(document as Product)
          products.push(product)
        });
        return products
      })
    )
  }
  getProduct(productId: string): Promise<Product> {
    return firstValueFrom(this.firestore.collection<Product>('Products').doc(productId).get().pipe(
      map(document => {
        if (!document.exists || !document.data()) return null
        var product = new Product(document.data() as Product)
        product.id = document.id
        return product
      })
    ))
  }

  productPurchases: ProductPurchase[]
  getProductPurchases(licenceHolderUid: string): Observable<ProductPurchase[]> {
    return this.firestore.collection<ProductPurchase>('ProductPurchases', ref => ref.where( 'licenceHolderUid', '==', licenceHolderUid )).valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var subscriptions = []
        documents.forEach(document => {
          var product = new ProductPurchase(document as ProductPurchase)
          if (!product.deleted && product.status != 'draft') subscriptions.push(product)
        });
        subscriptions.sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime())
        this.productPurchases = subscriptions
        this.refreshExpiringLicences()
        return subscriptions
      })
    )
  }
  getProductPurchase(id: string): Observable<ProductPurchase> {
    return this.firestore.collection<ProductPurchase>('ProductPurchases').doc(id).valueChanges( {idField: 'id'} ).pipe(
      map(document => {
        var subscription = new ProductPurchase(document as ProductPurchase);
        return subscription
      })
    )
  }
  getPayments(licenceHolderUid: string): Observable<Payment[]> {
    return this.firestore.collection<Payment>('Payments', ref => ref.where( 'licenceHolderUid', '==', licenceHolderUid )).valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var payments = []
        documents.forEach(document => {
          var payment = new Payment(document as Payment)
          /*if (!(document as any).licenceHolderUid) {
            console.log(document)
            this.firestore.collection('Payments').doc(document.id).update({ licenceHolderUid: null })
          }*/
          payments.push(payment)
        });
        payments.sort((a, b) => b.date.getTime() - a.date.getTime())
        return payments
      })
    )
  }
  getPayment(paymentId: string): Promise<Payment> {
    return firstValueFrom(this.firestore.collection<Payment>('Payments').doc(paymentId).get().pipe(
      map(document => {
        var payment = new Payment(document.data() as Payment)
        return payment
      })
    ))
  }
  getPaymentsOfUser(customerUid: string): Observable<Payment[]> {
    return this.firestore.collection<Payment>('Payments', ref => ref.where( 'customerUid', '==', customerUid )).valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var payments = []
        documents.forEach(document => {
          var payment = new Payment(document as Payment)
          payments.push(payment)
        });
        payments.sort((a, b) => b.date.getTime() - a.date.getTime())
        return payments
      })
    )
  }
  getCustomerPaymentSettings(customerUid: string): Promise<CustomerPaymentSettings> {
    return firstValueFrom(this.firestore.collection<CustomerPaymentSettings>('Users/' + customerUid + '/Settings').doc('PaymentSettings').get().pipe(
      map(document => {
        if (!document.exists || !document.data() || !document.data()) return null
        var settings = new CustomerPaymentSettings(document.data() as CustomerPaymentSettings)
        return settings
      })
    ))
  }

  getSubscriptionsByStripeCustomerId(customerId: string): Observable<CoachSubscription[]> {
    return this.firestore.collection<CoachSubscription>('Subscriptions', ref => ref.where( 'stripeCustomerId', '==', customerId )).valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var subscriptions = []
        documents.forEach(document => {
          var subscription = new CoachSubscription(document as CoachSubscription)
          subscription.creationDate = new Date((subscription as any).creationDate.seconds * 1000)
          if (subscription.overwriteSubscriptionStartDate) subscription.overwriteSubscriptionStartDate = new Date((subscription as any).overwriteSubscriptionStartDate.seconds * 1000)
          subscriptions.push(subscription)
        });
        return subscriptions
      })
    )
  }

  productSubscriptionSubscription: Subscription
  loadSubscriptions(user: User) {
    if (this.productSubscriptionSubscription) this.productSubscriptionSubscription.unsubscribe()
    this.productSubscriptionSubscription = this.getSubscriptionsByStripeCustomerId(this.user.getStripeCustomerId()).subscribe(subscriptions => {
      if (user.observableSubscription == null) user.observableSubscription = new BehaviorSubject(undefined)
      var currentSubscription = null
      subscriptions.forEach(subscription => {
        if (subscription.product == (environment.firebaseProjectId == 'traindoo-app' ? 'TRAINDOO' : 'nutrilize for Coaches') && subscription.active) {
          currentSubscription = subscription
        }
      })
      user.observableSubscription.next(currentSubscription)
      if (this.clientsOfLicenceHolder != null) {
        this.initIntercomWidget(currentSubscription != null, this.clientsOfLicenceHolder?.length ?? null)
      } else {
        this.observableClients.subscribe(clients => {
          this.initIntercomWidget(currentSubscription != null, this.clientsOfLicenceHolder?.length ?? null)
        })
      }
    })
  }
  loadCoachSubscription(coach: LicenceHolder): Promise<any> {
    return this.getSubscriptionsByStripeCustomerId(coach.stripeCustomerId).pipe(map(subscriptions => {
      subscriptions.forEach(subscription => {
        if (subscription.product == (environment.firebaseProjectId == 'traindoo-app' ? 'TRAINDOO' : 'nutrilize for Coaches') && subscription.active) {
          coach.subscription = subscription
        }
      })
    })).toPromise()
  }

  async loadCoachInvoices(onlyIfNotAdmin: boolean = false) {
    var user = await this.mainFirebase.auth.currentUser
    var isAdmin = AuthService.isAdmin(user);
    if (onlyIfNotAdmin && isAdmin) return
    (AuthService.isAdmin(user) ? this.getInvoicesOfCoaches() : this.getInvoicesOfCoach(this.user.licenceHolderUid)).subscribe(invoices => {
      var coachInvoices = invoices.filter(x => !(x.status == 'paid' && (x.amount == 0 || x.amount == null)))
      this.user.licenceHolder.coachInvoices = coachInvoices
      if (AuthService.isAdmin(user)) {
        firstValueFrom(this.getAllLicenceHolders()).then(licenceHolders => {
          coachInvoices.forEach(invoice => {
            invoice.licenceHolder = licenceHolders.find(lh => lh.uid == invoice.licenceHolderId)
          })
        })
      }
    })
  }

  //#region Scheduled Push-Notification
  insertAutomaticPushNotification(notification: AutomaticPushNotification){
    this.firestore.collection('ScheduledPushNotifications').add({
      active: notification.active,
      nextExecutionDate: Timestamp.fromDate(notification.nextExecutionDate),
      repetition: notification.repetition,
      repetitionMultiplier: notification.repetitionMultiplier,
      title: notification.title,
      description: notification.description,
      groupNames: notification.groupNames,
      userUids: notification.userUids,
      coachUid: notification.coachUid,
      coachUids: notification.coachUids,
      licenceHolderUid: this.user.licenceHolderUid
    }).then((res) => {
      notification.id = res.id
    })
  }

  getScheduledPushNotifications(licenceHolderUid: string): Observable<AutomaticPushNotification[]>{
      var pushNotifications = this.firestore.collection<AutomaticPushNotification>('ScheduledPushNotifications', ref => ref.where('licenceHolderUid', '==', licenceHolderUid)).valueChanges({idField: 'id'}).pipe(map(documents => {
        var notifications: AutomaticPushNotification[] = []
        documents.forEach(document => {
          var notification = new AutomaticPushNotification(document as AutomaticPushNotification)
          notification.active = ((document as any).active as boolean);
          notification.nextExecutionDate = ((document as any).nextExecutionDate as Timestamp).toDate()
          notifications.push(notification)
        })
        return notifications.sort((x, y) => -x.nextExecutionDate - -y.nextExecutionDate).sort(val => {return val.active ? -1 : 1})
      }))
      return pushNotifications
  }

  deleteScheduledPushNotification(notification: AutomaticPushNotification): Promise<void> {
    return this.firestore.collection<AutomaticPushNotification>('ScheduledPushNotifications').doc(notification.id).delete()
  }

  updateScheduledPushNotification(notification: AutomaticPushNotification){
    this.firestore.collection('ScheduledPushNotifications').doc(notification.id).set({
      active: notification.active,
      nextExecutionDate: notification.nextExecutionDate,
      repetition: notification.repetition,
      repetitionMultiplier: notification.repetitionMultiplier,
      title: notification.title,
      description: notification.description,
      groupNames: notification.groupNames,
      userUids: notification.userUids,
      coachUid: notification.coachUid,
      coachUids: notification.coachUids,
      licenceHolderUid: this.user.licenceHolderUid
    })
  }
  //#endregion

  getInvoicesOfCoach(licenceHolderUid: string): Observable<CoachInvoice[]> {
    return this.firestore.collection<CoachInvoice>('Invoices', ref => ref.where( 'licenceHolderId', '==', licenceHolderUid )).valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var payments = []
        documents.forEach(document => {
          if ((document as any).deleted) return
          var payment = new CoachInvoice(document as CoachInvoice)
          payments.push(payment)
        });
        payments.sort((a, b) => b.date.getTime() - a.date.getTime())
        return payments
      })
    )
  }
  getInvoicesOfCoaches(): Observable<CoachInvoice[]> {
    return this.firestore.collection<CoachInvoice>('Invoices').valueChanges( {idField: 'id'} ).pipe(
      map(documents => {
        var payments = []
        documents.forEach(document => {
          if ((document as any).deleted) return
          var payment = new CoachInvoice(document as CoachInvoice)
          payments.push(payment)
        });
        payments.sort((a, b) => b.date.getTime() - a.date.getTime())
        return payments
      })
    )
  }
  deleteInvoiceOfCoach(invoice: CoachInvoice): Promise<any> {
    return this.firestore.collection('Invoices').doc(invoice.id).update({deleted: true})
  }
  markInvoiceOfCoachAsPaid(invoice: CoachInvoice): Promise<any> {
    return this.firestore.collection('Invoices').doc(invoice.id).update({ status: 'paid' })
  }

  //#region Event Log

  getEventLogs(licenceHolderUid: string, date:Date): Observable<EventLog[]>{
      var observableEventLogs = this.firestore.collection<EventLog>('EventLog', ref => ref.where('licenceHolderUid', '==', licenceHolderUid).where('date','>=', new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0)).where('date','<', new Date(date.getFullYear(), date.getMonth(), date.getDate()+1, 0)).orderBy("date", "desc")).valueChanges({idField: 'id'}).pipe(map(documents => {
        var events: EventLog[] = []
        documents.forEach(document => {
          var event = new EventLog(document as EventLog);
          event.date = ((document as any).date as Timestamp)?.toDate()
          event.referenceDate = ((document as any).referenceDate as Timestamp)?.toDate()
          event.read = ((document as any).read as boolean) || false
          events.push(event)
        })
        return events
      }));
    return observableEventLogs
  }

  updateReadEventLog(eventLog:EventLog){
    this.firestore.collection('EventLog').doc(eventLog.id).update({
      read: eventLog.read
    })
  }

  //#endregion

  updateCoachFcmToken(fcmTokens:string[], coachUid: string): Observable<boolean> {
    return new Observable((observer) => {
      var docRef = this.firestore.collection('Coaches').doc(coachUid);
      docRef.update({
          fcmTokens: fcmTokens
        }).catch(x => {x
          console.log("Error updateCoachFcmToken: " + x)
        }).then(x =>
          {
            console.log("updateCoachFcmToken success")
            observer.next(true)
            observer.complete()
          }).catch(x => {
            console.log("Error updateCoachFcmToken")
            observer.next(false)
            observer.complete()
        });
    });
  }

  updateTrainingEnabled(user: User, value: boolean) {
    var subscription = this.getAllCoachesByLicenceHolderUid(user.licenceHolderUid || user.uid).subscribe(coaches => {
      if (subscription != null) subscription.unsubscribe()
      coaches.forEach(coach => {
        this.firestore.collection('Coaches/').doc(coach.uid).update({trainingEnabled: value, timestamp: new Date()})
      })
      user.coach.trainingEnabled = value
    })
  }

  async loadTrainingPlansIfNotLoaded(user: User, coach: User, updateTrainingPlanEndDate: boolean = false) {
    if (!user.loadedTrainingPlans) {
      await this.loadTrainingPlans(user, coach, updateTrainingPlanEndDate)
    }
  }

  private currentlyLoadingTrainingPlans: boolean = false

  async loadTrainingPlans(user: User, coach: User, updateTrainingPlanEndDate: boolean = false) {
    if (user.observableTrainingPlansLoader.getValue() == true) {
      await user.observableTrainingPlansLoader.toPromise()
      return
    }

    try{
      if (this.currentlyLoadingTrainingPlans) return
      this.currentlyLoadingTrainingPlans = true
      var documents = await firstValueFrom(this.firestore.collection<any>('Users/' + user.uid + '/PlannedTrainingExercises/').get())
      var plannedTrainingExercises:PlannedTrainingExercise[] = []
      for (let document of documents.docs) {
        var plannedExercise = new PlannedTrainingExercise(document.data() as PlannedTrainingExercise)
        plannedExercise.id = document.id
        if (document.data().videoRecordingRequest) {
          var videoRecordingRequest = document.data().videoRecordingRequest as VideoRecordingRequest;
          plannedExercise.videoRecordingRequest = new VideoRecordingRequest(videoRecordingRequest.active, videoRecordingRequest.frequency, videoRecordingRequest.hint)
        }
        if(document.data().superSetConfig) {
          var superSetConfig = document.data()?.superSetConfig as SuperSetConfig;
          plannedExercise.superSetConfig = new SuperSetConfig(superSetConfig?.numberOfRounds, superSetConfig?.totalAvailableTime, superSetConfig?.roundAvailableTime, superSetConfig?.name, superSetConfig?.nameTranslation);
        }
        plannedTrainingExercises.push(plannedExercise)
      }

      documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrainingPlans/').get().toPromise()
      var trainingPlans: TrainingPlan[] = []
      for (let document of documents.docs) {
        if (document.data().deleted == null || !document.data().deleted) {
          let trainingPlan = new TrainingPlan(document.data() as TrainingPlan)
          if (trainingPlan.custom) continue;
          if (trainingPlan.licenceHolderUid && trainingPlan.licenceHolderUid != coach.licenceHolderUid && trainingPlan.licenceHolderUid != 'nutrilize') continue;
          trainingPlan.id = document.id
          if (!trainingPlan.startDateString && document.data().startDate) trainingPlan.startDate = new Date(document.data().startDate.seconds * 1000)
          if (!trainingPlan.endDateString && document.data().endDate) trainingPlan.endDate = new Date(document.data().endDate.seconds * 1000)

          trainingPlan.sessions = [];
          for(let sessionDoc of ((document.data() as any).sessions as any[])){
            let session = new TrainingSession(sessionDoc.name, sessionDoc.id, sessionDoc.isRestDay, [], sessionDoc.deleted, sessionDoc.weekId, sessionDoc.plannedDate, sessionDoc.plannedDateString, sessionDoc.baseSessionId, sessionDoc.hide, sessionDoc.indicatorColor, sessionDoc.estimatedDurationInMinutes, sessionDoc.nameTranslation);
            if(!session.plannedDateString && (sessionDoc.plannedDate as any)?.seconds){
              session.plannedDate = new Date((sessionDoc.plannedDate as any)?.seconds * 1000);
            }
            var plannedExercises = plannedTrainingExercises.filter(x => x.sessionId == session.id);
            if(plannedExercises?.length > 0) session.exercises = plannedExercises.sort((n1,n2) => n1.position - n2.position);
            trainingPlan.sessions.push(session);
          }

          if(trainingPlan.imagePath?.length > 0){
            firstValueFrom(this.fireStorage.ref(trainingPlan.imagePath).getDownloadURL()).then((link) => {
              trainingPlan.imageDownloadURL = link;
            }).catch(ex => console.log(ex));
          }
          trainingPlans.push(trainingPlan)
        }
      }
      if (environment.firebaseProjectId == 'traindoo-app') {
        user.trainingPlans = trainingPlans.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
      } else {
        user.trainingPlans = trainingPlans.sort((a, b) => a.startDate.getTime() - b.startDate.getTime()).sort(val => {return !val.isDeprecated ? -1 : 1});
      }
      user.loadedTrainingPlans = true

      if (updateTrainingPlanEndDate) {
        var maxEndDate: Date = undefined
        user.trainingPlans.forEach(plan => {
          if (!plan.isOlderThanToday()) {
            if (plan.endDate) {
              if (maxEndDate === undefined || maxEndDate && plan.endDate.isSameOrAfterDate(maxEndDate)) maxEndDate = plan.endDate
            } else {
              maxEndDate = null
            }
          }
        })
        if (user.metadata != null && maxEndDate != user.metadata?.trainingPlanEndDate) {
          user.metadata.trainingPlanEndDate = maxEndDate || null
          this.updateMetadata(user)
        }
      }
    }
    catch(error){
      console.error(error)
    }
    finally{
      this.currentlyLoadingTrainingPlans = false
    }
    user.observableTrainingPlansLoader.next(true)
    user.observableTrainingPlansLoader.complete()
  }

  saveOrUpdatePlannedTrainingExercise(exercise:PlannedTrainingExercise, position: number, user: User, session: TrainingSession){
    if(exercise.id?.length > 0) {
      this.firestore.collection('Users/' + user.uid + '/PlannedTrainingExercises/').doc(exercise.id)
      .update(this.convertExerciseToFirebaseData(position, session, exercise));
    }
    else{
      this.firestore.collection('Users/' + user.uid + '/PlannedTrainingExercises/')
      .add(this.convertExerciseToFirebaseData(position, session, exercise))
      .then((res) => {
        exercise.id = res.id
      });
    }
  }

  async saveTrainingPlan(user: User, coach: User, trainingPlan: TrainingPlan, removeThumbnail: boolean, thumbnailImage: File, progressBehaviorSubject: BehaviorSubject<string> = null){
    let result = false;
    try {
      console.log(trainingPlan)
      let progress = 0;
      if(!trainingPlan.coachUid && !trainingPlan.licenceHolderUid){
        trainingPlan.coachUid = coach.uid;
        trainingPlan.licenceHolderUid = coach.licenceHolderUid;
      }
      const planRef = await this.firestore.collection(`Users/${user.uid}/TrainingPlans`).add(this.convertTrainingPlanToFirebaseData(trainingPlan));
      trainingPlan.id = planRef.id;
      progress = 0.1;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
      if(thumbnailImage) {
        trainingPlan.imagePath = this.getTrainingPlanThumbnailPath(trainingPlan);
        await this.uploadTrainingPlanThumbnailImage(thumbnailImage, trainingPlan)
        planRef.set({
          timestamp: Timestamp.now(),
          imagePath: trainingPlan.imagePath,
        }, {merge: true});
      }
      const exercisesPromises = [];
      progress = 0.3;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)

      for (const session of trainingPlan.sessions) {
        var exerciseCount = 0
        for (let i = 0; i < session.exercises.length; i++) {
          const exercise = session.exercises[i];
          const exercisePromise = this.firestore.collection(`Users/${user.uid}/PlannedTrainingExercises`).add(this.convertExerciseToFirebaseData(exerciseCount, session, exercise)).then((res) => { exercise.id = res.id });
          exercisesPromises.push(exercisePromise);
          if (!exercise.deleted) exerciseCount++
        }
      }
      progress = 0.4;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)

      await Promise.all(exercisesPromises);
      progress = 0.7;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)

      if(!trainingPlan.isTemplate){
        await this.setNotExistingUserTrainingVariables(user, trainingPlan.trainingVariables);
      }
      progress = 0.8;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)

      await this.setNotExistingGlobalTrainingVariables(trainingPlan.trainingVariables);
      progress = 0.9;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)


      user.trainingPlans.push(trainingPlan);

      if (environment.firebaseProjectId == 'traindoo-app') {
        user.trainingPlans = user.trainingPlans.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
      } else {
        user.trainingPlans = user.trainingPlans.sort((a, b) => a.startDate.getTime() - b.startDate.getTime()).sort(val => {return !val.isDeprecated ? -1 : 1});
      }

      await this.loadTrainingPlans(user, coach, true)
      progress = 0.95;
      progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
      result = true;
    }
    catch(error){
      console.error(error)
      this.toastr.error('Fehler beim Speichern des Trainingsplans', 'Fehler')
      result = false;
    }
    return result;
  }

  async updateTrainingPlanEndDateInMetadata(user: User) {
    console.log('Update training plan end date metadata')
    var maxTrainingPlanEndDate: Date = undefined
    user.trainingPlans.forEach(plan => {
      if (plan.deleted) return
      if (plan.endDate) {
        if (maxTrainingPlanEndDate === undefined || maxTrainingPlanEndDate && plan.endDate.isSameOrAfterDate(maxTrainingPlanEndDate)) {
          maxTrainingPlanEndDate = plan.endDate
        }
      } else {
        if (environment.firebaseProjectId != 'traindoo-app') maxTrainingPlanEndDate = null
      }
    })
    console.log(maxTrainingPlanEndDate)
    if (!user.metadata || !user.metadata.trainingPlanEndDate || maxTrainingPlanEndDate && !maxTrainingPlanEndDate.isSameDate(user.metadata.trainingPlanEndDate)) {
      if (!user.metadata) user.metadata = new Metadata()
      user.metadata.trainingPlanEndDate = maxTrainingPlanEndDate || null
      await this.updateMetadata(user)
    }
  }

  getTrainingPlanThumbnailPath(trainingPlan: TrainingPlan) {
    return "trainingplans/" + trainingPlan.id + "/" + "thumbnail_" + FirestoreNutritionPlanService.generateUniqueString() + ".png";
  }

  async uploadTrainingPlanThumbnailImage(thumbnailImage: File, trainingPlan: TrainingPlan) {
    await this.fireStorage.ref(trainingPlan.imagePath).put(thumbnailImage)
    await this.fireStorage.ref(trainingPlan.imagePath).getDownloadURL().toPromise().then((link) => {
      trainingPlan.imageDownloadURL = link;
    }).catch(ex => console.log(ex));
  }

  removeTrainingPlanThumbnail(trainingPlan: TrainingPlan) {
    trainingPlan.imagePath = null;
    trainingPlan.imageDownloadURL = null;
  }

  async updateTrainingPlan(user: User, coach: User, trainingPlan: TrainingPlan, removeThumbnail: boolean, thumbnailImage?: File, progressBehaviorSubject: BehaviorSubject<string> = null){
    let progress = 0;
    let oldTrainingPlan = user.trainingPlans.find(x => x.id == trainingPlan.id);
    if(removeThumbnail) {
      this.removeTrainingPlanThumbnail(trainingPlan);
    }
    if(thumbnailImage){
      trainingPlan.imagePath = this.getTrainingPlanThumbnailPath(trainingPlan);
      await this.uploadTrainingPlanThumbnailImage(thumbnailImage, trainingPlan);
    }
    progress = 0.1;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
    if(!trainingPlan.coachUid && !trainingPlan.licenceHolderUid){
      trainingPlan.coachUid = coach.uid;
      trainingPlan.licenceHolderUid = coach.licenceHolderUid;
    }
    console.log('Write TrainingPlan')
    await this.firestore.collection('Users/' + user.uid + '/TrainingPlans/').doc(trainingPlan.id).update(this.convertTrainingPlanToFirebaseData(trainingPlan))

    progress = 0.2;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
    let promises: Promise<any>[] = []
    for (let index = 0; index < trainingPlan.sessions.length; index++) {
      const session = trainingPlan.sessions[index];
      let oldSession = oldTrainingPlan?.sessions?.find(x => x.id == session.id);
      // Only count non-deleted exercises for position attribute of exercise.
      var exerciseCount = 0
      for (let i = 0; i < session.exercises.length; i++){
        let exercise = session.exercises[i]
        if (exercise.id?.length > 0) {
          exercise.position = exerciseCount;
          if(oldSession != null){
            let oldExercise = oldSession.exercises?.find(x => x.id == exercise.id);
            if(oldExercise.isSame(exercise)){
              if (!exercise.deleted) exerciseCount++
              continue;
            }
          }
          console.log('changed Exercise: ' + exercise.id)
          promises.push(this.firestore.collection('Users/' + user.uid + '/PlannedTrainingExercises/').doc(exercise.id).update(this.convertExerciseToFirebaseData(exerciseCount, session, exercise)))
        } else {
          promises.push(this.firestore.collection('Users/' + user.uid + '/PlannedTrainingExercises/').add(this.convertExerciseToFirebaseData(exerciseCount, session, exercise)).then((res) => { exercise.id = res.id }))
        }
        if (!exercise.deleted) exerciseCount++
      }
    }
    progress = 0.3;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
    await Promise.all(promises);

    progress = 0.7;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
    if(!trainingPlan.isTemplate){
      await this.setNotExistingUserTrainingVariables(user, trainingPlan.trainingVariables);
    }
    progress = 0.8;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)

    await this.setNotExistingGlobalTrainingVariables(trainingPlan.trainingVariables);

    progress = 0.9;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)

    if(user.trainingPlans.find(x => x.id == trainingPlan.id) == null){
      user.trainingPlans.push(trainingPlan);
      if (environment.firebaseProjectId == 'traindoo-app') {
        user.trainingPlans = user.trainingPlans.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
      } else {
        user.trainingPlans = user.trainingPlans.sort((a, b) => a.startDate.getTime() - b.startDate.getTime()).sort(val => {return !val.isDeprecated ? -1 : 1});
      }
    }
    else {
      user.trainingPlans = user.trainingPlans.map(x => x.id == trainingPlan.id ? trainingPlan?.clone() : x)
    }
    await this.loadTrainingPlans(user, coach, true)

    progress = 0.95;
    progressBehaviorSubject?.next(`${(progress * 100).toFixed()}%`)
  }

  async updateTrainingPlanEndDate(user: User, trainingPlan: TrainingPlan) {
    await this.firestore.collection('Users/' + user.uid + '/TrainingPlans/').doc(trainingPlan.id).update({endDate: trainingPlan.endDate, timestamp: new Date()})
  }

  private convertExerciseToFirebaseData(position: number, session: TrainingSession, exercise: PlannedTrainingExercise):unknown{
    var map = {
      timestamp: Timestamp.now(),
      deleted: exercise.deleted,
      exerciseId: exercise.exerciseId,
      note: exercise.note,
      position: position,
      sessionId: session.id,
      sets: exercise.sets.map(x => x.asMap()),
      pauseDuration: exercise.pauseDuration ?? null,
      connectedSuperSetExercise: exercise.connectedSuperSetExercise || false,
      superSetConfig: exercise.superSetConfig?.asFirebaseMap() || null,
      setParameters: exercise.setParameters.map(x => x.toString()),
      groupHeading: exercise.groupHeading,
      groupHeadingTranslation: exercise.groupHeadingTranslation?.AsMap() || null,
      videoRecordingRequest: exercise.videoRecordingRequest?.asMap(),
      autoRun: exercise.autoRun,
      alternativeExerciseId: exercise.alternativeExerciseId,
      noteUnit: exercise.noteUnit,
    }
    if (exercise.exertionUnit != null && exercise.exertionUnit != undefined) {
      map['exertionUnit'] = exercise.exertionUnit
    }
    return map
  }

  private convertTrainingPlanToFirebaseData(trainingPlan: TrainingPlan):unknown{
    return {
      timestamp: Timestamp.now(),
      deleted: trainingPlan.deleted,
      name: trainingPlan.name,
      nameTranslation: trainingPlan.nameTranslation.AsMap(),
      startDate: trainingPlan.startDate,
      startDateString: trainingPlan.startDateString,
      endDate: trainingPlan.endDate,
      endDateString: trainingPlan.endDateString,
      licenceHolderUid: trainingPlan.licenceHolderUid || null,
      coachUid: trainingPlan.coachUid || null,
      sessions: trainingPlan.sessions.map(x => x.asFirebaseMap()),
      hintTranslation: trainingPlan.hintTranslation.AsMap(),
      subNameTranslation: trainingPlan.subNameTranslation.AsMap(),
      imagePath: trainingPlan.imagePath,
      userEditable: trainingPlan.userEditable,
      isPeriodicPlan: trainingPlan.isPeriodicPlan,
      trainingVariables: trainingPlan.trainingVariables.map(x => x.asMap()),
      weeks: trainingPlan.weeks.map(x => x.asMap()),
    }
  }

  public async getTrackedTrainingSessionById(user: User, sessionId: string): Promise<TrackedTrainingSession> {
    let trackedTrainingSession = await this.getTrackedTrainingSessionByIdWithoutExercises(user, sessionId)
    trackedTrainingSession.trackedTrainingExercises = await this.getTrackedTrainingExercisesBySessionId(user, sessionId)
    if(trackedTrainingSession.deleted) return null;
    return trackedTrainingSession;
  }

  public async getTrackedTrainingExercisesBySessionId(user: User, sessionId: string): Promise<TrackedTrainingExercise[]> {
    var documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').ref.where('trackedSessionId', '==', sessionId).where('completed', '==', true).get()
    var exercises: TrackedTrainingExercise[] = []
    for(let document of documents.docs){
      var exercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
      if(!exercise.deleted) {
        exercise.id = document.id
        if (document.data().startDate) exercise.startDate = new Date(document.data().startDate.seconds * 1000)
        for(let recording of exercise.recordings){
          await this.loadTrackedVideoRecordingUrl(recording, user.uid)
        }
        exercises.push(exercise)
      }
    }
    return exercises;
  }

  public async getTrackedTrainingSessionByIdWithoutExercises(user: User, sessionId: string): Promise<TrackedTrainingSession> {
    var document = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingSessions/').doc(sessionId).ref.get();
    let trackedTrainingSession = new TrackedTrainingSession(document.data() as TrackedTrainingSession)
    trackedTrainingSession.id = document.id
    if (document.data().startDate) trackedTrainingSession.startDate = new Date(document.data().startDate.seconds * 1000)
    if (document.data().endDate) trackedTrainingSession.endDate = new Date(document.data().endDate.seconds * 1000)
    return trackedTrainingSession;
  }

  public async getPreviousTrackedTrainingExercise(user: User, exerciseId: string, startDate: Date): Promise<TrackedTrainingExercise> {
    var documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').ref.where('exerciseId', '==', exerciseId).where('startDate', '<', startDate).orderBy('startDate', 'desc').limit(7).get()
    if (documents.docs?.length > 0) {
      for(let document of documents.docs) {
        var trackedExercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
        trackedExercise.id = document.id
        if(trackedExercise.deleted){
          continue;
        }
        if (document.data().startDate) trackedExercise.startDate = new Date(document.data().startDate.seconds * 1000)
        for(let recording of trackedExercise.recordings){
          await this.loadTrackedVideoRecordingUrl(recording, user.uid)
        }
        return trackedExercise
      }
    }
    return null
  }


  public async getNextTrackedTrainingExercise(user: User, exerciseId: string, startDate: Date): Promise<TrackedTrainingExercise> {
    var documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').ref.where('exerciseId', '==', exerciseId).where('startDate', '>', startDate).orderBy('startDate', 'asc').limit(7).get()
    if (documents.docs?.length > 0) {
      for(let document of documents.docs) {
        var trackedExercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
        trackedExercise.id = document.id
        if(trackedExercise.deleted){
          continue;
        }
        if (document.data().startDate) trackedExercise.startDate = new Date(document.data().startDate.seconds * 1000)
        for(let recording of trackedExercise.recordings){
          await this.loadTrackedVideoRecordingUrl(recording, user.uid)
        }
        return trackedExercise
      }
    }
    return null
  }


  public async getTrackedTrainingSessionsByPlannedSessionId(user: User, plannedSessionId: string, limit: number = null): Promise<TrackedTrainingSession[]> {
    let query = this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingSessions/').ref.where('plannedSessionId', '==', plannedSessionId);
    if(limit) query = query.limit(limit);
    let documents = await query.get()

    var sessions: TrackedTrainingSession[] = []
    for(let document of documents.docs){
      var session = new TrackedTrainingSession(document.data() as TrackedTrainingSession)
      if(!session.deleted) {
        session.id = document.id
        if (document.data().startDate) session.startDate = new Date(document.data().startDate.seconds * 1000)
        if (document.data().endDate) session.endDate = new Date(document.data().endDate.seconds * 1000)
        sessions.push(session)
      }
    }
    return sessions;
  }


  public async getPreviousTrackedTrainingExercisesByMultipleIds(user: User, exerciseIds: string[], startDate: Date): Promise<TrackedTrainingExercise[]> {
    if(!exerciseIds || exerciseIds.length == 0) return null;
    var documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').ref.where('exerciseId', 'in', exerciseIds).where('startDate', '<', startDate).orderBy('startDate', 'desc').limit(7).get();
    if (documents.docs?.length > 0) {
      let trackedExercises: TrackedTrainingExercise[] = []
      for(let document of documents.docs) {
        var trackedExercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
        trackedExercise.id = document.id
        if(trackedExercise.deleted){
          continue;
        }
        if (document.data().startDate) trackedExercise.startDate = new Date(document.data().startDate.seconds * 1000)
        for(let recording of trackedExercise.recordings){
          await this.loadTrackedVideoRecordingUrl(recording, user.uid)
        }
        trackedExercises.push(trackedExercise)
      }
      return trackedExercises;
    }
    return null;
  }

  public async getNextTrackedTrainingExercisesByMultipleIds(user: User, exerciseIds: string[], startDate: Date): Promise<TrackedTrainingExercise[]> {
    if(!exerciseIds || exerciseIds.length == 0) return null;
    var documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').ref.where('exerciseId', 'in', exerciseIds).where('startDate', '>', startDate).orderBy('startDate', 'asc').limit(7).get();
    if (documents.docs?.length > 0) {
      let trackedExercises: TrackedTrainingExercise[] = []
      for(let document of documents.docs) {
        var trackedExercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
        trackedExercise.id = document.id
        if(trackedExercise.deleted){
          continue;
        }
        if (document.data().startDate) trackedExercise.startDate = new Date(document.data().startDate.seconds * 1000)
        for(let recording of trackedExercise.recordings){
          await this.loadTrackedVideoRecordingUrl(recording, user.uid)
        }
        trackedExercises.push(trackedExercise)
      }
      return trackedExercises;
    }
    return null;
  }

  public async getTrackedTrainingSessionsWithExercises(user: User, fromDate: Date, toDate: Date): Promise<TrackedTrainingSession[]> {
    if (user.observableTrainingSessionLoader.getValue() == true) {
      await user.observableTrainingSessionLoader.toPromise()
    }

    if (!user.trackedTrainingSessions || fromDate < user.trainingSessionQueryStartDate || toDate > user.trainingSessionQueryEndDate) {
      user.observableTrainingSessionLoader.next(true)
      var startDate = fromDate.clone() //user.trainingSessionQueryStartDate && user.trainingSessionQueryStartDate < fromDate ? user.trainingSessionQueryStartDate : fromDate
      var endDate = toDate.clone() // user.trainingSessionQueryEndDate && user.trainingSessionQueryEndDate > toDate ? user.trainingSessionQueryEndDate : toDate

      var sessionsMap: Map<string, TrackedTrainingSession> = new Map()
      var documents = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingSessions/').ref.where('startDate', '>=', startDate).where('startDate', '<=', endDate).get()
      documents.docs.forEach(document => {
        if (document.data()?.deleted == true) return
        var session = new TrackedTrainingSession(document.data() as TrackedTrainingSession)
        session.id = document.id
        if (document.data().startDate) session.startDate = new Date(document.data().startDate.seconds * 1000)
        if (document.data().endDate) session.endDate = new Date(document.data().endDate.seconds * 1000)
        sessionsMap.set(session.id, session)
      });

      var exercises = await this.getTrackedTrainingExercises(user.uid, fromDate, toDate)
      for (var exercise of exercises) {
        if (sessionsMap.has(exercise.trackedSessionId)) {
          if (!sessionsMap.get(exercise.trackedSessionId).trackedTrainingExercises) sessionsMap.get(exercise.trackedSessionId).trackedTrainingExercises = []
          sessionsMap.get(exercise.trackedSessionId).trackedTrainingExercises.push(exercise)
        }
      }

      if (!user.trackedTrainingSessions) user.trackedTrainingSessions = []
      sessionsMap.forEach((value: TrackedTrainingSession, key: string) => {
        if (user.trackedTrainingSessions.filter(v => v.id == value.id).shift() == null) user.trackedTrainingSessions.push(value)
      })

      user.trainingSessionQueryStartDate = startDate
      user.trainingSessionQueryEndDate = endDate
      user.observableTrainingSessionLoader.next(false)
      user.observableTrainingSessionLoader.complete()
      user.observableTrainingSessionLoader = new BehaviorSubject(false)
    }

    return user.trackedTrainingSessions.filter(session => session.startDate.isSameOrAfterDate(fromDate) && session.startDate.isSameOrBeforeDate(toDate))
  }

  public async getTrackedTrainingExercises(userUid: string, fromDate: Date, toDate: Date): Promise<TrackedTrainingExercise[]> {
    var documents = await this.firestore.collection<any>('Users/' + userUid + '/TrackedTrainingExercises/').ref.where('startDate', '>=', fromDate).where('startDate', '<=', toDate).get()
    var trackedExercises: TrackedTrainingExercise[] = []
    documents.docs.forEach(document => {
      var exercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
      if(!exercise.deleted) {
        exercise.id = document.id
        if (document.data().startDate) exercise.startDate = new Date(document.data().startDate.seconds * 1000)
        trackedExercises.push(exercise)
      }
    })
    trackedExercises.sort((b1, b2) => (b1.position || 0) - (b2.position || 0));
    return trackedExercises;
  }

  async markTrackedTrainingSessionAsViewed(session: TrackedTrainingSession, user: User) {
    session.viewedByCoach = true
    try {
      await this.firestore.collection('Users/' + user.uid + '/TrackedTrainingSessions/').doc(session.id).update({
        viewedByCoach: true
      })
    } catch(ex) {
      console.log(ex)
    }
  }

  public async getTrackedTrainingExercisesByExerciseId(userUid: string, exerciseId: string, fromDate: Date, toDate: Date): Promise<TrackedTrainingExercise[]> {
    var documents = await this.firestore.collection<any>('Users/' + userUid + '/TrackedTrainingExercises/').ref.where('exerciseId', '==', exerciseId).where('startDate', '>=', fromDate).where('startDate', '<=', toDate).get()
    var trackedExercises: TrackedTrainingExercise[] = []
    documents.docs.forEach(document => {
      var exercise = new TrackedTrainingExercise(document.data() as TrackedTrainingExercise)
      if(!exercise.deleted) {
        exercise.id = document.id
        if (document.data().startDate) exercise.startDate = new Date(document.data().startDate.seconds * 1000)
        trackedExercises.push(exercise)
      }
    })
    trackedExercises.sort((b1, b2) => (b1.position || 0) - (b2.position || 0));
    return trackedExercises;
  }

  public async updateExerciseRecordingFeedback(userUid: string, trackedExercise: TrackedTrainingExercise): Promise<void> {
    let data = trackedExercise.recordings.map(x => x.asMap());
    return await this.firestore.collection('Users/' + userUid + '/TrackedTrainingExercises/').doc(trackedExercise.id).update( {recordings: data, timestamp: new Date()} )
  }
  public async markExerciseRecordingAsViewed(userUid: string, trackedExercise: TrackedTrainingExercise): Promise<void> {
    let data = trackedExercise.recordings.map(x => x.asMap());
    return await this.firestore.collection('Users/' + userUid + '/TrackedTrainingExercises/').doc(trackedExercise.id).update( {recordings: data} )
  }

  public async loadTrackedVideoRecordingUrl(recording: TrackedVideoRecording, userUid: string) {
    try{
      if (recording.videoPath?.length > 0) {
        recording.videoUrl = await this.fireStorage.ref('users/' + userUid + '/' + recording.videoPath).getDownloadURL().toPromise();
      }
      if (recording.thumbnailPath?.length > 0) {
        recording.videoUrl = await this.fireStorage.ref('users/' + userUid + '/' + recording.thumbnailPath).getDownloadURL().toPromise();
      }
    } catch(ex){
      console.error(ex);
    }
  }
  private exerciseRecordingsPromiseMap = new Map<string, Promise<TrackedVideoRecording>>()

  public loadTrackedVideoRecordingUrls(recording: TrackedVideoRecording, userUid: string) {
    if (recording.videoPath?.length > 0 || recording.thumbnailPath?.length > 0) {
      if (recording.urlsLoaded) return new Promise<TrackedVideoRecording>((resolve, reject) => resolve(recording))
      if (this.exerciseRecordingsPromiseMap.has(recording.tmpId)) {
        return this.exerciseRecordingsPromiseMap.get(recording.tmpId).then((res) => {
          recording.videoUrl = res.videoUrl
          recording.thumbnailUrl = res.thumbnailUrl
          recording.urlsLoaded = true
          return recording
        })
      }
      var promise: Promise<TrackedVideoRecording> = new Promise(async (resolve, reject) => {
        if (recording.videoPath?.length > 0) {
           firstValueFrom(this.fireStorage.ref('users/' + userUid + '/' + recording.videoPath).getDownloadURL()).then((link) => {
            recording.videoUrl = link
            recording.urlsLoaded = true
          }).catch();
        }
        if (recording.thumbnailPath?.length > 0) {
           firstValueFrom(this.fireStorage.ref('users/' + userUid + '/' + recording.thumbnailPath).getDownloadURL()).then((link) => {
            recording.thumbnailUrl = link
          }).catch();
        }
        resolve(recording)
      });
      this.exerciseRecordingsPromiseMap.set(recording.tmpId, promise)
      return promise;
    }
    return null

  }

  sendInvitationEmail(recipientAddress: String, recipientName: String | null, heading: String, content: String, link: String | null, buttonText: String | null, subject: String | null, imageUrl: String | null, replyToEmail: String | null, licenceHolderUid: String | null): Observable<any> {
    const callable = this.firebaseFunctions.httpsCallable('sendInvitationEmail')
    return callable({ licenceHolderUid: licenceHolderUid, recipientAddress: recipientAddress, recipientName: recipientName?.length > 0 ? recipientName : null, heading: heading, content: content, link: link, buttonLink: link, buttonText: buttonText, subject: subject, imageUrl: imageUrl, replyToEmail: replyToEmail })
  }

  async getEmailForUser(uid: string): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('getEmailForUser')
    var result = await firstValueFrom(callable({ uid: uid }))
    if (result.success) return result.email
    return null
  }
  async updateEmailOfUser(oldEmail: string, newEmail: string): Promise<any> {
    const callable = this.firebaseFunctions.httpsCallable('updateEmailOfUser')
    var result = await firstValueFrom(callable({ oldEmail: oldEmail, newEmail: newEmail }))
    return result
  }

  public async loadNutritionStatisticsForDateRange(user: User, startDate: Date, endDate: Date): Promise<NutritionStatisticsItem[]> {
    var statistics = []
    var timeRange = (endDate.getTime() - startDate.getTime()) / (24*60*60*1000)

    var currentOffset = (startDate.getTimezoneOffset() * 60000 * -1)
    var userOffset = user.getDailyConditionForDate(startDate)?.timezoneOffset
    var timezoneOffset = 0
    if (userOffset != null && userOffset != currentOffset) {
      timezoneOffset = currentOffset - userOffset
      startDate = new Date(startDate.getTime() + timezoneOffset)
      endDate = new Date(endDate.getTime() + timezoneOffset)
    }

    var meals = await this.getAllMealsForDateRange(user, startDate, endDate, false, true, timezoneOffset).toPromise() //loadFoods changed to true because of micro statistic
    var activities = await this.getAllActivitiesByDateRange(user, startDate, endDate).toPromise()


    for (var i = 0; i <= timeRange; i++) {

      var date = new Date(startDate.getTime() + (timezoneOffset ?? 0))
      date.setDate(date.getDate() + i)
      var statistic = new NutritionStatisticsItem()
      statistic.date = date

      var dailyCondition = user.getDailyConditionForDate(date)

      var nutritionalGoal = new NutritionalGoal()

      var overwriteGoal = user.getCycleConfigForDate(date)?.getNutritionalGoalForDateAndSituation(date, dailyCondition?.selectedSituationId ?? null)
      if (overwriteGoal) {
        nutritionalGoal.calories = overwriteGoal.getCalories()
        nutritionalGoal.carbohydrates = overwriteGoal.getCarbohydrates()
        nutritionalGoal.protein = overwriteGoal.getProtein()
        nutritionalGoal.fat = overwriteGoal.getFat()
        nutritionalGoal.adjustWithActivityCalories = overwriteGoal.adjustWithActivityCalories
      } else {
        nutritionalGoal = await this.getLatestNutritionalGoalByDate(user, date).toPromise()
      }

      var activitiesOfDay = []
      activities.forEach(a => {
        if (a.date.isSameDateTimeZoneSensitive(date)) {
          activitiesOfDay.push(a)
        }
      })
      statistic.activities = activitiesOfDay

      if ((nutritionalGoal.adjustWithActivityCalories != null && nutritionalGoal.adjustWithActivityCalories == true) || (nutritionalGoal.adjustWithActivityCalories == null && user.adjustNutritionalGoalWithActivities)) {
        var caloriesBurned = 0
        activitiesOfDay.forEach(activity => {
            caloriesBurned = caloriesBurned + activity.caloriesBurned
        })
        nutritionalGoal.carbohydrates += nutritionalGoal.carbohydrates / nutritionalGoal.calories * caloriesBurned
        nutritionalGoal.protein += nutritionalGoal.protein / nutritionalGoal.calories * caloriesBurned
        nutritionalGoal.fat += nutritionalGoal.fat / nutritionalGoal.calories * caloriesBurned
        nutritionalGoal.calories += caloriesBurned
        nutritionalGoal.carbohydrates = Math.floor(nutritionalGoal.carbohydrates)
        nutritionalGoal.protein = Math.floor(nutritionalGoal.protein)
        nutritionalGoal.fat = Math.floor(nutritionalGoal.fat)
        nutritionalGoal.calories = Math.floor(nutritionalGoal.calories)
      }

      statistic.nutritionalGoal = nutritionalGoal

      var nutritionalSummary = new NutritionalSummary()
      statistic.nutritionalSummary = nutritionalSummary

      statistic.carbohydrates = 0
      statistic.protein = 0
      statistic.fat = 0
      statistic.carbohydratesGoal = nutritionalGoal.carbohydrates
      statistic.proteinGoal = nutritionalGoal.protein
      statistic.fatGoal = nutritionalGoal.fat
      statistic.meals = meals

      statistic.sugar = 0
      statistic.saturatedFat = 0
      statistic.fibre = 0
      statistic.salt = 0

      if (nutritionalSummary.calories >= nutritionalGoal.calories) {
        statistic.calories = 0
        statistic.calorieGoal = nutritionalGoal.calories
        statistic.calorieDeficit = 0;
        statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
      } else {
        statistic.calories = nutritionalSummary.calories
        statistic.calorieGoal = 0
        statistic.calorieSurplus = 0
        statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
      }

      var mealsOfDay = []
      meals.forEach(m => {
        if (m.date.isSameDateTimeZoneSensitive(date)) {
          mealsOfDay.push(m)
        }
      })

      mealsOfDay.forEach(meal => {
        nutritionalSummary.carbohydrates = nutritionalSummary.carbohydrates + meal.getCarbohydrates()
        nutritionalSummary.protein = nutritionalSummary.protein + meal.getProtein()
        nutritionalSummary.fat = nutritionalSummary.fat + meal.getFat()
        nutritionalSummary.calories = nutritionalSummary.calories + meal.getCalories()

        nutritionalSummary.sugar = nutritionalSummary.sugar + meal.getSugar();
        nutritionalSummary.saturatedFat = nutritionalSummary.saturatedFat + meal.getSaturatedFat();
        nutritionalSummary.fibre = nutritionalSummary.fibre + meal.getFibre();
        nutritionalSummary.salt = nutritionalSummary.salt + meal.getSalt();

        if (nutritionalSummary.calories >= nutritionalGoal.calories) {
          statistic.calories = 0
          statistic.calorieGoal = nutritionalGoal.calories
          statistic.calorieDeficit = 0;
          statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
        } else {
          statistic.calories = nutritionalSummary.calories
          statistic.calorieGoal = 0
          statistic.calorieSurplus = 0
          statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
        }
        statistic.carbohydrates = nutritionalSummary.carbohydrates
        statistic.protein = nutritionalSummary.protein
        statistic.fat = nutritionalSummary.fat

        statistic.sugar = nutritionalSummary.sugar
        statistic.saturatedFat = nutritionalSummary.saturatedFat
        statistic.fibre = nutritionalSummary.fibre
        statistic.salt = nutritionalSummary.salt
      })

      statistics.push(statistic)

      /*var statisticPromise = this.loadStatisticForUserAndDate(this.displayedUser, date).toPromise().then(statistic => {
        statistics.push(statistic)
      })
      promises.push(statisticPromise)*/
    }

    return statistics
  }
}
