import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-library-mobile-navigation',
  templateUrl: './library-mobile-navigation.component.html',
  styleUrls: ['./library-mobile-navigation.component.css']
})
export class LibraryMobileNavigationComponent {

  currentRoute: string

  constructor(private router: Router) {
    this.currentRoute = router.url
    console.log(this.currentRoute)
  }
}
