<div class="textalign-center">
    <div class="client-sub-navigation">
        <ul>
            <li class="nav-item sub-item {{currentRoute == '/training' ? 'active' : ''}}">
                <button class="menuitem-button" routerLink="/training">
                    <div class="icon">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_72_4122)">
                            <path d="M22 12.05H23.5M9.25 12.05H15.25M1 12.05H2.5M19 7.55005H21.25C21.4489 7.55005 21.6397 7.62907 21.7803 7.76972C21.921 7.91037 22 8.10114 22 8.30005V15.8C22 15.999 21.921 16.1897 21.7803 16.3304C21.6397 16.471 21.4489 16.55 21.25 16.55H19M5.5 16.55H3.25C3.05109 16.55 2.86032 16.471 2.71967 16.3304C2.57902 16.1897 2.5 15.999 2.5 15.8V8.30005C2.5 8.10114 2.57902 7.91037 2.71967 7.76972C2.86032 7.62907 3.05109 7.55005 3.25 7.55005H5.5M16 5.30005H18.25C18.6642 5.30005 19 5.63584 19 6.05005V18.05C19 18.4643 18.6642 18.8 18.25 18.8H16C15.5858 18.8 15.25 18.4643 15.25 18.05V6.05005C15.25 5.63584 15.5858 5.30005 16 5.30005ZM6.25 5.30005H8.5C8.91421 5.30005 9.25 5.63584 9.25 6.05005V18.05C9.25 18.4643 8.91421 18.8 8.5 18.8H6.25C5.83579 18.8 5.5 18.4643 5.5 18.05V6.05005C5.5 5.63584 5.83579 5.30005 6.25 5.30005Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_72_4122">
                            <rect width="24" height="24" fill="white" transform="translate(0 0.300049)"/>
                            </clipPath>
                            </defs>
                        </svg>    
                    </div>                 
                </button>
            </li>
            <li class="nav-item sub-item {{currentRoute == '/recipes' ? 'active' : ''}}">
                <button class="menuitem-button" routerLink="/recipes">
                    <div class="icon">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.875 8.875V19M4.875 8.875C5.77011 8.875 6.62855 8.51942 7.26149 7.88649C7.89442 7.25355 8.25 6.39511 8.25 5.5L7.5 1M4.875 8.875C3.97989 8.875 3.12145 8.51942 2.48851 7.88649C1.85558 7.25355 1.5 6.39511 1.5 5.5L2.25 1M16.125 13H10.875C10.875 13 12 2.5 16.125 1V19M4.875 1V4.75" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </button>
            </li>
            <li class="nav-item sub-item {{currentRoute?.includes('/tools/') ? 'active' : ''}}">
                <button class="menuitem-button" routerLink="/tools">
                    <div class="icon">
                        <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 13H11.5M5.5 10H11.5M11.5002 2.5H15.25C15.4489 2.5 15.6397 2.57902 15.7803 2.71967C15.921 2.86032 16 3.05109 16 3.25V19C16 19.1989 15.921 19.3897 15.7803 19.5303C15.6397 19.671 15.4489 19.75 15.25 19.75H1.75C1.55109 19.75 1.36032 19.671 1.21967 19.5303C1.07902 19.3897 1 19.1989 1 19V3.25C1 3.05109 1.07902 2.86032 1.21967 2.71967C1.36032 2.57902 1.55109 2.5 1.75 2.5H5.4998M4.75 5.5V4.75C4.75 3.75544 5.14509 2.80161 5.84835 2.09835C6.55161 1.39509 7.50544 1 8.5 1C9.49456 1 10.4484 1.39509 11.1517 2.09835C11.8549 2.80161 12.25 3.75544 12.25 4.75V5.5H4.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </button>
            </li>
        </ul>
    </div>
    <div class="spacer-10"></div>
</div>