<div *ngIf="displayedUser" class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-9 col-lg-6 col-xl-8 col-xxl-4 order-1 order-xxl-1 profile">
                <div class="profile-container">
                    <div class="profile-picture">
                        <img draggable="false" *ngIf="displayedUser?.profilePictureUrl" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{displayedUser?.profilePictureUrl}}">
                        <div *ngIf="!displayedUser?.profilePictureUrl">
                            <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (userService.getProfilePictureForUser(displayedUser) | async) }}">
                        </div>
                      </div>
                    <div class="profile-text {{displayedUser.hasOtherLanguageSettings() ? ' has-languagesettings' : ''}}">
                        <h3 class="profile-name">{{displayedUser.getName()}}</h3>
                        <div *ngIf="displayedUser.hasOtherLanguageSettings() || displayedUser.currentTimezoneOffsetString != null" class="localization-container">
                            <div *ngIf="displayedUser.hasOtherLanguageSettings()" tooltip="Dein Coachee hat eine andere App-Sprache eingestellt."><img class="language-flag" src="assets/icons/icon-united-kingdom.png" alt=""></div>
                            <div *ngIf="displayedUser.currentTimezoneOffsetString != null" tooltip="Die Zeitzone deines Coachees weicht von deiner Zeitzone um {{displayedUser.currentTimezoneOffsetString}} Stunden ab. Bei {{displayedUser?.getName()}} ist aktuell {{displayedUser.getCurrentClientTime() | date:'HH:mm'}} Uhr.">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                                    <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <button *ngIf="userService.getAccessibleClients()?.length > 0" class="button change-client p-0" id="dropdownClients" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownClients">
                    <div *ngFor="let client of userService.getAccessibleClients()">
                        <button class="dropdown-item {{displayedUser?.uid == client?.uid ? 'selected' : ''}}" type="button" routerLink="/client/{{client?.uid}}">{{client.getName()}}</button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-12 col-xxl-4 order-3 order-xxl-2">
                <div class="div textalign-center">
                    <div class="spacer-10"></div>
                    <button class="{{progress}}" (click)="swapTab('progress')">
                        Fortschritt
                        <div class="underline"></div>
                    </button>
                    <button class="{{meals}}" (click)="swapTab('meals')">
                        Tagebuch
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="userService.getLoggedInUser().isCoach && displayedUser.licence" class="{{settings}}" (click)="swapTab('settings')">
                        Planung
                        <div class="underline"></div>
                    </button>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-4 col-xxl-4 order-2 order-xxl-3" style="z-index: 1;" [ngClass]="{'mobile-small-info-buttons justify-content-center' : utilityService?.onSmallDisplay()}">
                <div *ngIf="showMeals" class="toggle-wrapper on-background icon-toggle d-flex float-right order-last h-100">
                    <div class="toggle margin-vertical-auto {{editUserDataService.isEditModeActivated ? 'on' : ''}}" (click)="switchEditMode()">
                        <div class="handle"></div>
                        <div class="toggle-icon left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                            </svg>
                        </div>
                        <div class="toggle-icon right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div *ngIf="userService.getLoggedInUser().isCoach" class="icon-button marginleft-25 float-right h-100" tooltip="Chat" [ngClass]="{'order-3' : utilityService?.onSmallDisplay()}" (click)="chatService.onOpenChatForUid(displayedUser?.uid)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-text h-100" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                    <ng-container *ngIf="!utilityService?.onSmallDisplay()">
                        Chat
                    </ng-container>
                </div>
                <div *ngIf="userService.getLoggedInUser().isCoach" class="icon-button marginleft-25 float-right h-100" tooltip="Notizen" [ngClass]="{'order-2' : utilityService?.onSmallDisplay()}" (click)="toggleNotesDialog()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sticky h-100" viewBox="0 0 16 16">
                        <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"/>
                    </svg>
                    <ng-container *ngIf="!utilityService?.onSmallDisplay()">
                        Notizen
                    </ng-container>
                </div>
                <div *ngIf="userService.getLoggedInUser().isCoach" class="icon-button marginleft-25 float-right h-100" tooltip="Dateiablage" [ngClass]="{'order-1' : utilityService?.onSmallDisplay()}" (click)="openFileSharing()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder h-100" viewBox="0 0 16 16">
                        <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                    </svg>
                    <ng-container *ngIf="!utilityService?.onSmallDisplay()">
                        Dateiablage
                    </ng-container>
                </div>
                <div *ngIf="userService.getLoggedInUser().isCoach" class="icon-button float-right h-100" tooltip="Kundendaten" [ngClass]="{'order-first' : utilityService?.onSmallDisplay()}" (click)="toggleClientDataDialog()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person h-100" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                    </svg>
                    <ng-container *ngIf="!utilityService?.onSmallDisplay()">
                        Kundendaten
                    </ng-container>
                </div>
                <div *ngIf="userService.getLoggedInUser().isCoach" id="clientdata-dialog" class="dialog-wrapper">
                    <div class="dialog layout-box">
                        <div class="dialog-topbar">
                            <div class="row">
                                <div class="col-auto">
                                    <h2 class="margin-0">Kundendaten</h2>
                                </div>
                                <div class="col toggle-wrapper on-background icon-toggle d-flex h-100 ml-0">
                                    <div class="toggle margin-vertical-auto {{isEditUserInformationActive ? 'on' : ''}}" (click)="switchUserDataEditMode()">
                                        <div class="handle"></div>
                                        <div class="toggle-icon left">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                                            </svg>
                                        </div>
                                        <div class="toggle-icon right">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <!--div class="col">
                                    <div class="col">
                                        <div class="icon-button" (click)="userService.createCustomToken(displayedUser.uid)">
                                            Token erstellen
                                        </div>
                                    </div>
                                </div-->
                                <div class="col-2">
                                    <div class="icon-button close float-right" (click)="toggleClientDataDialog()">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog-content-wrapper">
                            <div class="row">
                                <div class="col">
                                    <div class="row" *ngIf="!isEditUserInformationActive">
                                        <div class="col-8">Email:</div>
                                        <div class="col-4">{{userEmail}}</div>
                                        <div class="col-8">Aktuelles Gewicht:</div>
                                        <div class="col-4">{{displayedUser.latestBodyWeight?.toFixed(1) || displayedUser.bodyWeight?.toFixed(1)}} kg</div>
                                        <div class="col-8">Startgewicht:</div>
                                        <div class="col-4">{{displayedUser.firstBodyWeight?.toFixed(1) || displayedUser.bodyWeight?.toFixed(1)}} kg</div>
                                        <div class="col-8">Körpergröße:</div>
                                        <div class="col-4">{{displayedUser.bodyHeight.toFixed(0)}} cm</div>
                                        <ng-container *ngIf="displayedUser.birthDate">
                                            <div class="col-8">Geburtsdatum:</div>
                                            <div class="col-4">{{displayedUser.birthDate?.asFormatedString()}}</div>
                                        </ng-container>
                                        <div class="col-8">Alter:</div>
                                        <div class="col-4">{{displayedUser.age}} Jahre</div>
                                        <div class="col-8">Geschlecht:</div>
                                        <div class="col-4">{{displayedUser.getPrintableGender()}}</div>
                                        <div class="col-8">Aktivitätslevel (PAL-Wert):</div>
                                        <div class="col-4">{{displayedUser.getPrintablePhysicalActivityLevel()}}</div>
                                        <div class="col-4" *ngIf="displayedUser?.spikeProvider && displayedUser?.spikeUserId">Verbundenes Wearable: {{displayedUser?.spikeProvider}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="spacer-20"></div>
                                            <div class="bold">Gruppen:</div>
                                            <div class="groups-list">
                                                <div class="recipe-tags">
                                                    <ng-container *ngFor="let group of displayedUser.metadataUser?.assignedClientGroups">
                                                        <div class="chip link client-group" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                                            {{group}}
                                                        </div>
                                                        <mat-menu #menu="matMenu">
                                                            <button mat-menu-item (click)="userService.removeClientFromGroup(displayedUser, group)">Aus Gruppe entfernen</button>
                                                        </mat-menu>
                                                    </ng-container>

                                                    <div class="icon-button small" [matMenuTriggerFor]="addToGroup">
                                                        <i class="bi bi-plus"></i>
                                                    </div>
                                                    <mat-menu #addToGroup="matMenu">
                                                        <div class="limited-matmenu">
                                                            <ng-container *ngFor="let group of userService.getClientGroups()">
                                                                <button *ngIf="group != 'Alle' && !displayedUser.metadataUser?.assignedClientGroups.includes(group)" mat-menu-item (click)="userService.addClientToGroup(displayedUser, group)">{{group}}</button>
                                                            </ng-container>
                                                        </div>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                            <div class="spacer-20"></div>
                                        </div>
                                    </div>
                                    <div class="container row" *ngIf="isEditUserInformationActive && editableDisplayedUser != null">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-sm-6 col-md-8 d-flex align-items-center">Name:</div>
                                                <div class="col-sm-6 col-md-4">
                                                    <input class="wide-input" type="text" [(ngModel)]="editableDisplayedUser.name" required>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-6 col-md-8 d-flex align-items-center">Körpergröße:</div>
                                                <div class="col-sm-6 col-md-4">
                                                    <div class="row">
                                                        <div class="col">
                                                            <input class="wide-input" type="number" min="1" step="1" required [(ngModel)]="editableDisplayedUser.bodyHeight">
                                                        </div>
                                                        <div class="col-auto d-flex align-items-center">
                                                            cm
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-6 col-md-8 d-flex align-items-center">Geburtsdatum:</div>
                                                <div class="col-sm-6 col-md-4">
                                                    <mat-form-field class="example-full-width">
                                                        <input disabled matInput [matDatepicker]="picker" [(ngModel)]="editableDisplayedUser.birthDate" [max]="today">
                                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                                      </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-6 col-md-8 d-flex align-items-center">Geschlecht:</div>
                                                <div class="col-sm-6 col-md-4">
                                                    <div class="d-inline-block wrapper-statisticstype-selection">
                                                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownStatisticsGraphType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{editableDisplayedUser.getPrintableGender()}}</button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownStatisticsGraphType">
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setGender(GENDER_MALE)">Männlich</button>
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setGender(GENDER_FEMALE)">Weiblich</button>
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setGender(GENDER_DIV)">Divers</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-6 col-md-8 d-flex align-items-center">Aktivitätslevel (PAL-Wert):</div>
                                                <div class="col-sm-6 col-md-4">
                                                    <div class="d-inline-block wrapper-statisticstype-selection">
                                                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownStatisticsGraphType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{editableDisplayedUser.getPrintablePhysicalActivityLevel()}}</button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownStatisticsGraphType">
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setPhysicalActivityLevel(PHYSICAL_ACTIVITY_LEVEL_INACTIVE)">Inaktiv</button>
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setPhysicalActivityLevel(PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE)">Etwas aktiv</button>
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setPhysicalActivityLevel(PHYSICAL_ACTIVITY_LEVEL_ACTIVE)">Aktiv</button>
                                                            <button class="dropdown-item" type="button" (click)="editableDisplayedUser.setPhysicalActivityLevel(PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE)">Sehr aktiv</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col">
                                                    <button class="button-filled small" (click)="saveUserInformationChanges(displayedUser, editableDisplayedUser)">Speichern</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="userService.getLoggedInUser().isCoach" class="row">
                                <div class="spacer-10"></div>
                                <div class="col-12">
                                    <div class="icon-button small" (click)="openTrainingVariablesDialog()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                        </svg>
                                        Trainingsvariablen bearbeiten
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-auto">
                <div class="icon-button float-right" [ngClass]="{'order-last' : utilityService?.onSmallDisplay()}">
                    <div class="toggle-wrapper display-inlineblock">
                        <div class="toggle {{editUserDataService.isEditModeActivated ? 'on' : ''}}" (click)="switchEditMode()">
                            <div class="handle"></div>
                        </div>
                    </div>
                    Edit
                </div>
            </div> -->
            <div class="col-3 order-2 col-sm-0"></div>
        </div>
        
        <div class="spacer-10"></div>

        <div *ngIf="displayedUser?.bodyWeight == 0 && displayedUser?.operatingSystem == null" class="layout-box sub-box turquoise">
            Dein Coachee hat sich noch nicht in der App angemeldet, um zu starten.<br>
            Er hat eine Einladung per Email erhalten, um sich mit seinem bestehenden Account anzumelden. Du kannst die Email auch erneut versenden:
            <div class="spacer-10"></div>
            <button class="button-filled light small" (click)="resendInvitationEmail()">Einladung erneut senden</button>
        </div>

        <div *ngIf="showProgress">
            <div class="container-fluid padding-0">
                <div class="row">
                    <div class="col-12">
                        <app-body-data [selectedUserUid]="displayedUser?.uid"></app-body-data>
                    </div>
                </div>
            </div>
            <div class="container-fluid padding-0">
                <div class="row">
                    <div class="col-12">
                        <div class="layout-box adjust-mobile">
                            <app-nutrition-graph [User]="displayedUser"></app-nutrition-graph>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngIf="(user.isTrainingEnabled() || !userService.getLoggedInUser().isCoach)" class="container-fluid padding-0">
                <div class="row">
                    <div class="col-12 col-lg-6 order-2 order-lg-1">
                        <ng-container *ngIf="!displayedUser.loadedTrainingPlans; else trainingHistoryTemplate">
                            <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                        </ng-container>
                        <ng-template #trainingHistoryTemplate>
                            <app-training-history [User]="displayedUser"></app-training-history>
                        </ng-template>
                    </div>
                    <div class="col-12 col-lg-6 order-1 order-lg-2">
                        <div class="layout-box adjust-mobile">
                            <app-completed-questionaires-overview [user]="displayedUser" [relevantDailyConditions]="displayedUser.dailyConditions"></app-completed-questionaires-overview>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(userService.getLoggedInUser().isCoach && !user.isTrainingEnabled())" class="container-fluid padding-0">
                <div class="row">
                    <div class="col-12">
                        <div class="layout-box adjust-mobile">
                            <app-completed-questionaires-overview [user]="displayedUser" [relevantDailyConditions]="displayedUser.dailyConditions"></app-completed-questionaires-overview>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngIf="(user.isTrainingEnabled() || !userService.getLoggedInUser().isCoach)" class="container-fluid padding-0">
                <div class="row">
                    <div class="col-12">
                        <div class="layout-box adjust-mobile">
                            <h3>Trainingsstatistik</h3>
                            <app-training-graph [User]="displayedUser"></app-training-graph>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div class="spacer-50"></div>
        </div>

        <div *ngIf="showMeals">
            <app-diary [setDisplayedUser]="displayedUser"></app-diary>
            <div class="spacer-25"></div>
        </div>
        
        <div *ngIf="showChallenges && !(displayedUser?.bodyWeight == 0 && displayedUser?.operatingSystem == null)">
            <div class="switch-settings" *ngIf="showDeprecatedSettings && canAccessNewSettings() && !utilityService.onNativeMobileApp()">
                <div class="icon-button display-inlineblock marginleft-25" (click)="onToggleDeprecatedSettings()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                    &nbsp; Zu {{showDeprecatedSettings ? 'neuen' : 'alten'}} Einstellungen wechseln
                </div>
            </div>
            <div class="row" *ngIf="showDeprecatedSettings">

                <div class="col-12">
                    <div class="layout-box turquoise">
                        <h3>Wichtig! Bitte steige auf die neuen Einstellungen um!</h3>
                        In der neuen App-Version (Launch Mitte nächster Woche inkl. Training) werden die alten Nährstoffziele und Ernährungspläne nicht mehr unterstützt.<br>
                        Beim Wechsel über den Button oben links wird das aktuelle Nährstoffziel automatisch übertragen. Bei den Ernährungsplänen unten haben wir einen Button eingefügt, um auch den aktuellen Plan automatisch zu übertragen.<br>
                        Falls du keinen Button zum Wechseln der Einstellungen hast, sollte dein Coachee die nutrilize App aktualisieren.
                    </div>
                </div>
                
                <div class="col-12">
                    <div class="layout-box adjust-mobile">
                        <h3>Profil</h3>
                        <div class="container-fluid padding-0">
                            <div class="row">
                                <div class="col-12 col-md-7 col-xl-5">
                                    <div class="row">
                                        <div class="col-8">Aktuelles Gewicht:</div>
                                        <div class="col-4">{{displayedUser.latestBodyWeight?.toFixed(1) || displayedUser.bodyWeight?.toFixed(1)}} kg</div>
                                        <div class="col-8">Startgewicht:</div>
                                        <div class="col-4">{{displayedUser.firstBodyWeight?.toFixed(1) || displayedUser.bodyWeight?.toFixed(1)}} kg</div>
                                        <div class="col-8">Körpergröße:</div>
                                        <div class="col-4">{{displayedUser.bodyHeight.toFixed(0)}} cm</div>
                                        <div class="col-8">Alter:</div>
                                        <div class="col-4">{{displayedUser.age}} Jahre</div>
                                        <div class="col-8">Geschlecht:</div>
                                        <div class="col-4">{{displayedUser.getPrintableGender()}}</div>
                                        <div class="col-8">Aktivitätslevel (PAL-Wert):</div>
                                        <div class="col-4">{{displayedUser.getPrintablePhysicalActivityLevel()}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row {{nutritionPlanOpened ? 'nutritionplan-opened' : ''}}" *ngIf="!showDeprecatedSettings">
                <div class="spacer-25"></div>

                <div class="col-12">
                    <app-nutrition-plan [selectedUser]="displayedUser" [selectedCoach]="user" (openPlanEvent)="onNutritionPlanOpened($event)"></app-nutrition-plan>
                </div>

                <div class="col-12 metrics-area">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <app-metrics-assignment [selectedUser]="displayedUser" [selectedCoach]="user"></app-metrics-assignment>
                        </div>
                        <div class="col-12 col-lg-6">
                            <app-user-questionaires-overview [user]="displayedUser"></app-user-questionaires-overview>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 training-area">
                    <app-user-training-plan-overview *ngIf="user.isTrainingEnabled() && displayedUser?.versionCode > 142" [selectedUser]="displayedUser"></app-user-training-plan-overview>
                </div>

                <div class="spacer-50"></div>
            </div>
            
        </div>
    </div>
    <div *ngIf="userService.getLoggedInUser().isCoach" class="container">
        <div class="row">
            <div class="col">
                <chat>
                </chat>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>