import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-training-tab-page',
  templateUrl: './training-tab-page.component.html',
  styleUrls: ['./training-tab-page.component.css']
})
export class TrainingTabPageComponent implements OnInit {

  public environment = environment

  public showTrainingExercises = true
  public showTrainingPlanTemplates = false

  constructor(public utilityService: UtilityService) { }

  ngOnInit(): void {
  }

  resetTabs() {
    this.showTrainingExercises = false;
    this.showTrainingPlanTemplates = false;
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "showTrainingExercises":
        this.showTrainingExercises = true;
        break;
      case "showTrainingPlanTemplates":
        this.showTrainingPlanTemplates = true;
        break;
      default:
        break;
    }
  }

}
