import { LanguageService } from 'src/app/services/language.service';
import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { User } from 'src/app/model/user.model';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';
import { TrainingVariableEditorDialogType } from '../training-variable-editor-dialog/training-variable-editor-dialog.component';
import { SetParameter } from 'src/app/model/training-plan.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-cardio-zone-groups-editor',
  templateUrl: './cardio-zone-groups-editor.component.html',
  styleUrls: ['./cardio-zone-groups-editor.component.css']
})
export class CardioZoneGroupsEditorComponent {

  private hasChanges: boolean = false;
  public TrainingVariableEditorDialogType = TrainingVariableEditorDialogType;

  @Input() cardioZoneGroups: CardioZoneGroup[] = [];
  @Input() dialogType: TrainingVariableEditorDialogType;
  @Input() user: User;
  
  @Input() globalCardioZones: CardioZone[] = [];

  // @Input() isInputValid: Function(() => boolean);

  public get filteredCardioZoneGroups() {
    return this.cardioZoneGroups.filter(x => !x.deleted);
  }
  
  public rangePattern: string = "^[1-9][0-9]?[0-9]?(-[1-9][0-9]?[0-9]?)?$";
  public paceRangePattern: string = "^(((60|[0-5])?[0-9]):[0-5][0-9])(-((60|[0-5])?[0-9]):[0-5][0-9])?$";


  public exerciseDialogVisible: boolean = false;
  public editExerciseCardioZoneGroup: CardioZoneGroup = null;
  
  public selectedTrainingExercises: MergedTrainingExercise[] = [];

  private showExerciseDialog() {
    this.exerciseDialogVisible = true;
  }

  private hideExerciseDialog() {
    this.exerciseDialogVisible = false;
  }
  
  selectedTrainingExercisesDrop(event: CdkDragDrop<MergedTrainingExercise[]>) {
    moveItemInArray(this.selectedTrainingExercises, event.previousIndex, event.currentIndex);
  }

  removeSelectedExercise(exercise: MergedTrainingExercise) {
    // this.selectedTrainingExercises = this.selectedTrainingExercises.filter(x => x.id != exercise.id)
    let index = this.selectedTrainingExercises.indexOf(exercise);
    if (index >= 0) {
      this.selectedTrainingExercises.splice(index, 1);
    }
  }

  constructor(public userService: FirestoreService, public trainingService: TrainingService, private spinner: NgxSpinnerService, private toastr: ToastrService, public languageService: LanguageService) { }

  getCardioZones(zoneGroup: CardioZoneGroup){
    return zoneGroup.zones.sort((a, b) => a.isMaxCardioZone ? -1 : b.isMaxCardioZone ? 1 : 0);
  }

  getCardioZoneGroupExerciseNames(zoneGroup: CardioZoneGroup){
    if(zoneGroup.exerciseIds.length == 0){
      return "Übungen auswählen";
    }
    let returnString = "";
    let exercises = zoneGroup.exerciseIds.map(x => this.trainingService.getExerciseById(x));
    if(exercises.length > 0){
      returnString = exercises.map(x => x?.name?.GetValue(this.languageService.selectedLanguageCode)).join(", ");
    }
    return returnString
  }

  removeExercise(zoneGroup: CardioZoneGroup, exerciseId: string) {
    zoneGroup.exerciseIds = zoneGroup.exerciseIds.filter(x => x != exerciseId);
    this.hasChanges = true;
  }

  addExercise(zoneGroup: CardioZoneGroup){
    this.editExerciseCardioZoneGroup = zoneGroup;
    this.selectedTrainingExercises = zoneGroup.exerciseIds.map(x => this.trainingService.getExerciseById(x));
    this.showExerciseDialog();
    this.hasChanges = true;
  }


  onTrainingExerciseSelectionChanged(exercise: MergedTrainingExercise){
    if(this.selectedTrainingExercises?.find(x => x.sourceExerciseId == exercise.sourceExerciseId)){
      this.selectedTrainingExercises = this.selectedTrainingExercises.filter(x => x.id != exercise.id);
    }
    else {
      this.selectedTrainingExercises.push(exercise);
    }
  }

  onTrainingExerciseMultiSelectionChanged(selectedExercises: MergedTrainingExercise[]){
    this.selectedTrainingExercises = selectedExercises;
  }

  onTakeExercisesSelection(){
    this.hideExerciseDialog();
    this.editExerciseCardioZoneGroup.exerciseIds = this.selectedTrainingExercises.map(x => x.sourceExerciseId);
    this.selectedTrainingExercises = [];
  }

  onCancelExercisesSelection(){
    this.hideExerciseDialog();
    this.selectedTrainingExercises = [];
  }

  addCardioZoneGroup() {
    let zoneGroup = new CardioZoneGroup(null, this.user?.age);
    zoneGroup.zones = this.globalCardioZones.map(x => x.clone());
    zoneGroup.resetMaxCardioZone(this.user?.age);
    zoneGroup.recalculateCardioZones(this.globalCardioZones);
    zoneGroup.name = "Gruppe " + this.cardioZoneGroups.length;
    this.cardioZoneGroups.push(zoneGroup);
  }

  removeCardioZoneGroup(cardioZoneGroup: CardioZoneGroup) {
    if(cardioZoneGroup.id == "default"){
      this.toastr.error("Standard Belastungszonen-Gruppe kann nicht gelöscht werden!");
      return;
    }
    cardioZoneGroup.deleted = true;
    this.hasChanges = true;
  }

  copyZoneGroup(zoneGroup: CardioZoneGroup){
    let copy = zoneGroup.clone(this.user?.age);
    copy.id = FirestoreNutritionPlanService.generateUniqueString();
    copy.name = zoneGroup.name + " (Kopie)";
    this.cardioZoneGroups.push(copy);
    this.hasChanges = true;
  }


  addCardioZone(zoneGroup: CardioZoneGroup) {
    zoneGroup.zones.push(new CardioZone());
    this.hasChanges = true;
  }

  removeCardioZone(zone: CardioZone) {
    if(zone.isMaxCardioZone){
      this.toastr.error("Maximal-Belastungszone kann nicht gelöscht werden!");
      return;
    }
    // zone.deleted = true;
    this.hasChanges = true;
  }

  
  getHeartRate(zone: CardioZone){
    let returnString: string = "";
    if(zone.minHeartRate) {
      returnString += zone.minHeartRate;

      if(zone.maxHeartRate) {
        returnString += "-" + zone.maxHeartRate;
      }
    }

    return returnString;
  }
  
  setHeartRate(zone: CardioZone, value: string){
    if(value == null || value.length == 0){
      zone.minHeartRate = undefined;
      zone.maxHeartRate = undefined;
    }
    if(value.match(this.rangePattern)){
      if(value.includes('-')){
        let splitted = value.split('-');
        if(splitted.length > 1){
          let low = parseInt(splitted[0])
          let high = parseInt(splitted[1])

          if(low && high){
            zone.minHeartRate = low;
            zone.maxHeartRate = high;
          }
        }
      }
      else {
        let low = parseInt(value);
        if(low){
          zone.minHeartRate = low;
        }
      }
    }
    this.hasChanges = true;
  }

  
  getPace(zone: CardioZone, isPace500: boolean){
    let minParamter = isPace500 ? "minPace500" : "minPace";
    let maxParamter = isPace500 ? "maxPace500" : "maxPace";
    let returnString: string = "";
    if(zone.minPace != undefined){
      let minSecondsDate = zone[minParamter] ? new Date(0,0,0,0,0,zone[minParamter],0)?.toTimeString()?.slice(3,8) : "00:00";
      
      returnString = minSecondsDate
      
      if(zone[maxParamter] != undefined){
        let maxSecondsDate = zone[maxParamter] ? new Date(0,0,0,0,0,zone[maxParamter],0)?.toTimeString()?.slice(3,8) : "00:00";
        returnString += "-" + maxSecondsDate;
      }
    }
    return returnString;
  }

  setPace(zone: CardioZone, value: string, isPace500: boolean){
    let minParamter = isPace500 ? "minPace500" : "minPace";
    let maxParamter = isPace500 ? "maxPace500" : "maxPace";
    if(value == null || value.length == 0){
      zone[minParamter] = undefined;
      zone[maxParamter] = undefined;
    }
    if(value.match(this.paceRangePattern)){
      if(value.includes('-')){
        let splitted = value.split('-');
        if(splitted.length > 1){
          let low = this.getSecondsFromTimeString(splitted[0]);
          let high = this.getSecondsFromTimeString(splitted[1]);

          if(low && high){
            zone[minParamter] = low;
            zone[maxParamter] = high;
          }
        }
      }
      else {
        let low = this.getSecondsFromTimeString(value);
        if(low){
          zone[minParamter] = low;
        }
      }
    }
    this.hasChanges = true;
  }

  getSecondsFromTimeString(timeString: string){
    let splitted = timeString.split(':');
    if(splitted.length == 3){
      let hours = parseInt(splitted[0]);
      let minutes = parseInt(splitted[1]);
      let seconds = parseInt(splitted[2]);

      if(hours >= 0 && minutes >= 0 && seconds >= 0){
        return hours * 3600 + minutes * 60 + seconds;
      }
    }
    else if(splitted.length == 2){
      let minutes = parseInt(splitted[0]);
      let seconds = parseInt(splitted[1]);

      if(minutes >= 0 && seconds >= 0){
        return minutes * 60 + seconds;
      }
    }
  }

  setZoneGroupName(zoneGroup: CardioZoneGroup, value: string){
    zoneGroup.name = value;
    this.hasChanges = true;
  }

  setName(zone: CardioZone, value: string){
    zone.name = value;
    this.hasChanges = true;
  }

  onUpdatePaceZoneValues(zoneGroup: CardioZoneGroup){
    zoneGroup.recalculateCardioZones(this.globalCardioZones, SetParameter.pace);
    this.hasChanges = true;
  }

  onUpdatePace500ZoneValues(zoneGroup: CardioZoneGroup){
    zoneGroup.recalculateCardioZones(this.globalCardioZones, SetParameter.pace500);
    this.hasChanges = true;
  }

  onUpdateHeartRateZoneValues(zoneGroup: CardioZoneGroup){
    zoneGroup.recalculateCardioZones(this.globalCardioZones, SetParameter.heartRate);
    this.hasChanges = true;
  }

  onToggleHeartRate(zoneGroup: CardioZoneGroup){
    zoneGroup.heartRateAvailable = !zoneGroup.heartRateAvailable;
    this.hasChanges = true;
  }
  onTogglePace(zoneGroup: CardioZoneGroup){
    zoneGroup.paceAvailable = !zoneGroup.paceAvailable;
    this.hasChanges = true;
  }

  onTogglePace500(zoneGroup: CardioZoneGroup){
    zoneGroup.pace500Available = !zoneGroup.pace500Available;
    this.hasChanges = true;
  }
}
