import {Injectable} from '@angular/core';
import {FirestoreService} from "./firestore.service";
import {Community, CommunityModel} from "../model/community.model";
import {catchError, first, forkJoin, mergeAll, mergeMap, Observable, of, take, zip} from "rxjs";
import {map} from "rxjs/operators";
import {LicenceHolder} from "../model/licenceholder.model";
import firebase from "firebase/compat";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {doc, onSnapshot} from "@angular/fire/firestore";
import {CommunityManagementConfig} from "../model/community-management-config.model";

export type CommunityPostMetaUserAdded = {
  type: "metaUserAdded" | "metaUserRemoved";
  userUid: string;
  date: Date;
  timestamp: Date
}

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  private readonly COLLECTION_NAME: string = "Communities";
  private readonly STORAGE_COLLECTION_NAME: string = "communities";
  private readonly POSTS_COLLECTION_NAME: string = "CommunityPosts";

  constructor(private firestoreService: FirestoreService) {

  }

  public getCommunityEnabled() {
    const uid = this.firestoreService.getLoggedInUser().licenceHolderUid;
    return this.firestoreService.firestore.collection('LicenceHolders/').doc(uid).get();
  }

  public updateCommunityEnabled(enabled: boolean) {
    const uid = this.firestoreService.getLoggedInUser().licenceHolderUid;
    this.firestoreService.firestore.collection('LicenceHolders/').doc(uid).update({communityEnabled: enabled});
  }

  public getAgreement() {
    const uid = this.firestoreService.getLoggedInUser().licenceHolderUid;
    return this.firestoreService.firestore.collection('LicenceHolders/').doc(uid).get().pipe(map(
      (doc: DocumentSnapshot<LicenceHolder>) => doc.data().communityAgreement
    ));
  }

  public updateAgreement(agreement: string) {
    const uid = this.firestoreService.getLoggedInUser().licenceHolderUid;
    return this.firestoreService.firestore.collection('LicenceHolders/').doc(uid).update({communityAgreement: agreement});
  }

  public getCommunities(): Observable<Community[]> {
    const uid = this.firestoreService.getLoggedInUser().licenceHolderUid;

    return this.firestoreService.firestore.collection<Community>(this.COLLECTION_NAME, ref => {
      return ref.where('licenceHolderUid', '==', uid).where("deleted", "==", false);
    }).valueChanges({
      idField: "id"
    }).pipe(
      map(communities => communities.map(community => {
        return new Community(community)
      }))
    );
  }

  public addCommunity(community: Community) {
    // TODO what to do with ID?
    return this.firestoreService.firestore.collection<any>(this.COLLECTION_NAME).add(community.toFirestore());
  }

  public deleteCommunity(community: Community) {
    community.deleted = true;
    return this.updateCommunity(community);
  }

  public createCommunity(): Community {
    const newCommunity = new Community();
    newCommunity.licenceHolderUid = this.firestoreService.getLoggedInUser().licenceHolderUid;
    return newCommunity;
  }

  public updateCommunity(community: Community) {
    return this.firestoreService.firestore.collection<CommunityModel>(this.COLLECTION_NAME).doc(community.id).update(community.toFirestore());
  }

  public getImage(path: string): Observable<string> {
    if (!path) return of('');
    try {
      return this.firestoreService.fireStorage.ref(path).getDownloadURL();
    } catch (e) {
      return of('')
    }
  }

  public uploadImage(pic: File, communityId: string) {
    if(!pic) return;

    const path = `${this.STORAGE_COLLECTION_NAME}/${communityId}/${pic.name}`;
    return this.firestoreService.fireStorage.upload(path, pic).then(async () => {
      await this.firestoreService.firestore.collection(this.COLLECTION_NAME).doc(communityId).update({imagePath: path});
    });
  }

  public imagePathSeemsValid(path: string, communityId: string) {
    if (!path) return false;

    const snippets = path.split('/');
    if (snippets.length !== 3) return false;

    if(snippets[0] !== this.STORAGE_COLLECTION_NAME) return false;
    if(snippets[1] !== communityId) return false;
    if(snippets[2] === '') return false;

    return true;
  }

  public getUsers() {
    const userMap = new Map<string, string>();
    this.firestoreService.getAllClientsOfLicenceHolder().forEach(client => {
      let fullname= "";
      if(client.name) fullname = client.name;
      if(client.lastName) fullname += " " + client.lastName;
      userMap.set(client.uid, fullname);
    });
    return userMap;
  }

  public getCoaches() {
    const uid = this.firestoreService.getLoggedInUser().licenceHolderUid;
    return this.firestoreService.getAllCoachesByLicenceHolderUid(uid).pipe(take(1));
  }

  public getCommunityDoc(id: string, callback: (snapshot) => void) {
    return onSnapshot(doc(this.firestoreService.firestore.firestore, this.COLLECTION_NAME, id), callback);
  }

  public getGroups() {
    const groupMap = new Map<string, string>();
    this.firestoreService.getClientGroups().forEach((group, index) => {
      groupMap.set(group, group);
    });
    return groupMap;
  }
}
