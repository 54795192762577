<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12" *ngIf="environment.firebaseProjectId == 'traindoo-app' && utilityService.onMobile()">
                <app-library-mobile-navigation></app-library-mobile-navigation>
            </div>
            <div class="col-9 col-lg-9 col-xl-8 col-xxl-4 order-1 order-xxl-1 profile">
                <h2 class="">Coaching-Tools</h2>
            </div>
            <div class="col-12 col-xl-12 col-xxl-4 order-3 order-xxl-2">
                <div class="div textalign-center tab-button-group adjust-mobile">
                    <div class="spacer-10"></div>
                    <button class="tab-button" [routerLinkActive]="['active']" routerLink="checkins">
                        Check-Ins
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="tab-button" [routerLinkActive]="['active']" routerLink="notifications">
                        Erinnerungen
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="tab-button" [routerLinkActive]="['active']" routerLink="filesharing">
                        Dateiablage
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="tab-button" [routerLinkActive]="['active']" routerLink="community">
                        Community
                        <div class="underline"></div>
                    </button>
                </div>
            </div>
            <div *ngIf="!environment.isWhitelabel" class="col-12 col-lg-3 order-2 oder-xxl-3 textalign-right">
                <div class="pricing-button marginleft-25">
                    <div class="icon-button" (click)="openMarketingKit()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-briefcase" viewBox="0 0 16 16">
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                        Marketing-Kit öffnen
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="layout-box adjust-mobile">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
