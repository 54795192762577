import { Component } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { AffiliateDialogComponent } from '../dialogs/affiliate-dialog/affiliate-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent {

    get referralCounter() {
      return this.userService.getLoggedInUser().licenceHolder?.referralCounter ?? null
    }
    public selectedCategory: string = null

    public static largePartners = [
      'kaf-akademie',
      'musclemind',
    ]
    public static smallPartners = [
      'david-shiva',
      'prepmymeal',
      'jannis-neumann'
    ]
    public partners = []
  
    constructor(public userService: FirestoreService, public dialog: MatDialog) {
      this.partners = ['nutrilize']
      PartnersComponent.largePartners = PartnersComponent.largePartners.sort(() => Math.random() - 0.5)
      PartnersComponent.smallPartners = PartnersComponent.smallPartners.sort(() => Math.random() - 0.5)
      for (var i = 0; i < Math.max(PartnersComponent.largePartners.length, PartnersComponent.smallPartners.length); i++) {
        if (i < PartnersComponent.smallPartners.length) {
          this.partners.push(PartnersComponent.smallPartners[i])
        }
        if (i < PartnersComponent.largePartners.length) {
          this.partners.push(PartnersComponent.largePartners[i])
        }
      }
    }

    onShareAffiliateLink() {
      let dialogRef = this.dialog.open(AffiliateDialogComponent, { data: { link: 'https://www.nutrilize.app/referral?referral_name=' + this.userService.getLoggedInUser().licenceHolder?.name?.replaceAll(' ', '_') }, width: '500px'})
      dialogRef.afterClosed().subscribe(result => {})
    }

    onChangeCategory(category: string) {
      this.selectedCategory = category
    }
}
