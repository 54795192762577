import { Product } from "./product.model"
import { User } from "./user.model"

export enum PaymentStatus {
  DRAFT = "draft",
  PAID = "paid",
  UNPAID = "unpaid",
  PROCESSING = "processing",
  REFUNDED = "refunded",
  VOIDED = "voided",
  FAILED = "failed",
  DISPUTED = "disputed",
  INITIATED = "initiated",
  FORECAST = "forecast",
}

export class Payment {
    id: string;
    stripeInvoiceId: string;
    stripeCustomerId: string;
    stripeAccountId: string;
    customerUid: string;
    productId: string;
    productPurchaseId: string;
    currency: string;
    amount: number;
    status: PaymentStatus;
    date: Date;
    invoiceNumber: string;
    invoiceFileName: string;
    cancellationInvoiceNumber: string;
    cancellationInvoiceFileName: string;
    paymentMethod: string;
    logs: LogItem[];
    payoutDate: Date;
    reversePayoutDate: Date;
    stripePaymentIntentId: string;

    customer: User;
    product: Product;

    constructor();
    constructor(init: Payment);
    constructor(init?: Payment) {
        this.id = init && init.id || null
        this.stripeInvoiceId = init && init.stripeInvoiceId || null
        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.stripeAccountId = init && init.stripeAccountId || null
        this.customerUid = init && init.customerUid || null
        this.productId = init && init.productId || null
        this.productPurchaseId = init && init.productPurchaseId || null
        this.currency = init && init.currency || 'eur'
        this.amount = init && init.amount || null
        this.status = init && init.status || null
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
        this.invoiceNumber = init && init.invoiceNumber || null
        this.invoiceFileName = init && init.invoiceFileName || null
        this.cancellationInvoiceNumber = init && init.cancellationInvoiceNumber || null
        this.cancellationInvoiceFileName = init && init.cancellationInvoiceFileName || null
        this.paymentMethod = init && init.paymentMethod || null
        this.logs = init && init.logs?.map(x => new LogItem(x)) || []
        this.stripePaymentIntentId = init && init.stripePaymentIntentId || null
        this.payoutDate = init && init.payoutDate ? new Date((init as any).payoutDate.seconds * 1000) : null
        this.reversePayoutDate = init && init.reversePayoutDate ? new Date((init as any).reversePayoutDate.seconds * 1000) : null
    }

    getDate() {
        // Subtract timezone offset to get the correct date
        var offset = new Date().getTimezoneOffset();
        if (offset == null) return this.date
        var offsetHours = offset / 60
        var d = this.date.clone()
        d.setHours(d.getHours() + offsetHours)
        return d
    }

    getPrintablePrice() {
        return (this.amount / 100).toString().replace('.', ',')
    }
    getPrintablePriceWithCurrency() {
        return this.getPrintablePrice() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCurrency() {
        if (this.currency == 'eur' || this.currency == null) return '€'
        return '€'
    }
    getPrintableStatus() {
        switch (this.status) {
            case PaymentStatus.DRAFT:    return 'Entwurf';
            case PaymentStatus.PAID: return 'Bezahlt';
            case PaymentStatus.UNPAID: return 'Unbezahlt';
            case PaymentStatus.PROCESSING: return 'In Verarbeitung';
            case PaymentStatus.REFUNDED: return 'Erstattet';
            case PaymentStatus.VOIDED: return 'Storniert';
            case PaymentStatus.FAILED: return 'Fehlgeschlagen';
            case PaymentStatus.INITIATED: return 'Initiiert';
            default: return 'Unbekannt';
        }
    }
    getPrintablePaymentMethod() {
        if (!this.paymentMethod) return ''
        switch (this.paymentMethod) {
            case 'paypal':    return 'PayPal';
            case 'card':    return 'Kreditkarte';
            case 'sepa_debit':    return 'SEPA-Lastschrift';
            case 'bank_transfer':   return 'Banküberweisung';
            default: return this.paymentMethod;
        }
    }

    asMap() {
        return {
            stripeInvoiceId: this.stripeInvoiceId,
            stripeCustomerId: this.stripeCustomerId,
            stripeAccountId: this.stripeAccountId,
            customerUid: this.customerUid,
            productId: this.productId,
            productPurchaseId: this.productPurchaseId,
            currency: this.currency,
            amount: this.amount,
            status: this.status,
            date: this.date,
            invoiceNumber: this.invoiceNumber,
            invoiceFileName: this.invoiceFileName,
            cancellationInvoiceNumber: this.cancellationInvoiceNumber,
            cancellationInvoiceFileName: this.cancellationInvoiceFileName,
            paymentMethod: this.paymentMethod,
            payoutDate: this.payoutDate,
            stripePaymentIntentId: this.stripePaymentIntentId,
        }
    }
}

export class LogItem {
    date: Date
    event: string
    error: string
    message: string
    cardDeclineCode: string

    constructor();
    constructor(init: LogItem);
    constructor(init?: LogItem) {
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
        this.event = init && init.event || null
        this.error = init && init.error || null
        this.message = init && init.message || null
        this.cardDeclineCode = init && init.cardDeclineCode || null
    }

    getPrintableErrorDescription() {
        if (!this.error && !this.message) return null
        var text = this.getPrintableError()
        if (this.getPrintableCardDeclineCode()) text += ' (' + this.getPrintableCardDeclineCode() + ')'
        if (this.message) text += ': ' + this.message
        return text
    }

    getPrintableError() {
        if (this.error == 'card_declined') return 'Karte abgelehnt'
        if (this.error == 'expired_card') return 'Karte abgelaufen'
        if (this.error == 'sepa_generic_decline') return 'Zahlung von der ausstellenden Bank abgewiesen'
        return this.error ?? ''
    }

    getPrintableCardDeclineCode() {
        if (this.cardDeclineCode == 'do_not_honor') return 'Karte nicht akzeptiert'
        if (this.cardDeclineCode == 'expired_card') return 'Karte abgelaufen'
        if (this.cardDeclineCode == 'incorrect_cvc') return 'Falsche CVC'
        if (this.cardDeclineCode == 'incorrect_number') return 'Falsche Kartennummer'
        if (this.cardDeclineCode == 'incorrect_pin') return 'Falsche PIN'
        if (this.cardDeclineCode == 'insufficient_funds') return 'Nicht genügend Kontodeckung'
        if (this.cardDeclineCode == 'lost_card') return 'Karte verloren'
        return null
    }

    getPrintableEvent() {
        if (!this.event) return ''
        switch (this.event) {
            case 'invoice_created': return 'Rechnung erstellt'
            case 'invoice_finalized': return 'Rechnung finalisiert'
            case 'invoice_refunded': return 'Rechnung erstattet'
            case 'payment_created': return 'Rechnung erstellt'
            case 'invoice_payment_failed': return 'Zahlung fehlgeschlagen'
            case 'payment_failed': return 'Zahlung fehlgeschlagen'
            case 'invoice_paid': return 'Zahlung erfolgreich'
            case 'checkout_completed': return 'Kauf abgeschlossen'
            case 'licence_activated': return 'Lizenz aktiviert'
            case 'licence_manually_activated': return 'Lizenz manuell aktiviert'
            case 'invoice_voided': return 'Zahlung storniert'
            case 'renewal_canceled': return 'Verlängerung gekündigt'
            case 'purchase_canceled': return 'Kauf gekündigt'
            case 'payment_processing': return 'Zahlung in Verarbeitung'
            case 'runtime_started': return 'Laufzeit gestartet'
            case 'runtime_ended': return 'Laufzeit beendet'
            case 'recharge_payment': return 'Zahlung wiederholen'
            case 'runtime_manually_extended': return 'Laufzeit manuell verlängert'
            case 'paymentdate_changed': return 'Abrechnungsdatum geändert'
            case 'mark_as_paid': return 'Rechnung als bezahlt markiert'
            case 'skip_next_payment': return 'Nächste Zahlung ausgesetzt'
        }
        return this.event
    }
}
