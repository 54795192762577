import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {
  visible: boolean;
  collapsed: boolean = true

  private dashboard = "nav-item active";
  private tracking = "nav-item";
  private coach = "nav-item";
  private profile = "nav-item";
  private licence = "nav-item";
  private recipes = "nav-item";
  private settings = "nav-item";
  private account = "nav-item";
  private recipesClient = "nav-item";
  private chat = "nav-item";
  private training = "nav-item";
  private tools = "nav-item";
  private newsBoard = "nav-item";
  private payment = "nav-item";
  private partners = "nav-item";
  private iapDashboard = "nav-item";

  constructor(private router: Router)
  {
    this.visible = true;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.changeNavItems(val.urlAfterRedirects);
      }
    });
  }

  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }

  changeNavItems(name: string) {
    this.setNavItemsNormal();
    switch(name){
      case "/dashboard":
        this.dashboard = "nav-item active"
        break;
      case "/clients":
        this.coach = "nav-item active";
        break;
      case "/profile":
        this.profile = "nav-item active";
        break;
      case "/licence":
        this.licence = "nav-item active";
        break;
      case "/recipes":
        this.recipes = "nav-item active";
        break;
      case "/settings":
        this.settings = "nav-item active";
        break;
      case "/account":
        this.account = "nav-item active";
        break;
      case "/recipes_client":
        this.recipesClient = "nav-item active";
        break;
      case "/chat":
        this.chat = "nav-item active";
        break;
      case "/news-board":
        this.newsBoard = "nav-item active";
        break;
      case "/training":
        this.training = "nav-item active";
        break;
      case "/tools":
        this.tools = "nav-item active";
        break;
      case "/payment":
        this.payment = "nav-item active";
        break;
      case "/partners":
        this.partners = "nav-item active";
        break;
      case "/iap-dashboard":
        this.iapDashboard = "nav-item active";
        break
      default:
        if(name.startsWith("/client/")) this.tracking = "nav-item active";
        // man könnte auch nach splitten und erste Element immer checken. Wäre sinnvoll wenn allgemein auf subroutes umgestellt wird.
        if(name.startsWith("/tools/")) this.tools = "nav-item active";
        break;
    }
  }

  toggleHelpDialog(){
    (window as any).Intercom('showArticle', 8975896);
    return
  }

  openSupport(){
    (window as any).Intercom('show');
    //Intercom('startTour', 123);
  }

  toggleCollapseState() {
    this.collapsed = !this.collapsed
  }

  setNavItemsNormal() {
    this.dashboard = "nav-item";
    this.tracking = "nav-item";
    this.coach = "nav-item";
    this.profile = "nav-item";
    this.licence = "nav-item";
    this.recipes = "nav-item";
    this.settings = "nav-item";
    this.recipesClient = "nav-item";
    this.account = "nav-item";
    this.chat = "nav-item"
    this.newsBoard = "nav-item"
    this.training = "nav-item"
    this.tools = "nav-item"
    this.payment = "nav-item"
    this.partners = "nav-item"
    this.iapDashboard = "nav-item"
  }

  getDashboard() {
    return this.dashboard;
  }

  getTracking() {
    return this.tracking;
  }

  getCoach() {
    return this.coach;
  }

  getProfile() {
    return this.profile;
  }

  getLicence() {
    return this.licence;
  }

  getRecipes() {
    return this.recipes;
  }
  getSettings() {
    return this.settings;
  }

  getRecipesClient() {
    return this.recipesClient;
  }
  getAccount() {
    return this.account;
  }

  getChat(){
    return this.chat;
  }
  getNewsBoard(){
    return this.newsBoard;
  }

  getTraining(){
    return this.training;
  }

  getTools(){
    return this.tools;
  }

  getPayment() {
    return this.payment;
  }

  getPartners() {
    return this.partners;
  }

  getIapDashboard() {
    return this.iapDashboard;
  }
}
