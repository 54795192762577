import {ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CommunityService} from "../../services/community.service";
import {ImageEditorComponent} from "../../utilities/image-editor/image-editor.component";
import {MatDialog} from "@angular/material/dialog";
import {CommunityManagementConfig, ManagementConfig} from "../../model/community-management-config.model";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-community-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
// export class ManagementComponent implements OnInit, OnChanges, DoCheck {
export class ManagementComponent implements OnInit, OnChanges {
  protected readonly ALL_GROUP: string = 'all';
  protected readonly EDITOR_PLACEHOLDER: string = "Beschreibung";

  public shadowControl = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    imagePath: new FormControl(''),
    allowPostings: new FormControl(false),
    allowRecipeSharing: new FormControl(false),
    allowTrainingSharing: new FormControl(false),
    allowVoiceMessagesInPostings: new FormControl(false),
    coaches: new FormControl(''),
    groups: new FormControl(''),
    users: new FormControl(''),
  });

  @Input()
  public communityConfig: CommunityManagementConfig;

  constructor(private communityService: CommunityService, private dialog: MatDialog, private cdr: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.communityConfig.firstChange) {
      if (changes.communityConfig.previousValue.getCommunityId() === changes.communityConfig.currentValue.getCommunityId()) {
        return;
      }
    }

    this.shadowControl.patchValue({
      name: this.communityConfig.name,
      description: this.communityConfig.description,
      imagePath: this.communityConfig.imagePath,
      allowPostings: this.communityConfig.allowPostings,
      allowRecipeSharing: this.communityConfig.allowRecipeSharing,
      allowTrainingSharing: this.communityConfig.allowTrainingSharing,
      allowVoiceMessagesInPostings: this.communityConfig.allowVoiceMessagesInPostings,
      coaches: this.communityConfig.getCoachIds().join(' '),
      groups: this.communityConfig.getGroupIds().join(' '),
      users: this.communityConfig.getMemberIds().join(' ')
    }, {emitEvent: false});
  }

  ngOnInit() {
    this.shadowControl.valueChanges.subscribe(value => {
      this.communityConfig.name = value.name;
      this.communityConfig.description = value.description;
      this.communityConfig.imagePath = value.imagePath;
      this.communityConfig.allowPostings = value.allowPostings;
      this.communityConfig.allowRecipeSharing = value.allowRecipeSharing;
      this.communityConfig.allowTrainingSharing = value.allowTrainingSharing;
      this.communityConfig.allowVoiceMessagesInPostings = value.allowVoiceMessagesInPostings;

      this.communityConfig.isDirty = true;
    });
  }

  protected onUploadImage() {
    document.getElementById("input-community-image").click();
  }

  protected uploadImage(event) {
    const file = event.target.files[0];

    if(!file) return;

    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: {
        imageFile: file,
        aspectRatio: 1,
        maintainAspectRatio: true,
        containWithinAspectRatio: true,
        maxDimension: 800
      },
      width: '1000px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.croppedImage) {
        this.blobToBase64(result.croppedImage).then((thumbnailImageSrc: string) => {
          this.communityConfig.updatedImage = result.croppedImage;
          this.communityConfig.tempImgUrl = thumbnailImageSrc

          this.communityConfig.isDirty = true;
          (document.getElementById("input-community-image") as HTMLInputElement).value = "";
        });
      }
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  protected onUserSelected(user: string) {
    this.communityConfig.addOrRemoveMember(user);
    this.shadowControl.patchValue({
      users: this.communityConfig.getMemberIds().join(' '),
    });
  }

  protected onCoachSelected(coach: string) {
    this.communityConfig.addOrRemoveCoach(coach);
    this.shadowControl.patchValue({
      coaches: this.communityConfig.getCoachIds().join(' '),
    });
  }

  protected onGroupSelected(group: string) {
    this.communityConfig.addOrRemoveGroup(group);
    this.shadowControl.patchValue({
      groups: this.communityConfig.getGroupIds().join(' '),
    });
  }

  protected getSelectedUsersString() {
    if (this.allUsersTargeted()) {
      return "Alle";
    }

    return this.communityConfig.getGroupNamesAndMemberNames() || "Bitte wählen...";
  }

  protected getSelectedCoachesString() {
    return this.communityConfig.getCoachesNames() || "Bitte wählen...";
  }

  protected onEditorValueChanged(value: string) {
    if (value == this.EDITOR_PLACEHOLDER || value?.length == 0) {
      this.communityConfig.description = ''
    } else {
      this.communityConfig.description = value;
    }

    this.shadowControl.patchValue({
      description: this.communityConfig.description,
    });
  }

  public allUsersTargeted() {
    return this.communityConfig.getGroupIds().includes(this.ALL_GROUP)
  }

  public isGroupSelected(group: string) {
    return this.allUsersTargeted() || this.communityConfig.getGroupIds().includes(group);
  }

  public isUserSelected(user: string) {
    return this.allUsersTargeted() || this.communityConfig.getMemberIds().includes(user);
  }

  openIntercomArticle() {
    try {
      (window as any).Intercom('showArticle', 10272371);
    } catch (e) {
    }
  }
}
