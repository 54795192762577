import { EditUserDataService } from './../services/edit-user-data.service';
import { Component, ComponentFactoryResolver, Renderer2, Input, ComponentFactory } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../services/firestore.service';
import { User } from '../model/user.model';
import { UtilityService } from '../services/utility.service';
import { ChatService } from '../services/chat.service';
import { ToastrService } from 'ngx-toastr';
import { NutritionService } from '../services/nutrition.service';
// import * as algoliasearch from 'algoliasearch'
import '../prototypes'
import { NgxSpinnerService } from 'ngx-spinner';
import {MatDialog } from '@angular/material/dialog';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { NutritionplanExportService } from '../services/nutritionplan-export.service';
import { ChartExportService } from '../services/chart-export.service';
import { LanguageService } from '../services/language.service';
import { PaymentService } from '../services/payment.service';
import { SpikeService } from '../services/spike-service.service';
import { TrackedTrainingSession } from '../model/training-monitoring.model';
import { TrainingSession } from '../model/training-plan.model';
import { Activity } from '../model/activity.model';
import { QuestionaireResult } from '../model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from '../questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { TrainingHistoryDialogComponent } from '../training-monitoring/training-history-dialog/training-history-dialog.component';
import { ActivityHistoryDialogComponent } from '../training-monitoring/activity-history-dialog/activity-history-dialog.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { DayItem, NutritionPlanComponent } from '../nutrition-plan/nutrition-plan.component';
import { CycleConfig } from '../model/nutritionconfig.model';
import { ActivityEditorComponent } from '../activity-editor/activity-editor.component';
import { environment } from 'src/environments/environment';
import { Meal } from '../model/meal.model';
import { MealType, MealTypeTranslation, NutritionPlanMealConfig, StringToMealType } from '../model/nutritionplanv2.model';
import { Metric } from '../model/metric.model';
import { MetricData } from '../model/metricdata.model';
import { MetricsSelectionDialogComponent } from '../metrics-selection-dialog/metrics-selection-dialog.component';
import { TrackedMealEditorDialogComponent } from '../dialogs/tracked-meal-editor-dialog/tracked-meal-editor-dialog.component';
import { MealEditorDialogComponent } from '../meal-editor-dialog/meal-editor-dialog.component';
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { Recipe } from '../model/recipe.model';
import { Ingredient } from '../model/ingredient.model';
import { MetricDataImageDialogComponent } from '../metric-data-image-dialog/metric-data-image-dialog.component';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';
import { NutritionalValuePopoverComponent } from '../nutritional-value-popover/nutritional-value-popover.component';
import { EventLogService } from '../services/event-log.service';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.css']
})
export class DiaryComponent {

  public math = Math
  public EventLogService = EventLogService
  public componentFactory: ComponentFactory<any>

  public colorCalorieGraph = environment.colorCalorieGraph
  public colorCarbohydratesGraph = environment.colorCarbohydratesGraph
  public colorProteinGraph = environment.colorProteinGraph
  public colorFatGraph = environment.colorFatGraph
  public colorOtherNutrientsGraph = environment.colorOtherNutrientsGraph

  public weekDays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  public static weekdays() {
    return this.weekdays
  }

  // Displayed/Selected User
  public displayedUser: User
  public editableDisplayedUser: User
  public cycleConfigOfSelectedDate: CycleConfig
  public currentDate = new Date()

  public spinnerText = null

  @Input() set setDisplayedUser(value: User) {
    var reloadData = !this.displayedUser || this.displayedUser.uid != value.uid
    this.displayedUser = value
    if (reloadData) {
      this.loadTrackingDataForUser(this.displayedUser, this.currentDate)
    }
  }

  public environment = environment

  public nutritionalValueHolder: NutritionalValueHolder
  public selectedCycleConfig: CycleConfig;

  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder, cycleConfigOfSelectedDay: CycleConfig) {
    this.nutritionalValueHolder = nutritionalValueHolder
    this.selectedCycleConfig = cycleConfigOfSelectedDay
  }

  constructor(public nutritionService: NutritionService, public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, private paymentService: PaymentService, private nutritionPlanService: FirestoreNutritionPlanService, private route: ActivatedRoute, public utilityService: UtilityService, private nutritionPlanExportService: NutritionplanExportService, private toastr: ToastrService, private spinner: NgxSpinnerService,  public dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2, public chartExportService: ChartExportService, private spikeService: SpikeService, public editUserDataService: EditUserDataService, public languageService: LanguageService) {

  }

  ngOnInit() {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NutritionalValuePopoverComponent);

    this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
    this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
  }

  isAdmin(): boolean {
    return this.authService.isAdmin()
  }

  goToToday() {
    this.currentDate = new Date();
    this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
    this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
  }

  backOneDay() {
    this.currentDate.setDate(this.currentDate.getDate()-1);
    this.loadTrackingDataForUser(this.displayedUser, this.currentDate)
    this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
  }

  forwardOneDay() {
    this.currentDate.setDate(this.currentDate.getDate()+1);
    this.loadTrackingDataForUser(this.displayedUser, this.currentDate)
    this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
  }

  onDiaryDateChanged(date: Date) {
    this.currentDate = date
    this.loadTrackingDataForUser(this.displayedUser, this.currentDate)
    this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
  }

  refresh() {
    // this.updateBodyStatisticsGraph(this.displayedUser);
    this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
    this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
  }

  public diaryExportStartDate: Date
  public diaryExportEndDate: Date
  toggleDiaryExportDialog() {
    this.diaryExportEndDate = new Date()
    this.diaryExportStartDate = new Date().addDays(-7)
    document.getElementById('diaryexport-dialog').classList.toggle('show');
  }
  onConfirmDiaryExport() {
    this.exportDiary()
    this.toggleDiaryExportDialog()
  }
  onDiaryExportStartDateChanged(date: Date) {
    this.diaryExportStartDate = date
  }
  onDiaryExportEndDateChanged(date: Date) {
    this.diaryExportEndDate = date
  }

  async exportDiary() {
    let user = this.displayedUser
    var mealsForDays: Map<number, Meal[]> = new Map<number, Meal[]>()

    if (this.diaryExportEndDate.getTime() < this.diaryExportStartDate.getTime()) {
      var tmp = this.diaryExportEndDate
      this.diaryExportEndDate = this.diaryExportStartDate
      this.diaryExportStartDate = tmp
    }

    var startDate = this.diaryExportStartDate.clone()
    this.spinner.show()

    while (startDate.getTime() <= this.diaryExportEndDate.getTime()) {
      var date = startDate.clone()
      var meals = await this.userService.getMealsWithFoodsByDate(user, date, false).toPromise()
      mealsForDays.set(date.getTime(), meals)
      startDate.setDate(startDate.getDate() + 1)
    }

    this.nutritionPlanExportService.onExportDiary(mealsForDays, this.displayedUser).then(success => this.spinner.hide())

  }

  async onCreateActivity(user: User){
    let activity = new Activity();
    activity.date = this.currentDate;
    let now = new Date();
    activity.date.setHours(now.getHours());
    activity.date.setMinutes(now.getMinutes());
    this.onEditActivity(activity, user)
  }

  async onEditActivity(activity: Activity, user: User){
    let clonedActivity = activity.clone();
    this.spinner.show();
    let activityFacts = await this.userService.getAllActivityFacts();
    this.spinner.hide();
    let dialogRef = this.dialog.open(ActivityEditorComponent, {data: {activity: clonedActivity, availableAcitivityFacts: activityFacts, user: user}, width: '1000px'});
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.save) {
          try{
            this.spinner.show()
            this.userService.saveOrUpdateActivity(clonedActivity, user);
            this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
          } catch(ex) {
            console.error(ex);
          } finally {
            this.spinner.hide()
          }
        } else if(result.delete){
          try {
            this.spinner.show()
            this.userService.deleteActivity(clonedActivity, user);
            this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
          } catch(ex) {
            console.error(ex);
          } finally {
            this.spinner.hide()
          }
        }
      }
    });
  }


  private trackingDataSubscriptions: Subscription[] = []
  public loadingMeals = false
  public loadingMetricData = false

  loadTrackingDataForUser(user: User, date: Date) {
    var currentOffset = (date.getTimezoneOffset() * 60000 * -1)
    var userOffset = user.getLatestTimezoneOffsetForDate(date)
    var timezoneOffset = null
    if (userOffset != null && userOffset != currentOffset) {
      timezoneOffset = currentOffset - userOffset
    }
    
    this.selectedQuestionaireResults = [];
    this.trackingDataSubscriptions.forEach(subscription => {
      subscription.unsubscribe()
    })
    this.trackingDataSubscriptions = []
    
    user.meals = []
    user.activities = []
    user.dailyCondition = null
    
    // Fetch NutritionalGoal.
    /*var subscriptionNutritionalGoal = this.userService.getLatestNutritionalGoalByDate(user, date).subscribe(nutritionalGoal => {
      user.nutritionalGoal = nutritionalGoal;
      user.adjustNutritionalGoalWithActivitiesIfEnabled();
    })*/
    //this.trackingDataSubscriptions.push(subscriptionNutritionalGoal)

    if (user.configs == null || user.configs.length == 0){
      this.nutritionPlanService.loadCycleConfigs(user)
    }

    // Fetch Meals.
    this.loadingMeals = true
    
    var subscriptionMeals = this.userService.getMealsWithFoodsByDate(user, date, true, true, timezoneOffset).subscribe(meals => {
      user.meals = meals;
      var nutritionalSummary = new NutritionalSummary();
      meals.forEach(meal => {
        nutritionalSummary.carbohydrates = nutritionalSummary.carbohydrates + meal.getCarbohydrates();
        nutritionalSummary.protein = nutritionalSummary.protein + meal.getProtein();
        nutritionalSummary.fat = nutritionalSummary.fat + meal.getFat();
        nutritionalSummary.calories = nutritionalSummary.calories + meal.getCalories();
      })
      user.nutritionalSummary = nutritionalSummary;
      if(this.editUserDataService.isEditModeActivated){
        this.setFilteredPlannedMeals(date, user.meals, user);
      }
      this.loadingMeals = false
    })
    this.trackingDataSubscriptions.push(subscriptionMeals)

    // Fetch DailyCondition.
    this.loadingMetricData = true
    var subscriptionDailyCondition = this.userService.getDailyConditionByDate(user, date).subscribe(async dailyCondition => {
      user.dailyCondition = dailyCondition
      var metricDatas = dailyCondition.getMetricDataListWithQuestionaires()
      for (var metricData of metricDatas) {
        if (metricData.metric !== null) {
          if (metricData.metric.isMetricTypeImage() || metricData.metric.isMetricTypeVideo()) {
            if (!metricData.mediaLink) {
              metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, user.uid)
            }
          }
        }
      }
      this.loadingMetricData = false
    })
    this.trackingDataSubscriptions.push(subscriptionDailyCondition)

    // Fetch Activities.
    var subscriptionActivities = this.userService.getActivitiesByDate(user, date).subscribe(activities => {
      user.activities = activities
      user.adjustNutritionalGoalWithActivitiesIfEnabled();
      var startDate = date.clone()
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)
      this.userService.getTrackedTrainingSessionsWithExercises(user, startDate.clone(), startDate.clone().addDays(1)).then(sessions => {
        activities.forEach(a => {
          sessions.forEach(s => {
            if (a.date?.getTime() == s.startDate.getTime()) {
              a.trainingSession = s
            }
          })
        })
      })
    })
    this.trackingDataSubscriptions.push(subscriptionActivities)

    // Fetch Activities for calendar week.
    if (environment.firebaseProjectId == 'aesthetics-advisor') {
      var startDate = date.clone().getStartOfWeek()
      var endDate = date.clone().addDays(7)

      var subscriptionActivities = this.userService.getAllActivitiesByDateRange(user, startDate, endDate).subscribe(activities => {
        user.activitiesOfCurrentCalendarWeek = activities
      })
      this.trackingDataSubscriptions.push(subscriptionActivities)
    }

    
    if(!this.editUserDataService.isEditModeActivated){
      this.relevantMealsByMealType?.clear()
      this.relevanNutritionPlanMeals = []
      this.alternativeMealDay = null
    }
  }


  public relevantMealsByMealType: Map<String, DayItem[]> = new Map<String, DayItem[]>()

  public relevanNutritionPlanMeals: DayItem[] = []

  async setFilteredPlannedMeals(date: Date, existingMeals: Meal[], user: User){

    try{
      this.spinner.show()
      this.relevantMealsByMealType.clear()
      this.relevanNutritionPlanMeals = []
      this.alternativeMealDay = null
      if(user.planConfigs == null || user.planConfigs.length == 0){
        await this.nutritionPlanService.loadNutritionPlanConfigs(user)
      }
      if(user.configs == null || user.configs.length == 0){
        await this.nutritionPlanService.loadCycleConfigs(user)
      }
      // let relevantPlans = user.nutritionPlans.filter(x => x.startDate <= date && x.getEndDate() >= date)
      let relevantMealsByMealType = new Map<String, DayItem[]>()
      let relevanNutritionPlanMeals = []
      var startDate = date.clone();
      startDate.setHours(0,0,0,0);
      
      let meals = await this.nutritionPlanService.getNutritionPlanMealsForDateRange(user, startDate, date);
      for(let planConfig of user.planConfigs){
        if(planConfig.isActive && planConfig.startDate <= date && (planConfig.endDate == null || planConfig.endDate >= date)){
          for(let plan of planConfig.nutritionPlans){
            if (plan.isRepeating) {
              if(!plan.repeatingMeals){
                plan.repeatingMeals = []
                var configStartDate = planConfig.startDate.clone()
                var configEndDate = configStartDate.clone().addDays(plan.repetitionDuration)
        
                var fullRangeMeals = (await this.nutritionPlanService.getNutritionPlanMealsForDateRange(user, configStartDate, configEndDate)).filter(m => m.nutritionPlanId == plan.id)
                fullRangeMeals.forEach(meal => {
                  if (meal.isRepeating) {
                    plan.repeatingMeals.push(meal)
                  }
                })
              }
            }
            else {
              var planMeals = meals.filter(m => m.nutritionPlanId == plan.id)
              plan.repeatingMeals = []
              planMeals.forEach(meal => {
                if (meal.isRepeating) {
                  plan.repeatingMeals.push(meal)
                }
              })
            }
            let days = await NutritionPlanComponent.composeDays(startDate, date, user, user.configs, user.planConfigs, planMeals, this.nutritionPlanService, this.nutritionService)
            let day = days[0]
            
            var mealsForDay = []
            if (day.planConfig?.selectedNutritionPlan?.isRepeating) {
              var distance = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
              var repetitionDayNumber = distance % day.planConfig.selectedNutritionPlan.repetitionDuration
              mealsForDay = day.planConfig.selectedNutritionPlan.repeatingMeals?.filter( m => m.repetitionDayNumber == repetitionDayNumber && m.nutritionPlanId == day.planConfig.selectedNutritionPlan.id ).sort((a, b) => a.number - b.number) || []
              if (NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date) >= day.planConfig.selectedNutritionPlan.repetitionDuration) {
                day.isRepetition = true
              }
            } else if (day.planConfig?.selectedNutritionPlan) {
              mealsForDay = meals.filter( m => m.date.isSameDate(startDate) && m.nutritionPlanId == day.planConfig.selectedNutritionPlan.id ).sort((a, b) => a.number - b.number)
            }

            for(let item of day.items){
              if(item.meal != null || item.hasAlternativeMeals()){
                
                let tempMealType = item.mealConfig.mealType == MealType.custom ? item.mealConfig.customMealType : item.mealConfig.mealType
                if(existingMeals.find(x => x.plannedMealId == item.meal.id || item.alternativeMeals?.find(a => x.plannedMealId == a.id) != null) == null){
                  if(item.meal.imageURL == null){
                    this.nutritionPlanService.fetchMealImageUrl(item.meal).then(url => {
                      item.meal.imageURL = url
                    }).catch(error => {
                      item.meal.imageURL = null
                      console.error(error)
                    })
                  }
                  if(item.hasAlternativeMeals()){
                    for(let alternativeMeals of item.alternativeMeals){
                      if(alternativeMeals.imageURL == null){
                        this.nutritionPlanService.fetchMealImageUrl(alternativeMeals).then(url => {
                          alternativeMeals.imageURL = url
                        }).catch(error => {
                          alternativeMeals.imageURL = null
                          console.error(error)
                        })
                      }
                    }
                  }
                  if(relevantMealsByMealType.has(tempMealType)){
                    relevantMealsByMealType.get(tempMealType).push(item)
                  }
                  else {
                    relevantMealsByMealType.set(tempMealType, [item])
                  }
                  if(!relevanNutritionPlanMeals.includes(item)){
                    relevanNutritionPlanMeals.push(item);
                  }
                }
              }
            }
          }
  
        }
      }
      this.relevantMealsByMealType = relevantMealsByMealType;
      this.relevanNutritionPlanMeals = relevanNutritionPlanMeals;
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide();
    }

  }

  public alternativeMealDay: DayItem;

  onHideAlternativeMeals(){
    this.alternativeMealDay = null;
  }

  async onTrackMeal(date: Date, existingMeals: Meal[], user: User) {
    let meal = new Meal()
    await this.onTrackNewMeal(date, user, meal);
  }

  async onEditTrackMeal(meal: Meal, user: User) {
    // let plannedMeal = EditUserDataService.mealToPlannedMealV2(editMeal, this.languageService.selectedLanguageCode);
    await this.loadRecipes();
    const dialogRef = this.dialog.open(TrackedMealEditorDialogComponent, {
      data: { meal: meal.clone()}, width: '1000px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          this.spinner.show()
          try{
            let trackedMeal = result.meal
            if(trackedMeal){

              await this.editUserDataService.updateTrackedMeal(trackedMeal, user)
              this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
            }
          }
          catch(ex){
            console.error(ex);
          }
          finally{
            this.spinner.hide()
          }
        } else if (result.shouldDelete) {
          try{
            this.spinner.show()
            await this.editUserDataService.deleteTrackedMeal(meal, user);
            this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
          }
          catch(ex){
            console.error(ex);
          }
          finally{
            this.spinner.hide()
          }
        }
      }
    })
  }

  getMealTypeTranslation(mealType: string) {
    return MealTypeTranslation[StringToMealType[mealType]] || mealType;
  }

  async onWaterIntakeChanged(value: string){
    try{
      value = value.replace(',', '.');
      let waterIntake = parseFloat(value);
      if(Number.isNaN(waterIntake)){
        waterIntake = 0;
      }
      this.displayedUser.dailyCondition.waterIntake = waterIntake;
    }
    catch(ex){
      console.error(ex);
    }
  }

  async onTrackSelectedMeal(plannedMeal: PlannedMealV2, mealConfig: NutritionPlanMealConfig, user: User){
    try{
      await this.loadRecipes();
      this.spinner.show();
      await this.nutritionPlanService.getNutritionPlanMeal(plannedMeal, user, null)
      this.spinner.hide();
      let meal = EditUserDataService.plannedMealV2ToMeal(plannedMeal, this.languageService.selectedLanguageCode);  
      meal.date = this.currentDate
      let now = new Date();
      meal.date.setHours(now.getHours());
      meal.date.setMinutes(now.getMinutes());
      meal.mealType = Object.keys(MealType)[Object.values(MealType).indexOf(mealConfig.mealType)]
      await this.editUserDataService.trackMeal(meal, user)
      this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide();
    }
  }

  async loadRecipes(){
    try{
      if(!(this.nutritionService.recipes?.length > 0)) {
        this.spinner.show();
        this.spinnerText = "Lade Rezeptdatenbank..."
        await firstValueFrom(this.nutritionService.loadRecipes())
        if (!this.userService.getLoggedInUser()?.isCoach){
          await firstValueFrom(this.nutritionService.loadClientRecipes());
        }
      }
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide();
      this.spinnerText = null;
    }
  }

  async onShowMealInfo(plannedMeal: PlannedMealV2, user: User) {
    await this.loadRecipes();
    this.spinner.show();
    await this.nutritionPlanService.getNutritionPlanMeal(plannedMeal, user, null)
    this.spinner.hide();
    const dialogRef = this.dialog.open(MealEditorDialogComponent, {
      data: { meal: plannedMeal.clone(), readOnlyMode: true}, width: '1000px'
    });
  }

  async onTrackNewMeal(date: Date, user: User, meal: Meal){
    await this.loadRecipes();
    meal.date = date
    let now = new Date();
    meal.date.setHours(now.getHours());
    meal.date.setMinutes(now.getMinutes());
    const dialogRef = this.dialog.open(TrackedMealEditorDialogComponent, {
      data: { meal: meal}, width: '1000px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          this.spinner.show()
          try{
            let editedMeal = result.meal
            if(editedMeal){
              await this.editUserDataService.trackMeal(editedMeal, user)
              this.loadTrackingDataForUser(this.displayedUser, this.currentDate);
            }
          }
          catch(ex){
            console.error(ex);
          }
          finally{
            this.spinner.hide()
          }
        } else if (result.shouldDelete) {
        }
      }
    })
  }

  public convertedRecipe: Recipe

  onConvertToRecipe(meal: Meal) {
    this.convertedRecipe = new Recipe()
    this.convertedRecipe.ingredients = []
    this.convertedRecipe.setName(meal.name ?? null)
    this.convertedRecipe.setInstructions(meal.instructions ?? null)
    meal.foods.forEach(food => {
      var ingredient = new Ingredient()
      ingredient.weight = food.weight
      ingredient.nutritionFactId = food.nutritionFactId
      if (!food.nutritionFactId || !(food.nutritionFactId.startsWith('ntf') || food.nutritionFactId.startsWith('brc') || food.nutritionFactId.startsWith('bnd') || food.nutritionFactId.startsWith('bls'))) {
        ingredient.nutritionalSummary = new NutritionalSummary()
        ingredient.nutritionalSummary.carbohydrates = food.carbohydrates
        ingredient.nutritionalSummary.protein = food.protein
        ingredient.nutritionalSummary.fat = food.fat
        ingredient.nutritionalSummary.calories = food.calories
        ingredient.name = food.name
        ingredient.isDummy = true
        this.convertedRecipe.ingredients.push(ingredient)
      } else if (food.nutritionFactId && (food.nutritionFactId.startsWith('ntf') || food.nutritionFactId.startsWith('brc') || food.nutritionFactId.startsWith('bnd') || food.nutritionFactId.startsWith('bls'))) {
        var subscriptionIngredient0 = this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).subscribe(nutritionFact => {
          ingredient.nutritionFact = nutritionFact
          if (nutritionFact != undefined) subscriptionIngredient0.unsubscribe()
        })
        this.convertedRecipe.ingredients.push(ingredient)
      }
    })
    if (!meal.isDummyImage() && meal.imageLink) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = new Blob([xhr.response], { type: 'image/jpg' });
        const file = new File([blob], "thumbnail.jpg", {
          type: 'image/jpeg',
          lastModified: Date.now()
        });
        this.convertedRecipe.updatedImage = file
      };
      xhr.open('GET', meal.imageLink);
      xhr.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-origin')
      xhr.send();
    }
    this.toggleRecipeNameDialog()
  }
  onConfirmRecipeConversion() {
    this.nutritionService.insertRecipe(this.convertedRecipe, this.userService.getLoggedInUser()).then()
    this.toggleRecipeNameDialog()
  }
  toggleRecipeNameDialog() {
    document.getElementById('recipename-dialog').classList.toggle('show');
  }
  onConvertedRecipeNameChanged(text: string) {
    this.convertedRecipe.setName(text)
  }

  onOpenMealImage(meal: Meal) {
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: meal.imageLink}});
  }

  sendFeedback(meal: Meal) {
    this.userService.updateMealComment(this.displayedUser, meal);
    meal.show = false;
  }

  checkForAcknowledged(m: Meal) {
    if (m.commentAcknowledged != undefined && m.commentAcknowledged && !m.show) {
      return "grey-text";
    } else {
      return "normal-text";
    }
  }

  onInputFocus(meal: Meal) {
    meal.show = true;
  }

  onOpenActivity(activity: Activity, user: User) {
    if (this.editUserDataService.isEditModeActivated && activity.trainingSession == null) {
      this.onEditActivity(activity, user);
    } else if (activity.trainingSession != null) {
      this.onOpenTrainingSession(activity.trainingSession, activity);
    } else if (this.displayedUser.spikeUserId || activity.heartRate) {
      const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, { data: { user: this.displayedUser, activity: activity }, width: '1000px'})
    }
  }

  getPlannedSession(trackedTrainingSession: TrackedTrainingSession): TrainingSession{
    var session = this.displayedUser.trainingPlans.filter(x => x.id == trackedTrainingSession.trainingPlanId)[0]?.sessions?.filter(x => x.id == trackedTrainingSession.plannedSessionId)[0]
    return session
  }

  onOpenTrainingSession(trainingSession: TrackedTrainingSession, activity: Activity = null) {
    if (trainingSession != null) {
      let questionaireResults = this.displayedUser?.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trainingSession.id);
      const dialogRef = this.dialog.open(TrainingHistoryDialogComponent, { data: { user: this.displayedUser, selectedTrackedTrainingSession: trainingSession, activity: activity, selectedPlannedTrainingSession: this.getPlannedSession(trainingSession), questionaireResults: questionaireResults}})
    }
  }


  public selectedQuestionaireResults: QuestionaireResult[] = [];

  onQuestionaireResultSelected(questionaireResult: QuestionaireResult)
  {
    if(this.selectedQuestionaireResults.includes(questionaireResult)){
      this.selectedQuestionaireResults = this.selectedQuestionaireResults.filter(x => x !== questionaireResult);
    }
    else {
      this.selectedQuestionaireResults.push(questionaireResult);
    }
  }


  onOpenQuestionaireResults(){
    if(this.selectedQuestionaireResults.length > 0){
      let allAvailableQuestionaireResults = this.displayedUser.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
      const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: this.selectedQuestionaireResults, allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.displayedUser, editMode: this.editUserDataService.isEditModeActivated}, width: '1000px', autoFocus: false});
      dialogRef.afterClosed().subscribe(async result => {
        if (result && result.save) {
          this.spinner.show()
          try{
            await this.userService.updateQuestionaireResults(this.displayedUser.dailyCondition, this.displayedUser);
            this.toastr.success("Änderungen gespeichert", "",  {
              positionClass: 'toast-bottom-center'
            });
          }
          catch(ex){
            console.error(ex);
            this.toastr.error("Fehler beim Speichern", "",  {
              positionClass: 'toast-bottom-center'
            });
          }
          finally{
            this.spinner.hide()
          }
        }
      });
    }
  }

  onOpenQuestionaire(questionaireResult: QuestionaireResult){
    let allAvailableQuestionaireResults = this.displayedUser.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.displayedUser, editMode: this.editUserDataService.isEditModeActivated}, autoFocus: false});
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.save) {
        this.spinner.show()
        try{
          await this.userService.updateQuestionaireResults(this.displayedUser.dailyCondition, this.displayedUser);
          this.toastr.success("Änderungen gespeichert", "",  {
            positionClass: 'toast-bottom-center'
          });
        }
        catch(ex){
          console.error(ex);
          this.toastr.error("Fehler beim Speichern", "",  {
            positionClass: 'toast-bottom-center'
          });
        }
        finally{
          this.spinner.hide()
        }
      }
    });
  }

  getFilteredAssignedMetrics(user: User){
    return user.assignedMetrics?.filter(x => !x.deleted && user.dailyCondition?.metricData?.find(m => m.metricId == x.metricId) == null)
  }

  onTrackSelectedMetric(metric: Metric, user: User){
    let metricData = new MetricData();
    metricData.date = this.currentDate;
    metricData.metric = metric;
    metricData.metricId = metric.metricId;
    user.dailyCondition.metricData.push(metricData);
  }
  
  onTrackMetric(date: Date, user: User){
    const dialogRef = this.dialog.open(MetricsSelectionDialogComponent, { data: { notAvailableMetricIds: user?.dailyCondition?.metricData?.map(x => x.metricId) || [], canShowMetricSets: true}, width: '1200px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldTake) {
          result.metrics.forEach(metric => {
            if(user?.dailyCondition.metricData.find(x => x.metricId == metric.metricId) == null){
              this.onTrackSelectedMetric(metric, user);
            }
          });
        }
      }
    });
  }

  async saveMetricDataChanges(){
    this.spinner.show()
    try{
      if(this.displayedUser.dailyCondition.date == null){
        this.displayedUser.dailyCondition.date = this.currentDate;
      }
      if(this.displayedUser.dailyCondition.waterIntake == null || this.displayedUser.dailyCondition.waterIntake < 0 || Number.isNaN(this.displayedUser.dailyCondition.waterIntake)) {
        this.toastr.error("Bitte gib eine gültige Wassermenge an", "",  {
          positionClass: 'toast-bottom-center'
        });
        return;
      }
      let invalidMetricData = this.displayedUser?.dailyCondition?.metricData?.filter(x => x.isValueValid() == false);
      if(invalidMetricData.length > 0){
        this.toastr.error(invalidMetricData.map(x => x.metric?.nameTranslation?.GetValue(this.languageService.selectedLanguageCode)).join(', '), "Bitte überprüfe deine Eingaben",  {
          positionClass: 'toast-bottom-center'
        });
        return;
      }
      await this.userService.saveOrUpdateMetricDataChanges(this.displayedUser, this.displayedUser.dailyCondition);
      this.toastr.success("Änderungen gespeichert", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Fehler beim Speichern", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide()
    }
  }

  async onRemoveMetricData(metricData: MetricData){
    this.displayedUser.dailyCondition.metricData = this.displayedUser.dailyCondition.metricData.filter(x => x.metricId != metricData.metricId);
  }

}
