import { Component, Input, OnInit } from '@angular/core';
import { User } from '../model/user.model';
import { NotificationService } from '../services/notification.service';
import { FirestoreService } from '../services/firestore.service';

@Component({
  selector: 'app-notification-composer',
  templateUrl: './notification-composer.component.html',
  styleUrls: ['./notification-composer.component.css']
})
export class NotificationComposerComponent implements OnInit {

  @Input() user: User
  notificationTitle: string
  notificationContent: string
  type: string
  newType: string
  typeId: string

  constructor(public notificationService: NotificationService, private userService: FirestoreService) { }

  ngOnInit(): void {
    this.notificationTitle = this.notificationService.notificationTitle
    this.notificationContent = this.notificationService.notificationContent
    this.type = this.notificationService.type
    this.notificationService.updateTrigger.subscribe(update => {
      this.notificationTitle = this.notificationService.notificationTitle
      this.notificationContent = this.notificationService.notificationContent
      this.type = this.notificationService.type
      this.newType = this.notificationService.newType
      this.typeId = this.notificationService.typeId
    })
  }

  onNotificationContentChanged(text: string) {
    this.notificationContent = text
  }
  async onSendNotification() {
    await this.userService.createUserNotificationEntry(this.user, this.notificationTitle || '', this.notificationContent || '', this.newType, this.typeId, null)
    await this.notificationService.sendNotification(this.user, this.type, this.notificationTitle || '', this.notificationContent || '')
    this.notificationService.showComposer = false
  }

  onOpenComposer() {
    this.notificationService.showBadge = false
    this.notificationService.showComposer = true
  }
  onClose() {
    this.notificationService.showComposer = false
    this.notificationService.showBadge = true
  }
}
